import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { gridInterfaces } from 'interfaces';
import ActionsColumnButton from './actions-column-button';

const ActionsColumn: React.FC<gridInterfaces.IActionsColumn> = ({ api, colDef, data, getActionItems }) => {
    const actionItems = getActionItems(data, undefined);
    const { icon, iconClass, captionKey, captionClass, disabled, handler, hidden, onlyIcon = false }: gridInterfaces.IActionItem = actionItems[0];

    const actionButton = (
      <ActionsColumnButton
          buttonClass='bg-transparent flex text-left cursor-pointer items-center'
          icon={icon}
          api={api}
          data={data}
          iconClass={iconClass}
          captionKey={captionKey}
          captionClass={captionClass}
          disabled={disabled}
          handler={handler} 
          hidden={hidden}
          onlyIcon={onlyIcon}
      />
    );

    return (
      <div role="button"  aria-expanded="false" data-test={`grid-column-${colDef?.colId}`}>
        {!colDef?.editable && actionItems.length === 1
          ? actionButton
          : <FontAwesomeIcon icon={faEllipsisH} className="text-lg text-theme-primary cursor-pointer" />
        }
      </div>
    );
}

export default ActionsColumn;
