import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const AlertCard = ({
    type,
    widthClass = 'w-alert-card',
    containerClass = 'w-[476px]',
    messageClass = 'w-alert-message',
    title,
    messageLine1,
    messageLine2,
  }) => {
  let icon;
  let defaultHeader;
  let className = type;
  let utilityClasses;
  switch (type) {
    case 'success':
      icon = faCheck;
      defaultHeader = 'success';
      utilityClasses = { background: 'bg-theme-success', textColor: 'text-theme-success', btnBorder: 'border border-solid border-theme-success', btnBackground: 'bg-theme-success' };
      break;
    case 'error':
      className='theme-danger';
      icon = faTimesCircle;
      defaultHeader = 'Error!';
      utilityClasses = { background: 'bg-theme-danger', textColor: 'text-theme-danger', btnBorder: 'border border-solid border-theme-danger', btnBackground: 'bg-theme-danger' };
      break;
    case 'warning':
      className='theme-warning';
      icon = faExclamationTriangle;
      defaultHeader = 'warning';
      utilityClasses = { background: 'bg-theme-warning', textColor: 'text-theme-warning', btnBorder: 'border border-solid border-theme-warning', btnBackground: 'bg-theme-warning' };
      break;
    case 'info':
    default:
      icon = faInfo;
      defaultHeader = 'info';
      utilityClasses = { background: 'bg-theme-info', textColor: 'text-theme-info', btnBorder: 'border border-solid border-theme-info', btnBackground: 'bg-theme-info' };
  }

  return (
    <div className={`alert flex ${utilityClasses.btnBorder} z-50 ${utilityClasses.background} ${widthClass} `}>
      <div className="w-14 flex items-center justify-center">
        <FontAwesomeIcon icon={icon} className={`text-2xl ${utilityClasses.background} text-theme-on-primary`} />
      </div>
      <div className={`content ${containerClass} p-2 bg-theme-on-primary text-theme-medium`}>
        <h1 className={`text-[12px] font-normal leading-4 mb-2 mt-15 ${utilityClasses.textColor}`}>{title}</h1>
        <p className={`text-[11px] ${messageClass} font-normal leading-5 text-theme-medium mb-4`}>{messageLine1}</p>
        {messageLine2 && <p className="text-[11px] font-normal leading-5 text-theme-medium mb-4">{messageLine2}</p>}
      </div>
    </div>
  )
};

export default AlertCard;
