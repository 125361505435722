import React from 'react';

interface DropdownContextType {
  toggle?: () => void;
}

const DropdownContext = React.createContext<DropdownContextType | undefined>(undefined);

export const useDropdownContext = () => {
  const context = React.useContext(DropdownContext);
  if (context === undefined) {
    throw new Error('useDropdownContext must be used within a DropdownProvider');
  }
  return context;
}

export default DropdownContext;
