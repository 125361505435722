import useApi from 'hooks/use-api';
import { usePortalAppContext } from 'context/portal-app-context';
import useLocale from './use-locale';

// retrieves a link on click from a provider.  Used for links that can expire, such as SSO links to external sites.
const useDataHierarchy = (context) => {  
  const portalContext = usePortalAppContext();
  const { config, multiPortfolioEnabled } = context || portalContext;
  const { translateToString } = useLocale();

  const userHierarchyApi = useApi(config.API.userUrl2);

  const hasSingleLineage = (rawDataAccessFromApi, dataAccess) => {
    const hasSingleAssociation = dataAccess?.length === 1 && rawDataAccessFromApi?.length === 1 && rawDataAccessFromApi[0].hierarchyDescendent.level === '2';
    const hasSubsetMidsUnderSingleAssocation = dataAccess?.length === 1 && dataAccess[0].selectedMids?.length > 0;
    return hasSingleAssociation || hasSubsetMidsUnderSingleAssocation;
  };

  //to do: remove when displayName is added to api response
  const portfolioNameMap = {
    'TSYS': 'TSYS',
    'GPN': 'Global Payments',
    'VT': 'Virtual Terminal',
    'HPY': 'HPY  ',
    'T-MAS': 'TSYS',
    'G-MAS': 'Global Payments',
    'GP-API': 'Virtual Terminal',
    'HPY-DISPUTE': 'HPY  '
  }

  const getPortfolios = (rawDataAccessFromApi) => {
    const uniquePortfolioNames = [...new Set(rawDataAccessFromApi.map(d => d?.hierarchyDescendent?.hierarchyConfig?.affiliation))];
    const hierarchyConfigs = uniquePortfolioNames.map(p => rawDataAccessFromApi.find(d => d?.hierarchyDescendent?.hierarchyConfig?.affiliation === p)?.hierarchyDescendent?.hierarchyConfig);
    const portfolios = hierarchyConfigs.map(h => {
      const tierIndexes = [...Array(h.maxNumberOfLevels - 1).keys()].reverse();  // skip the 'all' level
      return {
        name: h.name,
        affiliation: h.affiliation,
        config: h,
        tierIndexes,
        displayName: portfolioNameMap[h.affiliation],
        singleTierMerchant: tierIndexes.length === 1,
      };
    });
    portfolios.forEach(p => {
      p.tiers = p.tierIndexes.filter(t => t > 0).map(t => {
        return {
          tierFullName: p.config[`level${t + 1}Label`].toUpperCase(),
          tierName: p.config[`level${t + 1}Label`].substr(0, 5).toUpperCase(),
          apiLevelNumber: t + 1,
          apiLevelName: `level${t + 1}Label`,
          tierIndex: p.tierIndexes.findIndex(x => x === t),
          classes: 'mb-[4px] w-[72px]',
        };
      });
      delete p.tierIndexes;
      if (p.tiers[0] && p.tiers[0].classes) p.tiers[0].classes = 'mb-[4px] ml-[8px] w-[72px]';
    });
    return multiPortfolioEnabled ? portfolios : portfolios.filter(p => p.name === 'T-MAS');
  };

  const getNode = async(loggedInUserDataAccess, hierarchyId, requestedTierIndex, searchInput, someoneElseEmail = '', maxTiers) => {
    if (requestedTierIndex === undefined) {
      return;
    }
    const searchText = searchInput || '';
    try {      
      let hasDirectAccess = false;
      let tierIndex = !hierarchyId ? 0 : -1;
      const hasfullPortfolioAccess = loggedInUserDataAccess?.some(row => row.nodes[0]?.selectedChild.label === 'All' && row.nodes[0]?.selectedChild.hasDirectAccess);
      if (hasfullPortfolioAccess) hasDirectAccess = true;
      if (hierarchyId && !hasfullPortfolioAccess) {
        loggedInUserDataAccess.forEach( row => {
          const hierarchyIdIndex = row.nodes.findIndex(n => n.selectedChild && n.selectedChild.hierarchyId === hierarchyId && n.selectedChild.label !== 'All');
          if (hierarchyIdIndex >= 0) {
            tierIndex = hierarchyIdIndex + 1;
            hasDirectAccess = row.nodes[hierarchyIdIndex].hasDirectAccess;
          }
        });
      }
      if (!hierarchyId && hasfullPortfolioAccess) {
        tierIndex = -1; // if we're trying to get root node, and it's set to all then this user has portfolio access.  set to -1 to force pull the list of banks from the API
      }
      let hasChildMerchants = false;
      if (tierIndex === loggedInUserDataAccess[0]?.nodes.length) {
        hasChildMerchants = true;
      } 
      let uniqueListEntries = [];
      let listEntriesTotalCount = 0;
      let tierName = '';
      if (tierIndex >= 0) {
        // let tierName = hasChildMerchants ? 'MERCHANT' : loggedInUserDataAccess[0].nodes[tierIndex].tierName;
        const fullListEntries = loggedInUserDataAccess
          .filter(row => tierIndex === 0 || (tierIndex < row.nodes.length && row.nodes[tierIndex-1].selectedChild.hierarchyId === hierarchyId))
          .map(row => row.nodes[tierIndex].selectedChild);
        uniqueListEntries = [];
        fullListEntries.forEach(n => {
          if (!uniqueListEntries.some(u => u.hierarchyId === n.hierarchyId)) uniqueListEntries.push(n);
        });
        listEntriesTotalCount = uniqueListEntries.length;
      }
      // todo: after MVP make this dynamic by reading tier schema from hierarchy config to accommodate other n-tier hierarchies besides TSYS
      if (tierIndex === maxTiers) {
        const hierarchyRow = loggedInUserDataAccess.find(row => row?.nodes?.length === tierIndex && row.nodes[tierIndex-1].selectedChild.hierarchyId === hierarchyId);
        uniqueListEntries = hierarchyRow.selectedMidRows;
        listEntriesTotalCount = uniqueListEntries.length;
      }
      // if (requestedTierIndex < 2 && uniqueListEntries.length === 0) {
      if (uniqueListEntries.filter(u => u.label !== 'All').length === 0) {
        let searchId = hierarchyId || loggedInUserDataAccess[0].selectedHierarchy.hierarchyId;
        try {
          await userHierarchyApi.get(`/hierarchy/hierarchies/value/${searchId}?value=${searchText}&pageSize=1000&targetUserEmail=${someoneElseEmail}`);
          hasDirectAccess = true;
          const response = userHierarchyApi.response.data.content;
          uniqueListEntries = getChildrenOfNode(response, searchId);
          listEntriesTotalCount = response.totalCount;
        }
        catch(e) {
          listEntriesTotalCount = 0;
        }
      }
      return {
        tierName,
        hasChildMerchants,
        isLazyLoadComplete: false,
        listEntries: uniqueListEntries,
        listEntriesTotalCount,
        hasDirectAccess,
      };
    }
    catch(e) {
      console.log(e);
    }
  };

  const getTierName = (tierLevel, portfolio) => {
    const tierName = portfolio.tiers.find(t => t.tierIndex === tierLevel)?.tierName;
    return tierName;  
  };

  const tierCustomClasses = ['mb-1 ml-2 w-[72px]', 'mb-1 w-[72px]', 'mb-1 w-32'];

  const getHierarchyRowsFromPersistedFilters = (portfolio, filterRows) => {
    const filterKeys = Object.getOwnPropertyNames(filterRows);
    const output = [];
    filterKeys.forEach( (k, idx) => {
      // get the full lineage codes & values, including merchant if present
      // replace logic is to send 000000 in lineageValues
      const lineageCodes = k.split('|').filter(c => c);
      const lineageValues = (filterRows[k].replace(/\|$/, '|000000').replace("||","|000000|")).split('|').filter(c => c);

      let selectedLineageValues = filterRows[k];
      if ((selectedLineageValues.match(/\|/g) || []).length  == 3) {
        let selectedLineageValuesMid = filterRows[k].split('|');
        selectedLineageValuesMid.splice(-1);
        selectedLineageValues = selectedLineageValuesMid.join("|")
      }
      // if it's selected at merchant level, get only the lineage tiers
      if (lineageCodes.length > portfolio.tiers.length) {
        lineageCodes.splice(-1);
        lineageValues.splice(-1);
      }

      // get the selected code & label
      const selectedHierarchyCode = lineageCodes.slice(-1)[0];
      const selectedHierarchyLabel = lineageValues.slice(-1)[0];

      if (!output.some(x => x.selectedHierarchy.hierarchyId === selectedHierarchyCode)) {
        output.push({
          isNewRow: false,
          selectedPortfolio: portfolio.name,
          rowKey: idx + 1,
          searchBy: {
            value: 'Hierarchy',
            label: 'Hierarchy',
          },
          selectedMids: [],
          selectedMerchants: [],
          selectedMidRows: [],
          selectedMerchantNumbers: [],
          nodes: lineageCodes.map((code,idx) => {
            const tier = portfolio.tiers[idx];
            const selectedChild = {
              hasDirectAccess: true,
              hierarchyId: code,
              label: lineageValues[idx],
              value: code,
            };
            return {        
              hierarchyId: code,
              value: code,
              label: lineageValues[idx],
              tierFullName: tier.tierFullName,
              tierName: tier.tierName,
              tierIndex: idx,
              apiLevelNumber: tier.apiLevelNumber,
              apiLevelName: tier.apiLevelName,
              isFocused: false,
              isLoading: false,
              classes: 'mb-[4px] w-[72px]',
              children: [selectedChild],
              selectedChild,
            };
          }),
          selectedHierarchy: {
            hierarchyId: selectedHierarchyCode,
            value: selectedHierarchyCode,
            label: selectedHierarchyLabel,
          },
          selectedLineage: selectedLineageValues,
        });
      }
    });

    filterKeys
      .forEach(k => {
        const lineageCodes = k.split('|');
        if (lineageCodes.length > portfolio.tiers.length) {
          const lineageValues = filterRows[k].split('|');
          const merchantId = lineageCodes.slice(-1)[0];
          const merchantNumber = lineageValues.slice(-1)[0];
          const selectedHierarchyId = lineageCodes.slice(-2)[0];
          const h = output.find(x => x.selectedHierarchy.hierarchyId === selectedHierarchyId);
          const selectedMidsObject = {
            hierarchyId: merchantId,
            merchantId: merchantNumber};
          if (h) {
            h.selectedMids.push(selectedMidsObject);           
            h.selectedMerchantNumbers.push(merchantNumber);
            h.selectedMidRows.push(selectedMidsObject);
          }
        }
      });
    output.forEach(r => {
      if (r.nodes.length < 3 && r.selectedHierarchy.label !== 'All') {
        const tier = portfolio.tiers[r.nodes.length];
        const allEntry = {
          hierarchyId: r.selectedHierarchy.hierarchyId,
          value: r.selectedHierarchy.hierarchyId,
          label: 'All',
        };
        r.nodes.push({
          children: [allEntry],
          disabled: false,
          isFocused: false,
          isLoading: false,
          selectedChild: allEntry,
          tierFullName: tier?.tierFullName,
          tierName: tier?.tierName,
          tierIndex: r?.nodes?.length,
          apiLevelNumber: tier?.apiLevelNumber,
          apiLevelName: tier?.apiLevelName,
        });
    }});
    return output;
  };
  
  //TODO: Need to change the accessType value from backend api , after that changeAccessType can be removed
  const getHierarchyRowsFromUserHierarchies = (apiRows, portfolio, changeAccessType = false, rowKeyStart = 0) => {
    const hierarchyConfig = portfolio.config;
    // api returns hierarchyId of the actual data access group ID instead of the ID of the selected hierarchy contained in the underlying row in the dag, so need to fix it on client
    apiRows.forEach(apiRow => {
      if (apiRow?.accessType === 'DAG') {
        console.log(`updating DAG hierarchyId from ${apiRow.hierarchyId} to ${apiRow.hierarchyDescendent.hierarchyId}`);
        apiRow.dagId = apiRow.hierarchyId;
        apiRow.hierarchyId = apiRow.hierarchyDescendent.hierarchyId;         
      }
    });
    const results = apiRows
    .filter(r => r?.hierarchyDescendent?.hierarchyConfig?.affiliation === hierarchyConfig.affiliation)
    .map((r, idx) => {
      const isMerchantSelection = !r.hierarchyDescendent || r.hierarchyDescendent.name === 'merchant';
      const isSingleTierMerchant = isMerchantSelection && r.hierarchyDescendent?.lineageDetails?.length === 1 && r.hierarchyDescendent?.lineageDetails[0].value === 'top_level';
      let leafNode;
      if (!isMerchantSelection) {
        const selectedLeafNodeChild = {
          hierarchyId: r.hierarchyDescendent.hierarchyId,
          value: r.hierarchyDescendent.hierarchyId,
          label:  r.hierarchyDescendent.value === 'top_level' ? 'All' : (r.hierarchyDescendent.value || '000000'),
          hasDirectAccess: true,
        };
        leafNode = {
          tierName: r.hierarchyDescendent.name.toUpperCase(),
          isFocused: false,
          children: [selectedLeafNodeChild],
          selectedChild: selectedLeafNodeChild,
        };
      }
      let selectedHierarchy = {};
      if (!isMerchantSelection) {
        selectedHierarchy = {
          hierarchyId: r.accessType === 'DAG' && !changeAccessType ? r.hierarchyId || r.hierarchyDescendent.hierarchyId : r.hierarchyDescendent.hierarchyId,
          value: r.hierarchyId,
          label:  r.hierarchyDescendent.value === 'top_level' ? 'All' : (r.hierarchyDescendent.value || '000000'),
        };
      } else {
        const level2Node = r.hierarchyDescendent.lineageDetails.find(x => x.level.toLowerCase() === '2' || x.level.toLowerCase() === 'level 2');
        selectedHierarchy.hierarchyId = level2Node.hierarchyId;
        selectedHierarchy.value = level2Node.hierarchyId;
        selectedHierarchy.label = level2Node.name.toUpperCase();
      }
      const lineageDetails = r.hierarchyDescendent.lineageDetails;
      const selectedDag = r?.hierarchyDescendent?.systemTags?.map(o => o.value)?.map(m => {
        if (m?.includes(r?.dagId)) {   
          const obj = JSON.parse(m);
          return ({
            name: obj.dataAccessGroup.name,
            value: obj.dataAccessGroup.name,
            id: obj.dataAccessGroup.groupId
          });
        }
      }
      ).filter(m => m);

      const rowResult =  {
        rowKey: (idx + 1) + rowKeyStart,
          //TODO: Need to change the accessType value from backend api , after that changeAccessType can be removed
        searchBy: r.accessType === 'DAG' && !changeAccessType 
          ? { label: 'DAG', value: 'DataAccessGroup' } : isSingleTierMerchant ? { label: 'MID', value: 'MID' } : { label: 'Hierarchy', value: 'Hierarchy' },
        selectedGroup: r.accessType === 'DAG'?
        {
          name:selectedDag?.[0]?.name, 
          value:selectedDag?.[0]?.value,
          id:selectedDag?.[0]?.id
        } : null,
        isNewRow: false,
        selectedHierarchy: isSingleTierMerchant ? null : selectedHierarchy,
        userHierarchyAssignmentId: r.userHierarchyAssignmentId,
        selectedMids: [],
        selectedMidRows: [],
        nodes: lineageDetails && !isSingleTierMerchant ? lineageDetails.slice(1).map((ld,i)=> {
          const selectedChild = {
            hierarchyId: ld.hierarchyId,
            value: ld.hierarchyId,
            label: ld.value === 'top_level' ? 'All' : ld.value || '000000',
            hasDirectAccess: false,
          };
          const node = {
            tierName: ld.name === 'all' ? 'top_level' : ld.name.toUpperCase(),
            isFocused: false,
            children: [selectedChild],
            selectedChild,
          };
          return node;
        }) : [],
      };

      if (!isMerchantSelection) {
        rowResult.nodes.push(leafNode);
      } else {
        const merchant = r.hierarchyDescendent;
        const merchantDetails = merchant.merchantDemographics;
        rowResult.selectedMids = isSingleTierMerchant 
          ? [{
            affiliation: portfolio?.affiliation,
            portfolioName: portfolioNameMap[portfolio?.affiliation],
            hierarchyId: merchant.hierarchyId,
            userHierarchyAssignmentId: r.userHierarchyAssignmentId,
            value: merchantDetails.merchantNumber,
            merchantName: merchantDetails.dbaName,
            address: `${merchantDetails.dbaAddress1}, ${merchantDetails.dbaCity}, ${merchantDetails.dbaPostalCode}`,
          }]
          : [merchant.hierarchyId];
        const lineage = merchant.lineageDetails.map(l => l.value === 'top_level' ? null : l.value === null ? '' : l.value);    
        rowResult.selectedMidRows = isSingleTierMerchant ? null : [{
          hierarchyId: merchant.hierarchyId,
          userHierarchyAssignmentId: r.userHierarchyAssignmentId,
          portfolioName: portfolio?.name,
          portfolioDisplayName: portfolioNameMap[portfolio?.name],
          merchantId: merchantDetails.merchantNumber,
          merchantName: merchantDetails.dbaName,
          address: `${merchantDetails.dbaAddress1}, ${merchantDetails.dbaCity}, ${merchantDetails.dbaPostalCode}`,
          hierarchy: `${lineage.filter(l => l !== null).join(' ')}`
        }];
      }
      return rowResult;
    });
    const uniqueRows = [];
    let idx = 1;
    results.forEach(r => {
      let existingRow = uniqueRows?.find(u => u?.selectedHierarchy?.hierarchyId === r?.selectedHierarchy?.hierarchyId && u?.selectedHierarchy?.label === r?.selectedHierarchy?.label);
      if (existingRow && r.searchBy?.value !== 'DataAccessGroup' ) {
        r.selectedMids[0]?existingRow.selectedMids.push(r.selectedMids[0]):[];
        r.searchBy?.value !== 'MID' && r.selectedMidRows[0] ? existingRow.selectedMidRows.push(r.selectedMidRows[0]) : [];
      } else {
        r.rowKey = (idx++) + rowKeyStart;
        r.selectedPortfolio = portfolio?.affiliation;
        // todo: after MVP make this dynamic by reading tier schema from hierarchy config to accommodate other n-tier hierarchies besides TSYS
        if (r.searchBy?.value !== 'MID' && r.nodes.length < 3 && r.selectedHierarchy.label !== 'All') {
          console.log('populating all entry for:');
          console.log(r);
          const allEntry = {
            hierarchyId: r.selectedHierarchy.hierarchyId,
            value: r.selectedHierarchy.hierarchyId,
            label: 'All',
          };
          r.nodes.push({
            tierName: getTierName(r.nodes.length, portfolio),
            children: [allEntry],
            disabled: false,
            isFocused: false,
            isLoading: false,
            selectedChild: allEntry,
            // tierAbbrev: getTierAbbrev()
          });
          console.log(r.nodes);
        }
        r.nodes.forEach((n,idx) => n.classes = (idx === 0) ? tierCustomClasses[0] : tierCustomClasses[1]);
        uniqueRows.push(r);
      }
  });
    return uniqueRows;
  };

  const getChildrenOfNode = (node, parentHierarchyId) => {
    if (!node) return [];
    const children = node.results;
    const mapped = children.map((c, idx) => {
      const result = {
        hierarchyId: c.hierarchyId,
        value: c.hierarchyId,
        label: c.value || '000000',
        hasDirectAccess: true,
      };
      if (c.merchantDemographics) {
        result.merchantDemographics = c.merchantDemographics;
      }
      return result;
    });
    const allOption = {
      hierarchyId: parentHierarchyId,
      value: 'All',
      label: 'All',
    };
    const result = [allOption, ...mapped];
    return result;
  };

  return {
    getNode,
    hasSingleLineage,
    getPortfolios,
    getChildrenOfNode,
    getHierarchyRowsFromUserHierarchies,
    getHierarchyRowsFromPersistedFilters,
    portfolioNameMap,
  };
};

export default useDataHierarchy;