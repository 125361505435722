import React from 'react';
import PropTypes from 'prop-types';

const CommonLoader = (props) => {
  return (
    <div className="common-wrapper mt-loader flex justify-center items-center font-roboto">
      <svg
        version="1.1"
        id="CL"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enableBackground="new 0 0 100 100"
        xmlSpace="preserve"
        style={{ width: props.width, fontWeight: 'bold' }}
      >
        {/* leaving below code in case we use it again in the future with translations capability */}
        {/* <text x="0" y="21" fontSize="20px" fill={props.fill}>Loading</text>
        <circle cx="82" cy="22" stroke={props.fill} strokeWidth="1" fill={props.fill} r="2">
          <animate
            attributeName="r"
            begin="0s; circ1b.end"
            dur="0.4s"
            id="circ1a"
            from="2"
            to="0.5"
          />
          <animate
            attributeName="r"
            begin="circ1a.end"
            dur="0.4s"
            id="circ1b"
            from="0.5"
            to="2"
          />
        </circle>
        <circle cx="87" cy="22" stroke={props.fill} strokeWidth="1" fill={props.fill} r="2">
          <animate
            attributeName="r"
            begin="0.1s; circ2b.end"
            dur="0.4s"
            id="circ2a"
            from="2"
            to="0.5"
          />
          <animate
            attributeName="r"
            begin="circ2a.end"
            dur="0.4s"
            id="circ2b"
            from="0.5"
            to="2"
          />
        </circle>
        <circle cx="92" cy="22" stroke={props.fill} strokeWidth="1" fill={props.fill} r="2">
          <animate
            attributeName="r"
            begin="0.2s; circ3b.end"
            dur="0.4s"
            id="circ3a"
            from="2"
            to="0.5"
          />
          <animate
            attributeName="r"
            begin="circ3a.end"
            dur="0.4s"
            id="circ3b"
            from="0.5"
            to="2"
          />
        </circle> */}
      </svg>
    </div>
  );
};

export default CommonLoader;
