// authSession.js
import { PublicClientApplication } from "@azure/msal-browser";
import { v4 as createUuid} from 'uuid';
import throttle from 'lodash.throttle';
import createIdleDetector from "./idleCrossTab";
import { getTokenExpiry } from "./tokenExpiry";
import useDebug from "./debug";

const authSession = ({
    msalConfig,
    onTimerChange,
    onLogout,
    onRenew,  
    inactiveTimeoutSeconds = 900,
    countdownTimeoutSeconds = 60,
    renewalBufferSeconds = 900,
    defaultPostLogoutRedirect = '',
    retainSessionOnTimeout = false, 
  }) => {

  const bc = new BroadcastChannel('authSessionChannel');    // establish Broadcast Channel to sync events across tabs
  const msalInstance = new PublicClientApplication(msalConfig);
  const debug = useDebug(false);
  let monitorSessionInstance;
  let timerInterval;
  let startTime;
  let countdownSeconds;
  let isRenewing = false;

  // initialize state
  let state = sessionStorage.getItem('authSessionState'); 
  if (!state) {
    debug ('initializing state...');
    window['__auth_portal_init__'] = true;
    state =   {
      tabId: createUuid(),
      initialTabBroadcasted: false,  
      isMonitoring: false, 
      hasMonitorSessionInstance: false, 
      timerIsRunning: false, 
      countdownSeconds: countdownTimeoutSeconds,
      tokenExpiry: null,
      status: 'Active',
      isReloading: false,
      timedOut: false
    };
    sessionStorage.setItem('authSessionState', JSON.stringify(state));
  } else {
    state = JSON.parse(state)
    if (!state.isReloading && !window['__auth_portal_init__']) {
      // tab was duplicated, need to reassign a unique tabId
      window['__auth_portal_init__'] = true;
      state = {...state, tabId: createUuid(), initialTabBroadcasted: false, isMonitoring: false };
      sessionStorage.setItem('authSessionState', JSON.stringify(state));
    }
    state.isReloading = false;
    sessionStorage.setItem('authSessionState', JSON.stringify(state));
  }

  const signinByRedirect = async (languages) => {
    let brandApplicationAssociationId = "";
    let brandDetails = JSON.parse(`${sessionStorage.getItem('brandDetails')}`);
    brandApplicationAssociationId = brandDetails && brandDetails.brandApplicationAssociationId;

    try {
      await msalInstance.loginRedirect({
        extraQueryParameters: {
          "brandApplicationAssociationId": brandApplicationAssociationId, 
          "app_locales": languages
        }
      });
    } catch (err) {
      throw err;
    }
  };

  const handleRedirect = async () => {
    try {
      const response = await msalInstance.handleRedirectPromise();
      const token = response?.idToken || response;
      const tokenExpiry = token ? getTokenExpiry(token).expiry : null;
      state = { ...state, tokenExpiry: tokenExpiry ? tokenExpiry.toISOString() : null };
      sessionStorage.setItem('authSessionState', JSON.stringify(state));
      return token;
    } catch (error) {
      throw error;
    } 
  };

  const login = async (loginRedirect, languages = []) => {
    const retryAttempted = sessionStorage.getItem('loginRetryAttempted');
    try {

      state = JSON.parse(sessionStorage.getItem('authSessionState'));
      if (state?.timedOut && retainSessionOnTimeout) {
          state = { ...state, timedOut: false, countdownSeconds: countdownTimeoutSeconds, timerIsRunning: false };
          sessionStorage.setItem('authSessionState', JSON.stringify(state));
          await logout('/');
      }

      let postLoginRedirect = sessionStorage.getItem('postLoginRedirect');
      if (postLoginRedirect === null && loginRedirect) sessionStorage.setItem('postLoginRedirect', loginRedirect);  // only store initial loginRedirect for deep links
      postLoginRedirect = postLoginRedirect || "";

      const token = await handleRedirect(); // returns null if not on redirect (ie. sign in hasn't occurred)
      
      const postLogoutRedirect = sessionStorage.getItem('postLogoutRedirect');
      if (token) {
        sessionStorage.removeItem('postLoginRedirect');
        monitorSessionInstance = monitorSession();
        state = { ...state, hasMonitorSessionInstance: true };
        sessionStorage.setItem('authSessionState', JSON.stringify(state));
      } else {
        if (postLogoutRedirect) {
          sessionStorage.removeItem('postLogoutRedirect');
        } else {
          await new Promise((resolve, reject) => {
            setTimeout(async () => {
              // only redirect to auth provider if no other tab has responded within 200ms with an existing auth token
              try {
                await signinByRedirect(languages);
                resolve();
              } catch (err) {
                reject(err);
              }
            }, 200);
          });
        }
      }
      if (retryAttempted) sessionStorage.removeItem('loginRetryAttempted');
      return { token, postLoginRedirect, postLogoutRedirect };
    } catch (error) {
      if (error.errorCode === 'no_cached_authority_error' && !retryAttempted) {
        try {
          sessionStorage.setItem('loginRetryAttempted', 'true');
          window.location.href = "/"
        } catch (retryError) {
          throw retryError
        }
      } else {
        throw error;
      }
    }
  };

  const signinByPopup = async (extraQueryParameters) => {
    try {
      const request = {
        scopes: ['openid'],
        authority: msalConfig.auth.authority,
        redirectUrl: msalConfig.auth.redirectUri,
        response_type: 'id_token',
        response_mode: 'query',
      };
      if (extraQueryParameters) {
        request.extraQueryParameters = extraQueryParameters;
      }
      const response = await msalInstance.loginPopup(request);
      const token = response?.idToken || response;  
      return token;
    }
    catch(error) {
      throw error;
    }
  };

  const renew = async () => {
    try {
      let token;
      const silentRenewRequest = {
        scopes: ['openid'],
        account: msalInstance.getAllAccounts()[0],
        forceRefresh: true,
      };
      isRenewing = true;
      const response = await msalInstance.acquireTokenSilent(silentRenewRequest);
      token = response && response.idToken;
      const tokenExpiry = getTokenExpiry(token).expiry;
      state = { ...state, tokenExpiry: tokenExpiry ? tokenExpiry.toISOString() : null };
      sessionStorage.setItem('authSessionState', JSON.stringify(state));
      onRenew(token);
      debug('token expiry:', new Date(tokenExpiry));
      debug('renewed with token:', token);
      isRenewing = false;
      return token;
    } catch (error) {
      console.log('portal auth session error with renewing token:', error);
    }
  };

  const logout = async (customRedirect) => {
    clearInterval(timerInterval);
    const redirect = customRedirect || defaultPostLogoutRedirect;
    state = JSON.parse(sessionStorage.getItem('authSessionState'));
    bc.postMessage({ action: 'logged-out', tabState: { tabId: state?.tabId }, timedOut: state?.timedOut });

    if (window.stop) window.stop();
    if (window.openedWindows) {
      const popup = window.openedWindows[0];
      if (popup && popup.close) {
        popup.close();
      }
    }

    const retainSession = state?.timedOut && retainSessionOnTimeout;
    if (!retainSession) {
      sessionStorage.setItem('postLogoutRedirect', redirect);
      sessionStorage.removeItem('authSessionState');
      sessionStorage.removeItem('tabStatus');
      monitorSessionInstance?.teardown();
    }
    sessionStorage.removeItem('loginRetryAttempted');

    onLogout();

    try {
      if (retainSession) {
        window.location.href = redirect;
      } else {
        await msalInstance.logoutRedirect();
      }
      return;
    } catch (error){
      throw error;
    }
  };

  const getRenewalInfo = () => {
    state = JSON.parse(sessionStorage.getItem('authSessionState'));
    if (!state?.tokenExpiry) {
      return { shouldRenew: false, isExpired: false };
    }  
    const tokenExpiresInMs = new Date(state?.tokenExpiry) - Date.now();
    const shouldRenew = (renewalBufferSeconds * 1000) > tokenExpiresInMs;
    const isExpired = tokenExpiresInMs <= 0;
    return { shouldRenew, isExpired };
  };

  const resetTimer = () => {
    clearInterval(timerInterval);
    state = { ...state, timerIsRunning: false, countdownSeconds: countdownTimeoutSeconds };
    sessionStorage.setItem('authSessionState', JSON.stringify(state));
    onTimerChange({ ...state });
    bc.postMessage({ action: 'reset-timer', tabState: { tabId: state?.tabId } });
  }


  const processMonitorState = () => {

    if (!state.initialTabBroadcasted) {
      // delay sending requests to let any delete requests to settle as bc is not synchronous
      setTimeout(() => {
        bc.postMessage({ action: 'user-activity', tabState: { tabId: state?.tabId, status: 'Active' } });
        bc.postMessage({ action: 'request-tab-status'});
      }, 2000);
      state = { ...state, initialTabBroadcasted: true };
      sessionStorage.setItem('authSessionState', JSON.stringify(state));
    }

    window.addEventListener("beforeunload", function (event) {

      // if not logging out, perform cleanup and set state in case of refresh
      state = JSON.parse(sessionStorage.getItem('authSessionState'));
      if (state) {
        bc.postMessage({ action: 'remove-tab-status', tabState: { tabId: state?.tabId } });
        state = { ...state, isMonitoring: false, initialTabBroadcasted: false, isReloading: true };
        sessionStorage.setItem('authSessionState', JSON.stringify(state));
        bc.close();
        monitorSessionInstance?.teardown();
      }

    });

      // listening for bc messages
    bc.addEventListener("message", ({ data }) => {
      const tabs = JSON.parse(sessionStorage.getItem('tabStatus')) || {};  // status on other tabs that are open
      const tabStatusHasChanged = tabs[data?.tabState?.tabId] !== data?.tabState?.status;
      state = JSON.parse(sessionStorage.getItem('authSessionState')); 
      if (state && (state?.tabId !== data?.tabState?.tabId)) {
        switch (data.action) {
          case 'user-activity':
            debug('received status from tab:', data?.tabState?.tabId);
            if (tabStatusHasChanged) {
              tabs[data?.tabState?.tabId] = data?.tabState?.status;
              sessionStorage.setItem('tabStatus', JSON.stringify(tabs));
            }
            break;
        
          case 'request-tab-status':
            debug('request from another tab to broadcast tab status');
            bc.postMessage({ action: 'user-activity', tabState: { tabId: state?.tabId, status: state.status } });
            break;
        
          case 'logged-out':
            if (state) {
              debug('logout request from another tab');
              state = { ...state, timedOut: data?.timedOut };
              sessionStorage.setItem('authSessionState', JSON.stringify(state));
              logout();
            }
            break;
          
          case 'run-timer':
            if (!state.timerIsRunning) {
              debug('running timer ...');
              monitorSessionInstance.runTimer();
              state = { ...state, timerIsRunning: true, countdownSeconds: countdownTimeoutSeconds };
              sessionStorage.setItem('authSessionState', JSON.stringify(state));
              onTimerChange({ ...state });
            }
            break;

          case 'reset-timer':
            debug('canceling timer ...');
            state = { ...state, timerIsRunning: false, countdownSeconds: countdownTimeoutSeconds };
            sessionStorage.setItem('authSessionState', JSON.stringify(state));
            onTimerChange({ ...state });
            break;
        
          case 'remove-tab-status':
            if (tabs[data?.tabState?.tabId]) {
              debug('request to remove tab:', data?.tabState?.tabId);
              delete tabs[data?.tabState?.tabId];
              sessionStorage.setItem('tabStatus', JSON.stringify(tabs));
            }
            break;
        
          default:
            break;
        }
      }
    });

  }

  const monitorSession = () => {

    processMonitorState();

    debug('running monitor session instance...');
    state = { ...state, isMonitoring: true };
    sessionStorage.setItem('authSessionState', JSON.stringify(state));

    const runTimer = async () => {
      countdownSeconds = state.countdownSeconds ?? 60;
      startTime = Date.now();
      
      timerInterval = setInterval(async () => {
        try {
          state = JSON.parse(sessionStorage.getItem('authSessionState'));
    
          if (state?.timerIsRunning) {
            const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
            const remainingTime = countdownSeconds - elapsedTime;
            const tokenHasExpired = (new Date(state?.tokenExpiry) - startTime) <= 0;
            
            if (remainingTime <= 0 || tokenHasExpired) {
              state.countdownSeconds = 0;
              state.timerIsRunning = false;
              state.timedOut = true;
              sessionStorage.setItem('authSessionState', JSON.stringify(state));
              onTimerChange({ ...state });
              clearInterval(timerInterval);
              await logout();
            } else {
              state.countdownSeconds = remainingTime;
              sessionStorage.setItem('authSessionState', JSON.stringify(state));
              onTimerChange({ ...state });
            }
          } else {
            clearInterval(timerInterval);
          }
        } catch (err) {
          throw err;
        }
      }, 1000);
    };

    const onUserActivity = throttle(() => {
      state = JSON.parse(sessionStorage.getItem('authSessionState'));
      const { shouldRenew } = getRenewalInfo();
      const tabs = JSON.parse(sessionStorage.getItem('tabStatus'));
      if (!isRenewing && !state?.timerIsRunning && shouldRenew) {
        debug('is renewing...');
        renew();
      }
    }, 1000);
    
    const onIdleStateChange = (isIdle) => {
      state = JSON.parse(sessionStorage.getItem('authSessionState'));

      if (isIdle && !state?.timerIsRunning) {
        const tabs = JSON.parse(sessionStorage.getItem('tabStatus'));
        const allOpenTabsInactive = !tabs || Object.keys(tabs).every(tabId => tabs[tabId] === 'Inactive');
        debug('idle state...');
        if (allOpenTabsInactive) {
          debug('all open tabs inactive, running timer...');
          runTimer();
          bc.postMessage({ action: 'run-timer', tabState: { tabId: state?.tabId } });
          state = { ...state, timerIsRunning: true, countdownSeconds: countdownTimeoutSeconds };
          sessionStorage.setItem('authSessionState', JSON.stringify(state));
          onTimerChange({ ...state });
        } else {
          debug('some tabs are active...');
          bc.postMessage({ action: 'user-activity', tabState: { tabId: state?.tabId, status: "Inactive" } });
          sessionStorage.setItem('authSessionState', JSON.stringify({ ...state, status: 'Inactive' }));
        }               
      }
    }

    const idleDetector = createIdleDetector(inactiveTimeoutSeconds, onIdleStateChange, onUserActivity);
    const teardown = () => {
      clearInterval(timerInterval);
      idleDetector.teardown();
    }

    return {
      teardown,
      runTimer
    }
    
  }

  const processReinstateMonitor = () => {
    state = JSON.parse(sessionStorage.getItem('authSessionState'));
    // reinstate session monitor instance if page refresh occurs
    if(!state.isMonitoring && state.hasMonitorSessionInstance) {
      debug('has monitor instance...');
      state = { ...state, isMonitoring: true };
      sessionStorage.setItem('authSessionState', JSON.stringify(state));
      monitorSessionInstance = monitorSession();
      if (state?.timerIsRunning) monitorSessionInstance.runTimer();
    }
  }

  const setReloadingListener = () => {
    state = JSON.parse(sessionStorage.getItem('authSessionState'));
    if (state.isReloading) {
      sessionStorage.setItem('authSessionState', JSON.stringify({ ...state, isReloading: false }));
    }
  }

  window.addEventListener("load", setReloadingListener);

  processReinstateMonitor();

  return {
    login,
    logout,
    signinByPopup,
    resetTimer,
  }
}

export default authSession;