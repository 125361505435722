import React, { SyntheticEvent, useState } from 'react';
import Modali, { useModali } from 'modali';
import useLocale, { localeKeys } from '../../hooks/use-locale';
import { usePortalAppContext } from 'context/portal-app-context';
import LocalizedTextEditor from './LocalizedTextEditor';

export interface LocalizedTextProps {
    localeKey: localeKeys;
    id?: string;
    templateVariables?: { [key: string]: any } // object of template variables
}

const LocalizedText: React.FC<LocalizedTextProps> = ({ localeKey, id, templateVariables }) => {
    const { translate } = useLocale();
    const context: any = usePortalAppContext();
    const [showTranslateButton, setShowTranslateButton] = useState(false);

    const interpolateString = (text: string) => {
        let resultString = text;
        if (templateVariables) {
            const keys = Object.keys(templateVariables);
            keys?.forEach(key => {
                const placeholder = `%${key}%`;
                resultString = resultString.replace(placeholder, templateVariables[key])
            })
        }
        return resultString;
    }

    const translateResultsObj = translate(localeKey);
    let translatedText = translateResultsObj.translation || translateResultsObj.defaultTranslation;
    const hasTranslation: boolean = !!translateResultsObj.translation;

    if (templateVariables) {
        translatedText = interpolateString(translatedText);
    }
    
    const borderColor = hasTranslation ? 'border-b-green-500' : 'border-b-red-500';
    const translationAdminClasses = context?.translationAdmin?.isManagingTranslations ? `relative ${borderColor} border-b-2 p-0"` : "";

    const onMouseOver = () => {
        if (context?.translationAdmin?.isManagingTranslations) {
            if (!showTranslateButton) {
                console.log('on label mouse over');
                setShowTranslateButton(true);
            }
        }
    }

    const onMouseOut = () => {
        if (showTranslateButton) {
            console.log('on mouse out');
            setShowTranslateButton(false);
        } 
    }

    const [translateModal, toggleTranslateModal] = useModali({
        overlayClose: false,
        closeButton: false,
        large: false,
        centered: true,
      });

    const onTranslateClick = (e: SyntheticEvent) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();     
        e.preventDefault();
        setShowTranslateButton(false);
        toggleTranslateModal();
    };

    const onSaveTranslation = (translatedText?: string) => {
        context?.translationAdmin?.updateTranslation(localeKey, translatedText);
        toggleTranslateModal();
    }

    const onCancelTranslation = (e?: SyntheticEvent) => {
        console.log(e);
        e?.stopPropagation();
        e?.nativeEvent.stopImmediatePropagation();     
        e?.preventDefault();
        toggleTranslateModal();
    };
  
    const translationElement: JSX.Element =  
        <>
            <span 
                className={translationAdminClasses} 
                id ={id} 
                onMouseEnter={onMouseOver}
                onMouseLeave={onMouseOut}
                data-test={localeKey}>
                    {translatedText}
                    {showTranslateButton && 
                        <span
                            className="bg-red-500 text-white text-[9px] absolute top-0 left-0 px-1 py-0 rounded-sm" 
                            onMouseDown={onTranslateClick}>
                                Translate
                        </span>
                    }
            </span>
        
            <Modali.Modal {...translateModal}>
                <LocalizedTextEditor
                    localeName={ context?.translationAdmin?.localeName}
                    localeKey={localeKey}
                    englishText={translateResultsObj.defaultTranslation}
                    translatedText={translateResultsObj?.translation || undefined}
                    onSave={onSaveTranslation}
                    onCancel={onCancelTranslation}
                />
            </Modali.Modal>
        </>
    return translationElement;
}

export default LocalizedText;