import React from 'react';

const LogoutTimer = ({ isRunning, loginRedirect, isAuthenticated, children }) => {

  const showTimer = () => {
    return (
      <div id="session-monitor-wrapper">
        {children}
      </div>
    );
  };

  if (isAuthenticated) {
    return isRunning ? showTimer() : <div />;
  } else {
    return loginRedirect;
  }
};


export default LogoutTimer;
