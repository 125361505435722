import React, { useEffect, useState, Fragment, useLayoutEffect } from 'react';
import useMerchantList from 'hooks/use-merchant-list';
import Select, { components } from 'react-select';
import useStateRef from 'react-usestateref';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faSearch } from '@fortawesome/free-solid-svg-icons';
import Search from 'components/DataAccessEditor/icons/Search.svg';
import LocalizedText from 'components/Translations/LocalizedText';
import AccessibleIcon from 'components/Translations/AccessibleIcon';

const ListDisplay = ({ option, type }) => {
  if (type === 'MID') {
    return (
      <div
        className="grid gap-0 grid-cols-12 auto-cols-max text-theme-dark m-0 p-0 break-words"
        data-test="dataaccess-mid-search-item"
      >
        <div className="text-[12px] col-span-4 pr-2">{option.value}</div>
        <div className="text-[10px] col-span-4">{option.merchantName}</div>
        <div className="text-[9px] col-span-4">{option.address}</div>
      </div>
    );
  } else {
    return (
      <div
        className="grid gap-0 grid-cols-9 auto-cols-max text-theme-dark m-0 p-0"
        data-test="dataaccess-mid-search-item"
      >
        <div className="text-[12px] col-span-3">{option.merchantName}</div>
        <div className="text-[10px] col-span-2">{option.value}</div>
        <div className="text-[9px] col-span-4">{option.address}</div>
      </div>
    );
  }
};

export default function SearchByMid({ 
    onMidSelection,
    hierarchyFilter,
    addMoreMids,
    isDropDownListVisible,
    showSelectButton = true,
    targetUserEmail = '',
    placeholderText = '',
    initialValue = [],
    type = 'MID',
    maxSelection = 0,
    portfolio = '',
  }) {
  const [options, setOptions] = useState(initialValue);
  const [error, setError] = useState([]);
  const [selectedValue, setSelectedValue] = useState(initialValue);
  const [characterEnteredValue, setCharacterEntered] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [, setUpdatedText, updatedTextRef] = useStateRef(false);
  const { setSearchCriteria, getRows, totalRowCount, abort } = useMerchantList(null, '/hierarchy/profile/merchants/value', targetUserEmail, portfolio);
  const [inputValue, setInputValue] = useState('');
  const [menuIsOpen, setMenuIsOpen, menuIsOpenRef] = useStateRef(false);
  const [notValidText, setNotValidText, notValidTextRef] = useStateRef(false);

  useLayoutEffect(() => {
    if (menuIsOpenRef.current) {
      const element = document.getElementsByClassName('search-by-mid__menu')[0];
      element?.scrollIntoView();
    }
  },[options, menuIsOpenRef.current]);

  async function fetchSearchApiData(characterEnteredValue) {
    try {
      const regexp = type === 'MID' ? new RegExp(/^[-a-zA-Z_0-9\b]+$/) : new RegExp(/^[a-zA-Z0-9 \b]+$/);
      const minChar = type === 'MID' ? 4 : 1; 
      if ((characterEnteredValue !== '' || regexp.test(characterEnteredValue)) && characterEnteredValue.length >= minChar) {
        setSearchCriteria(characterEnteredValue);
        setIsLoading(true);
        setOptions([...selectedValue]);
        const rows = await getRows({ request: { startRow: 0, endRow: 100 }, type });

        if (updatedTextRef.current === characterEnteredValue) {
          const rowsWithUnselectedMids = rows.filter(
            (r) => !selectedValue.some((x) => r.merchantId === x.value)
          );
          setOptions(
            [].concat(
              ...selectedValue,
              [],
              rowsWithUnselectedMids.map((r) => {
                return {
                  value: r.merchantId,
                  hierarchyId: r.hierarchyId,
                  merchantName: r.merchantName,
                  address: r.address,
                  lineage: r.lineage,
                  lineageGuids: r.lineageGuids,
                  portfolioName: r.portfolioName,
                  affiliation: r.affiliation
                };
              })
            )
          );
          setIsLoading(false);
        }
        if (!updatedTextRef.current) {
          setIsLoading(false);
        }
      } else if (characterEnteredValue.length >= 1 && characterEnteredValue.length < minChar) {
        abort();
        if (isLoading) setIsLoading(false);
        if (options.length !== selectedValue.length) setOptions([...selectedValue]);
      }
    } catch (error) {
      setError(error.message || 'Unexpected Error!');
      setIsLoading(false);
    }
  }

  const handleInputChange = (characterEntered, event) => {
    const midInput = type === 'MID' ? characterEntered.replace(/\s/g, '') :  characterEntered.trimStart().replace(/\s\s+/g, ' ');
    if (midInput !== '' || event.action === 'input-change') {
      setCharacterEntered(midInput);
      setInputValue(midInput);
    }
    const regexp = type === 'MID' ? new RegExp(/^(\s*|[-a-zA-Z0-9_ ]+)$/) : new RegExp(/^(\s*|[a-zA-Z0-9 ]+)$/); 
    if (characterEntered === '' || regexp.test(characterEntered)) {
    }
      setNotValidText(!regexp.test(characterEntered || inputValue));
      if(!notValidTextRef.current && midInput.trim().split(" ").length <= 5){
        fetchSearchApiData(midInput);
        setUpdatedText(midInput);
      }     
  };

  const onKeyDown = (e) => {
    var key = e.keyCode || e.charCode;
    const regex = type === 'MID' ? new RegExp(/[-a-zA-Z_0-9]/).test(e.key) : true;
    if ((!regex && key !== 8 && !(e.ctrlKey && (e.keyCode === 86 || e.keyCode === 67 || e.keyCode === 65))) || (maxSelection > 0 && maxSelection === selectedValue.length)) {
      e.preventDefault();
    }
  };

  const handleChange = (e) => {
    const newSelectedValue = Array.isArray(e) ? e : [];
    if ((options.length === selectedValue.length && (newSelectedValue.length < selectedValue.length || !newSelectedValue.length))  ) {
      setOptions([...newSelectedValue]);
    }
    setSelectedValue(newSelectedValue);
    setInputValue('');
    setCharacterEntered('');
  if (!showSelectButton && onMidSelection) {
    onMidSelection(e);
  }
};

  const onAddMidsClick = () => {
    onMidSelection(selectedValue);
  };

  const SearchIcon = () => {
    return <FontAwesomeIcon icon={faSearch} />

};

  const MenuList = props => {
    return (
        <components.MenuList {...props}>
          <div className="m-0 p-0">
            {props.children}
          </div>
        </components.MenuList>
    );
  };

  const DropdownIndicator = props => {
    return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
    );
  };

  const Placeholder = props => {
    return (
    <components.Placeholder {...props}>
       <div className="text-[0.7rem] xl:text-sm font-medium text-theme-medium">{placeholderText}</div>
    </components.Placeholder>
    );
  };

  const noOptionsMessageMID = (
    <div className="text-theme-medium text-[12px] text-left" >
      {isLoading &&
        <>
          <LocalizedText localeKey="filters.midSearch.searching" id="mid-searching-label" />
          <AccessibleIcon icon={faSpinner} labeledBy="mid-searching-label" size="md" spin classes="text-sm pl-[4px]" />
        </>
      }
      {notValidTextRef.current && characterEnteredValue.length > 3 &&
        <LocalizedText localeKey="filters.midSearch.onlyNumbers" />
      }
      {!isLoading && characterEnteredValue.length < 4 &&
        <LocalizedText localeKey="filters.midSearch.placeholder" templateVariables={{ minNumbers: 4 }} />
      }
      {!notValidTextRef.current && !isLoading && characterEnteredValue.length >= 4 && totalRowCount === 0 &&
      <>
        <LocalizedText localeKey="filters.midSearch.noMatch" />
      </>
      }
    </div>
  );

  const searchTokens = inputValue.trim().split(" ") || [];
  const inValidTokensCount = searchTokens.length > 5;

  const errorMessageDBAName = () => {
    const conditions = [
      {
        condition: isLoading,
        message: (
          <>
            <LocalizedText localeKey="filters.midSearch.searching" id="mid-searching-label" />
            <AccessibleIcon icon={faSpinner} labeledBy="dba-searching-label" size="md" spin classes="text-sm pl-[4px]" />
          </>
        ),
      },
      {
        condition: maxSelection > 0 && maxSelection === selectedValue.length,
        message: <LocalizedText localeKey="filters.dbaSearch.maxSelections" />,
      },
      {
        condition: notValidTextRef.current && characterEnteredValue.length > 0,
        message: <LocalizedText localeKey="common.noSpecialChars" />,
      },
      {
        condition: inValidTokensCount,
        message: <LocalizedText  localeKey="filters.omniSearch.maxPhrases" templateVariables={{ allowedSpaces: 5 }} />,
      },
      {
        condition: characterEnteredValue.length < 1,
        message: <LocalizedText  localeKey="filters.dbaSearch.minChars" templateVariables={{ minChars: 1 }} />,
      },
      {
        condition: characterEnteredValue.length >= 1 && totalRowCount === 0,
        message: <LocalizedText  localeKey="filters.dbaSearch.noMatch" />,
      },
    ];
    return conditions.filter(item => item.condition)[0]?.message
  };

  const noOptionsMessageDBA = (
    <div className="text-theme-medium text-[12px] text-left">
      {errorMessageDBAName()} 
    </div>
  );

  const displayKey = type === "MID" ? "value" : "merchantName";
  const inputOptions = ((maxSelection > 0 && maxSelection === selectedValue.length) || inValidTokensCount || isLoading || notValidTextRef.current) ? [] : options;

  return (
    <>
      <Select
        className="w-[670px] lrg:w-[500px] min-h-[40px] font-bold bg-theme-on-primary rounded focus:outline-none focus:shadow-outline border border-solid border-theme-light-border focus:border-theme-primary"
        components={{ MenuList, Placeholder, DropdownIndicator }}
        
        styles={{
          control: (provided) => ({
            border: 'none',
            display: 'flex',
            padding: 1,
        }),
          clearIndicator: () => ({padding: '6px 8px'}),
          indicatorSeparator: (styles) => ({display: 'none' }),
          indicatorsContainer: (provided, state) => ({
              ...provided,
              padding: 0,
              alignItems: 'self-start'
          }),    
          multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#E5F4FF80',
          }),
          multiValueLabel: (provided) => ({
            ...provided,
            color: '#005C9E',
          }),
          menuList: (provided) => ({
            ...provided,
            padding: 0,
            margin: 0,
          }),
          option: (provided, state) => ({
            ...provided,
            padding: '8px 12px 8px 12px',
            'lineHeight': '18px',
            'fontSize': '12px',
            'borderBottom': '1px solid #D7DCE1',      
          })      
        }}
        inputValue={inputValue}
        isMulti
        autoFocus
        openMenuOnFocus
        value={options.filter(obj => selectedValue.map(x => x.value).includes(obj.value))}
        isSearchable
        options={inputOptions}
        filterOption={() => true}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={onKeyDown}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        noOptionsMessage={() => type === "MID" ? noOptionsMessageMID : noOptionsMessageDBA }
        formatOptionLabel={(option, { context }) => context === 'value' ? option[displayKey] : <ListDisplay option={option} type={type} />}
        classNamePrefix="search-by-mid"
      />
      {showSelectButton && selectedValue.length > 0 &&
      <div className="ml-4 min-w-[120px]">
        <button type="button" className="w-full bg-theme-primary h-10 text-theme-on-primary border-0 border border-gray-300 p-1 text-[14px] font-semibold" data-test ="data-access-management-selectmid-button" onClick={onAddMidsClick}>{type === 'MID' ? <LocalizedText localeKey="midSelector.title.primary"/> : 'Select DBAs'}</button>
      </div>
      }
    </>
  );
}
