import React, { useState, useEffect } from "react";
import Select, { SelectRenderer } from "react-dropdown-select";
import { FilterProps } from "interfaces/filter";
import { IndexCalendarRange } from "index-design-system-react";
import LocalizedText from "components/Translations/LocalizedText";
import { localeKeys } from "hooks/use-locale";
import RemoveFilterButton from "./buttons/RemoveFilterButton";

export const DateFilter: React.FC<Omit<FilterProps, "options">> = ({
  filter,
  onRemove,
  onFilter,
  classes,
  initialValue = { "lastLoginDateFrom": "", "lastLoginDateTo": "" }

}) => {

  const [selected, setSelected] = useState(initialValue);

  const handleFilters = (date: any) => {
    if (
      date.detail.startDate.dateInstance.getDate() &&
      date.detail.endDate.dateInstance.getDate()
    ) {
      setSelected({
        lastLoginDateFrom: date.detail.startDate.format("YYYY-MM-DD"),
        lastLoginDateTo: date.detail.endDate.format("YYYY-MM-DD"),
      });
    }
  };

  useEffect(() => {
    onFilter(selected);
  }, [selected]);

  let startDate = "";
  let lastDate = "";

  if (selected.lastLoginDateFrom && selected.lastLoginDateTo) {
    const startDateList = selected.lastLoginDateFrom.split("-");
    startDate = [startDateList[1], startDateList[2], startDateList[0]].join(
      "/"
    );
    const lastDateList = selected.lastLoginDateTo.split("-");
    lastDate = [lastDateList[1], lastDateList[2], lastDateList[0]].join("/");
  }

  let selectClasses = [
    "loginFilter",
    "flex-1",
    "text-sm",
    "font-medium",
    "leading-snug",
    "text-gray-600",
    "border",
    "border-solid",
    "border-gray-300",
  ];

  if (classes) {
    selectClasses = [...selectClasses, ...classes.split(" ")];
  }

  return (
    <Select
      inputRenderer={({
        inputRef,
      }: {
        inputRef: React.RefObject<HTMLInputElement>;
      }) => (
        <div
          ref={inputRef}
          className="mx-1 py-1 flex items-center relative"
          style={{
            backgroundColor: filter.query
              ? "rgba(241, 249, 255, 0.7)"
              : "#FFFFFF",
            color: "#9296A5",
          }}
        >
          <RemoveFilterButton onRemove={() => onRemove(filter)} />
          <span className="text-xs uppercase ml-1 font-medium cursor-pointer">
            <LocalizedText localeKey={filter.nameKey as localeKeys}/>
          </span>
          {selected.lastLoginDateFrom ? (
            <span
              id={`${filter.nameKey}-all`}
              className="cursor-pointer text-theme-info mx-2 font-medium text-sm"
            >
              {selected.lastLoginDateFrom} - {selected.lastLoginDateTo}
            </span>
          ) : (
            <>
              <span className="text-xs mx-2 opacity-50 cursor-pointer">
                MM/DD/YY - MM/DD/YY
              </span>
            </>
          )}
          <i className="text-xs mx-2 opacity-50 far fa-calendar cursor-pointer mx-2" />
        </div>
      )}
      closeOnSelect={true}
      dropdownRenderer={() => (
        <IndexCalendarRange
          startDate={startDate}
          endDate={lastDate}
          onDateChanged={handleFilters}
        />
      )}
      dropdownHandleRenderer={({ state }: SelectRenderer<any>): JSX.Element => (
        <div
          className={
            "empty-arrow " +
            (state.dropdown ? "empty-arrow-up" : "empty-arrow-down")
          }
        />
      )}
      options={[]}
      valueField="id"
      className={selectClasses.join(" ")}
      style={{
        width: "auto",
        height: 42,
        marginRight: 16,
        marginBottom: 16,
        backgroundColor: "white",
        boxShadow: "none",
      }}
      values={[]}
      onChange={function (): void {
        throw new Error("Function not implemented.");
      }}
    />
  );
};

export default DateFilter;
