import React from 'react';
import LocalizedText from 'components/Translations/LocalizedText';
import AccessibleIcon from 'components/Translations/AccessibleIcon';
import { gridInterfaces } from 'interfaces';

const ActionsColumnButton: React.FC<gridInterfaces.IActionsColumnButton> = ({
        api,
        data, 
        icon,
        iconClass, 
        captionKey, 
        captionClass, 
        disabled, 
        handler, 
        hidden,
        buttonClass,
        onlyIcon = false, 
    }) => {

    const dataTestAttribute = `grid-${captionKey.split('.')[1] ? captionKey.split('.').slice(1).join('-') : captionKey}`;
    const labeledByValue = dataTestAttribute + '-label';
    
    const handleClick = (handler: gridInterfaces.IActionItem['handler']) => {
        handler(data, api);
        api.stopEditing();
    }

    return (
        <>
            {!hidden && <button
                className={`${hidden ? 'hidden' : ''} ${buttonClass}`} 
                onClick={() => handleClick(handler)}
                data-test={dataTestAttribute} 
                disabled={disabled}
                key={dataTestAttribute}
                aria-label='action-button'
            >
                {icon   ? <AccessibleIcon icon={icon} labeledBy={labeledByValue} classes={iconClass}/> 
                        : <i className={`${iconClass} text-base font-medium`}/>}
                {!onlyIcon && <span className={`ml-1 font-medium font-roboto text-left p-1 w-full ${captionClass}`}><LocalizedText localeKey={captionKey} id={labeledByValue}/></span>}
            </button>}
        </>
    )
}

export default ActionsColumnButton;