import React, { useState, useRef, useEffect } from 'react';
// import { useHistory } from "react-router-dom";
import { usePortalAppContext } from 'context/portal-app-context';
import LocalizedText from './Translations/LocalizedText';

const NavigationWarning = ({ 
  disableWarning,
  // ignoreCancelWarningRef,
  warningLine1,
  warningLine2,  
  continueCaption,
  cancelCaption,
  warningLine1Key,
  warningLine2Key,
  continueCaptionKey,
  cancelCaptionKey,
  onWarningConfirmed,
  skipWarningRouteList = [],
}) => {
  const unblock = useRef();
  // const history = useHistory();
  const { enqueueAlert, routerHistory } = usePortalAppContext();

  const showCancelWarning = (continueAction) => {
    enqueueAlert({
      alertType: 'modal',
      titleKey: "hierarchySelector.selectDataAccess.alerts.deleteWarning.title",
      messageType: 'warning',
      continueCaption,
      cancelCaption,
      continueAction: continueAction,
      cancelAction: () => {},
      message: warningLine1,
      message2: warningLine2,
      messageKey: warningLine1Key,
      message2Key: warningLine2Key,
      continueCaptionKey: continueCaptionKey,
      cancelCaptionKey: cancelCaptionKey,
      modalDialog: true,
    });
  };

  useEffect(() => {
    unblock.current = routerHistory.block(tx => {
      const skipWarning = disableWarning || skipWarningRouteList.includes(tx.pathname); // || ignoreCancelWarningRef.current ;
      if (skipWarning) {
        if (onWarningConfirmed) onWarningConfirmed();
        return true;
      } else {
        showCancelWarning(() => {
          unblock.current();
          if (onWarningConfirmed) onWarningConfirmed();
          routerHistory.push(tx);
        });
        return false;
      }
    });
    return () => unblock.current();
  }, [disableWarning, warningLine1, warningLine2, continueCaption, warningLine1Key, warningLine2Key, continueCaptionKey, cancelCaptionKey,cancelCaption, skipWarningRouteList]);

  return (
    <div></div>
  );
};

export default NavigationWarning;
