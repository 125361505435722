import React from 'react';
import { ColDef, ColumnFunctionCallbackParams } from 'ag-grid-community';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircle } from '@fortawesome/free-solid-svg-icons'

interface IPillColumnItems {
  getTheme: (status: string) => string,
  value?: string,
  loaderWidth?: number | string,
}

export interface IPillColumn extends IPillColumnItems, ColumnFunctionCallbackParams, ColDef {};

const PillColumn: React.FC<IPillColumn> = ({value, getTheme, colDef }) => {
    const theme = value && getTheme(value);
    return (
      <div>
        <div className={`pill-status min-w-[60px]`} style={{ borderColor: theme, color: theme}} data-test={`grid-column-${colDef?.colId}`}>
          <FontAwesomeIcon icon={faCircle as IconProp} className='leading-0 text-8px mr-1.5' style={{color: theme}} /> 
          <span className="font-medium">{value}</span>
        </div>
      </div>
    )      
}

export default PillColumn;
