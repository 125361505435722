import React from 'react';
import { ColDef } from 'ag-grid-community';
import useDateTimeFormats from 'hooks/use-date-time-formats';

interface IDateColumnItems {
  value?: string,
  loaderWidth?: string | number;
}

export interface IDateColumn extends IDateColumnItems, ColDef {};

const DateColumn: React.FC<IDateColumn> = ({ value }) => {
  const { formatDateTime } = useDateTimeFormats();
  const { formattedDate, formattedTime } = formatDateTime(value || '');

  return (
    <div>
      <div>{formattedDate || 'N/A'}</div>
      <div className="text-theme-medium text-xs font-normal">{formattedTime}</div>
    </div>
  )
}  


export default DateColumn;
