import React from 'react';
import { ColumnFunctionCallbackParams, ColDef } from "ag-grid-community";
import { isDeepEqual } from 'react-use/lib/util';

interface IRadioButtonItems {
    data?: ColumnFunctionCallbackParams['data'],
    api?: ColumnFunctionCallbackParams['api'],
    isRowSelected?: (id: string) => boolean,
    onRowSelect: (id: any) => void
}

export interface IRadioButtonColumn extends IRadioButtonItems, ColDef { };
const RadioButtonColumn: React.FC<IRadioButtonColumn> = ({ data, onRowSelect, api, isRowSelected }) => {

    const radioClick = () => {
        onRowSelect(data)
    }

    if (data && isRowSelected) {
        const selected = isRowSelected(data);
        if (selected) {
            api?.deselectAll()
            api?.getRenderedNodes()?.forEach((node: any) => {
                if (isDeepEqual(data, node?.data)) {
                    node?.setSelected(true)
                }

            })
        }
        return (
            <div data-test="grid-column-radio">
                <input type='radio' name='col' aria-label='Col Radio' onClick={radioClick} defaultChecked={selected} />
            </div>
        );
    }
    return <div />;

}

export default RadioButtonColumn;