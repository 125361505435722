import React from 'react';
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import LocalizedText from 'components/Translations/LocalizedText';
import AccessibleIcon from 'components/Translations/AccessibleIcon';
import { localeKeys } from 'hooks/use-locale';

interface IGridHeader {
  headingKey: string, 
  rowCount: number, 
  rowCountCaptionKey: string, 
  actionButtonPermission: boolean, 
  actionButtonCaptionKey: localeKeys,
  actionButtonHandler: () => void,
  showMessage: boolean,
  messageKey: localeKeys,
  disabled: boolean,
  hidden: boolean,
}

const GridHeader: React.FC<IGridHeader> = ({
    headingKey, 
    rowCount, 
    rowCountCaptionKey, 
    actionButtonPermission, 
    actionButtonCaptionKey, 
    actionButtonHandler,
    showMessage,
    messageKey,
    disabled,
    hidden,
  }) => {

    return (
      <div className="ag-grid__header flex items-center text-theme-dark sm:mb-4">
        <div className="ag-grid__header--headline flex items-center w-1/2 sml:w-auto">
          <h2 className="mb-0 mr-6 text-2xl text-theme-dark font-medium sml:whitespace-normal md:whitespace-nowrap"><LocalizedText localeKey={headingKey as localeKeys} /></h2>
          {rowCount > 0 && 
            <>
              <div className="font-medium whitespace-nowrap text-theme-medium mt-1.5 sml:hidden">
                <span>{rowCount.toLocaleString()}</span>&nbsp; 
                <LocalizedText localeKey={rowCountCaptionKey as localeKeys}/>
              </div>
            </>
          }
        </div>
        { showMessage || actionButtonPermission &&
        <div className="ag-grid__header--actions w-full grid grid-cols-1 place-items-end">
          {showMessage
            ?
              <div className='flex justify-center items-center py-2.5 px-4 bg-[#FFFAE5]'>
                <AccessibleIcon icon={faExclamationCircle as IconDefinition} labeledBy="grid-header-message-label" classes="text-theme-warning text-[18px]" />
                <span className='pl-4 text-xs italic'><LocalizedText localeKey={messageKey} id="grid-header-message-label"/></span>
              </div>
            :
              <div>
                {actionButtonPermission && 
                  <button 
                    onClick={actionButtonHandler} 
                    disabled={disabled}
                    className={`${hidden ? 'invisible' : ''} btn btn-primary btn-alt p-1 px-4 flex items-center m-0 border border-solid border-gray-300`}
                    data-test="data-access-management-create-group"
                >
                    <AccessibleIcon icon={faPlus as IconDefinition} labeledBy="grid-header-action-button-label" classes="text-[1.25rem]" />
                    <span className="ml-2 pt-px"><LocalizedText localeKey={actionButtonCaptionKey} id="grid-header-action-button-label"/></span>
                  </button>
                }
              </div>
          }
        </div>
    }
      </div>
    );
};

export default GridHeader;