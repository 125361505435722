import React from 'react';

export enum CellShape {
  Bar = "Bar",
  Pill = "Pill",
  Ellipsis = "Ellipsis"
}

interface ISkeletonLoader {
  numberOfRows: number, 
  columnDefinitions: { cellShape: CellShape, width: string | number,  }[],
}

const SkeletonLoader: React.FC<ISkeletonLoader> = ({ 
  numberOfRows, 
  columnDefinitions, 
 }) => {
  var rowCount = numberOfRows;
  const rows = [];
  for (var i = 0; i < rowCount; i++) {
    rows.push(<LoaderRows columnDefinitions={columnDefinitions} key={i} />);
  }
  return (
    <div className="common-loader-wrapper theme-light-background flex absolute h-full w-full opacity-100 min-h-loader"style={{ visibility: 'hidden', backgroundColor: '#FFFFFF' }}>
      <div className="loader-outer left-0 right-0 absolute">
        <div className="loader my-0 mx-auto min-w-loader">
        <div className="ag-header ag-focus-managed ag-pivot-off" unselectable="on" style={{ height: '45px', width:'100%', backgroundColor:'#F4F5F6' }}></div>		
          {rows}
        </div>
      </div>
    </div>
  );
};

interface ILoaderRows {
  columnDefinitions: { cellShape: CellShape, width: string | number,  }[],
  key: number,
}

const LoaderRows: React.FC<ILoaderRows> = ({ columnDefinitions }) => {
  const columns = columnDefinitions;
  const cells = columns.map((column,  index) =>
    <LoaderCells column={column} key={index} />
  );

  return (
    <div style={{ height: '60px' }} className="ag-row ag-row-level-0 ag-after-created ag-row-focus !text-left animate-pulse">
      {cells}
    </div>
  );
};

interface ILoaderCells {
  column: { cellShape: CellShape, width: number | string },
  key: number,
}

const LoaderCells: React.FC<ILoaderCells> = ({ column }) => {
  if(column.cellShape == CellShape.Pill){
    return (
      <div className="ag-cell ag-cell-not-inline-editing no-border ag-cell-value !inline-block !static" tabIndex={-1} role="gridcell" aria-colindex={7} unselectable="on" style={{width: column.width}}>
        <div className="ag-react-container" style={{width: '100%'}}><div className="pill-status false bg-theme-light-border border-0 font-normal text-xs !h-4 !min-w-full">&nbsp;</div></div>
      </div>
    );
  }
  else if(column.cellShape == CellShape.Ellipsis){
    return (
      <div className="ag-cell ag-cell-not-inline-editing no-border ag-cell-value !inline-block !static" tabIndex={-1} role="gridcell" aria-colindex={8} unselectable="on" style={{width: column.width}}>
        <div className="ag-react-container justify-end pr-[25px]" style={{width: '100%'}}><div className="bg-theme-light-border" style={{width: '18px', height: '4px'}}></div></div>
      </div>
    );
  }
  else{
    return (
      <div className="ag-cell ag-cell-not-inline-editing no-border ag-cell-value !inline-block !static" tabIndex={-1} role="gridcell" aria-colindex={4} unselectable="on" style={{width: column.width}}>
        <div className="ag-react-container bg-theme-light-border" style={{width: '100%'}}><div className="font-normal text-xs">&nbsp;</div></div>
      </div>
    );
  }
}

export default SkeletonLoader;
