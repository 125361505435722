import { useState, useEffect, useRef } from 'react';
import useApi from 'hooks/use-api';
import { usePortalAppContext } from 'context/portal-app-context';
import useDataHierarchy from "hooks/use-hierarchy";
import useTranslations from './use-translations';
import useLocale from 'hooks/use-locale';

const useDataAccessGroup = (context) => {
  
  const portalContext = usePortalAppContext();
  const { translate } = useTranslations();
  const { config } = context || portalContext;
  const dagUrl = config.API.userUrl2; // The data-access-group endpoints are under userservicenew
  const options = {
    cachePolicy: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const dagApi = useApi(dagUrl, options);
  const { translateToString } = useLocale();

  const { getHierarchyRowsFromUserHierarchies, getPortfolios } = useDataHierarchy(context);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const searchCriteria = useRef('');
  const [createDAGError, setCreateDAGError] = useState(false);

  useEffect(() => {
    if (dagApi.error) {

      let titleKey = "alerts.generalError.title";
      let messageKey = "alerts.generalError.message";

      const fetchedTitle = (dagApi?.response?.data?.errorCode && translateToString(`alerts.${dagApi?.response?.data?.errorCode}.title`)) ? `alerts.${dagApi?.response?.data?.errorCode}.title` : `alerts.${dagApi?.response?.data?.resourceKey}.title`;
      const fetchedMessage = (dagApi?.response?.data?.errorCode && translateToString(`alerts.${dagApi?.response?.data?.errorCode}.message`)) ? `alerts.${dagApi?.response?.data?.errorCode}.message` : `alerts.${dagApi?.response?.data?.resourceKey}.message`;

      if (fetchedTitle) {
        titleKey = fetchedTitle;
      }

      if (fetchedMessage) {
        messageKey = fetchedMessage;
      }

      if (createDAGError) {
        titleKey = "alerts.cannot.create.data.access.group.title";
        messageKey = "alerts.cannot.create.data.access.group.message";
      }

      portalContext.enqueueAlert({ 
          alertType: 'modal', 
          titleKey, 
          messageType: 'error', 
          messageKey,
          message2: undefined, continueCaption: undefined, continueAction: undefined, cancelCaption: undefined, 
          cancelAction: undefined, transitionDuration: undefined 
      });
    }
  },[dagApi.error]);
  
  const createDataAccessGroup = async({
    name,
    description,
    hierarchies,
  }) => {
    
    const newGroup = {
      name,
      description,
      hierarchyIds: hierarchies.map(h => (h)),
    }; 
    setCreateDAGError(true);
    return await dagApi.post(`/hierarchy/data-access-groups`, newGroup);
  };

  const setSearchCriteria = (search) => {
    searchCriteria.current = search;
  };

  const searchDataAccessGroup = async({
    omniSearchValues,
    includeOnlyOwnedDAGs,
    userName,
    ownerName,
    merchantNumbers,
    sortBy,
    descendingSort,
    page,
    pageSize
  }) => {
    const groups = {
        userName: "",
        ownerName: "",
        omniSearchValues: searchCriteria.current.omniSearchValues,
        merchantNumbers: [],
        includeOnlyOwnedDAGs: false,
        sortBy: "GROUP_NAME",
        descendingSort: true,
        page: 1,
        pageSize: 100
    }; 
    return await dagApi.post(`/hierarchy/data-access-groups/search`, groups);
  };

  const getDAG = async (url, errorResponse = false) => {
    try {
      setIsLoading(true);
      await dagApi.get(url);
      if (dagApi.response.ok) {
        setIsLoading(false);
        const dagInfo = dagApi.response.data;  
        let hierarchyResults = dagInfo.assoicatedUserHierarchyAssignment.results;
        if (dagInfo?.userHierarchyAssignments?.totalCount > 50) {
          const nextPageUrl = `${url}&hierarchyPage=2`;
          await dagApi.get(nextPageUrl);
          if (dagApi.response.ok) {
            const nextPageResults = dagApi.response.data.assoicatedUserHierarchyAssignment.results;
            hierarchyResults = hierarchyResults.concat(nextPageResults);
          }
        }
        const portfolios = await getPortfolios(hierarchyResults);
        portfolios.forEach(p => {
          p.dataAccess = getHierarchyRowsFromUserHierarchies(hierarchyResults, p, true);
        });

        const resultDAG = {          
          groupId: dagInfo.groupId,
          countOfNodes: dagInfo.countOfNodes,
          createdByEmail: dagInfo.createdByEmail,
          createdByFirstName: dagInfo.createdByFirstName,
          createdByLastName: dagInfo.createdByLastName,
          createdDate: dagInfo.createdDate,
          description: dagInfo.description,
          lastUpdatedDate: dagInfo.lastUpdatedDate,
          name: dagInfo.name,
          userApplicationAssignments: dagInfo.assoicatedUserHierarchyAssignment,
          portfolios,
          dataAccess: portfolios[0].dataAccess,
          rawDataAccessFromApi: hierarchyResults,
          hasMidsExcludedFromLoggedInUser: dagInfo?.assoicatedUserHierarchyAssignment?.hasExcludedStuff,
        };

        return errorResponse ? { dag: resultDAG, ok: true } : resultDAG
      } else {
        setIsLoading(false);
        return errorResponse ? { error: dagApi.response.data, ok: false } : null;
      }
    }
    catch(err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const deleteDataAccessGroup = async (DAGId) => {
      setIsSaving(true);
      const response = await dagApi.del(`/hierarchy/data-access-groups/${DAGId}`);
      setIsSaving(false);
      return response;
  }

  const getDAGById = async (groupId, errorResponse = false) => {
    const url = `/hierarchy/data-access-groups/${groupId}?hydrateHierarchy=true`;
    const dagInfo = await getDAG(url, errorResponse);
    return dagInfo;
  };

  const getDAGForLoggedInUser = async () => {
    setIsLoading(true);
   const result= await dagApi.get(`/hierarchy/data-access-groups?includeAllAccessibleGroups=false&page=1&pageSize=100`);
    if (dagApi.response.ok) {
      setIsLoading(false);
    }
    return result;
  };

  const updateDataAccessGroup = async (
    updatedGroup,
    groupDetails,
    updatedDataAccess,

  ) => {
    let resp = { ok: true };
    setIsSaving(true);
    if (updatedGroup.groupName !== groupDetails.name || updatedGroup.groupDesc !== groupDetails.description) {
      const updatedGroupPayload = {
        "name": updatedGroup.groupName,
        "description": updatedGroup.groupDesc
      };
      resp = await dagApi.put(`/hierarchy/data-access-groups/${groupDetails.groupId}`, updatedGroupPayload);
    }

    if (updatedDataAccess) {
      const updatedHierarchyIds = updatedDataAccess?.map(m => m?.midHierarchyIds && m?.midHierarchyIds?.length !== 0 ? m?.midHierarchyIds.map(m => m) : m?.hierarchyId).flat();
      const originalHierarchyIds = groupDetails?.dataAccess?.map(m => m?.selectedMidRows.length !== 0 ? m?.selectedMidRows?.map(r => r.hierarchyId) : m?.selectedHierarchy?.hierarchyId).flat();
      const hierarchyIdsToDelete = originalHierarchyIds?.filter(x => !updatedHierarchyIds?.includes(x));
      const hierarchyIdsToAdd = updatedHierarchyIds?.filter(x => !originalHierarchyIds?.includes(x));
      const updatedGroupPayload = {
        "hierarchyIdsToAdd": hierarchyIdsToAdd,
        "hierarchyIdsToDelete": hierarchyIdsToDelete
      };
      resp = await dagApi.put(`/hierarchy/data-access-groups/${groupDetails.groupId}/hierarchies`, updatedGroupPayload);
    }
    setIsSaving(false);
    return resp;
  }

  const deleteUserFromDAG = async (userId,groupId) => {
    return await dagApi.del(`/hierarchy/data-access-groups/assignments/${userId}/${groupId}`);
  };

  const addUserToDataAccessGroup = async({
    userId,
    dataAccessGroupId, 

  }) => {
    const addUserPayload = {
      userId,
      "hierarchyId" : dataAccessGroupId, 
      "accessType": "DAG",   
      "status": "ACTIVE", 
    }; 
    return await dagApi.post(`/hierarchy/assignments/${userId}`, addUserPayload);
  };

  const getUserHierarchyAssignmentId = async (userId) => {
    return await dagApi.get(`/hierarchy/assignments/${userId}?includeInactive=true`);
  };

  return {
    createDataAccessGroup,
    deleteDataAccessGroup,
    getDAGById,
    getDAGForLoggedInUser,
    searchDataAccessGroup,
    setSearchCriteria,
    updateDataAccessGroup,
    isSaving,
    addUserToDataAccessGroup,
    deleteUserFromDAG,
    isLoading,
    getUserHierarchyAssignmentId
  };
};

export default useDataAccessGroup;