import React from 'react';
import PropTypes from 'prop-types';
import CommonLoader from '../CommonLoader';
import './BVLoader20.scss';

const BVLoader20 = (props) => {
  return (
    <div className="common-loader-wrapper theme-light-background flex justify-center items-center absolute h-full w-full opacity-100 min-h-loader" /* FOUC fix: */ style={{ visibility: 'hidden', backgroundColor: `${props.bgColor}` }}>
      <div className="loader-outer left-0 right-0 absolute top-1/2">
        <div className="loader my-0 mx-auto min-w-loader flex justify-center">
          <svg
            version="1.1"
            id="BV2"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            xmlSpace="preserve"
            width={props.width}
            height={props.width}
          >
            <rect id="hp1" className="horizontal1 pill" x="4" y="4" stroke="#0a61ad" strokeWidth="2" fill="#0a61ad" width="93" height="10" />
            <rect id="hp2" className="horizontal2 pill" x="4" y="28" stroke="#0a61ad" strokeWidth="2" fill="#0a61ad" width="42" height="10" />
            <rect id="o1" className="outlinedPrimary radius" x="4" y="50" stroke="#0a61ad" strokeWidth="8" fill="#fff" width="39" height="39" />
            <g transform="scale(1,-1) translate(0,-100)">
              <rect id="o2" className="outlinedSecondary radius" x="4" y="-48" stroke="#0a61ad" strokeWidth="8" fill="#fff" width="39" height="39" />
              <rect id="s1" className="solidPrimary radius" x="54" y="-48" stroke="#0a61ad" strokeWidth="2" fill="#0a61ad" width="44" height="44" />
            </g>
          </svg>
        </div>
        <div className="text-wrap max-h-loader max-w-loader flex justify-center items-center my-5 mx-auto">
          <CommonLoader width={'80px'} fill={props.fill} />
        </div>
      </div>
    </div>
  );
};

BVLoader20.propTypes = {
  bgColor: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
};

BVLoader20.defaultProps = {
  bgColor: '#f8f9fc',
  width: '50px',
  fill: '#0a61ad',
};

export default BVLoader20;
