import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faExclamationCircle, faCircleXmark , faCheckCircle , faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import LocalizedText from './Translations/LocalizedText';
import AccessibleIcon from './Translations/AccessibleIcon';

const NotificationCard = ({
    type,
    title,
    messageLine1,
    messageLine2,
    messageLine1Key,
    messageLine2Key,
    titleKey
  }) => {
  let icon;
  let defaultHeader;
  let utilityClasses;
  switch (type) {
    case 'success':
      icon = faCheckCircle;
      defaultHeader = 'success';
      utilityClasses = { background: 'text-green-900', textColor: 'text-theme-medium', containerBackground: 'bg-[#E5FFF5]' };
      break;
    case 'error':
      icon = faCircleXmark;
      defaultHeader = 'Error!';
      utilityClasses = { background: 'text-red-800', textColor: 'text-theme-medium', containerBackground: 'bg-[#FFEBE6]', textClass: 'pb-1 pt-2' };
      break;
    case 'warning':
      icon = faExclamationCircle;
      defaultHeader = 'warning';
      utilityClasses = { background: 'text-yellow-600', textColor: 'text-theme-medium', containerBackground: 'bg-[#FFFAE5]' };
      break;
    case 'info':
    default:
      icon = faInfoCircle;
      defaultHeader = 'info';
      utilityClasses = { background: 'text-blue-700', textColor: 'text-theme-medium', containerBackground: 'bg-[#E5F4FF]' };
  }

 
  return (
    <div className={`flex items-center ${utilityClasses.containerBackground} p-4 mb-4`} data-test="notification-card-container">
      <div className="mr-4">
        <AccessibleIcon icon={icon} classes={`text-2xl ${utilityClasses.background} `} labeledBy={titleKey} />
      </div>

      <div className={`${utilityClasses.textColor} ${utilityClasses.textClass}`}>
        <h1 className={`text-[14px] mb-0 not-italic font-bold text-sm leading-6`}>{titleKey ? <LocalizedText localeKey={titleKey} id={titleKey}/> : title}</h1>
        <p className={`text-[12px] font-normal not-italic text-xs leading-4 ${utilityClasses.textColor} mb-2`}>{messageLine1Key ? <LocalizedText localeKey={messageLine1Key}/> : messageLine1}<br/>{messageLine2Key ? <LocalizedText localeKey={messageLine2Key} /> : messageLine2}</p>
      </div>
    </div>
  )
};

export default NotificationCard;
