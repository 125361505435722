import { LicenseManager } from 'ag-grid-enterprise';
// component imports
import AlertCard from 'components/AlertCard';
import AppsTray from 'components/AppsTray/AppsTray';
import NotificationCard from 'components/NotificationCard';
import Button, { ButtonType } from 'components/button';
import BVLoader20 from 'components/Loaders/BVLoader20';
import CommonLoader from 'components/Loaders/CommonLoader';
import DataAccessEditor from 'components/DataAccessEditor/DataAccessEditor';
import DropdownMenu from 'components/DropdownMenu/DropdownMenu';
import MidSelector from 'components/DataAccessEditor/MidSelector/MidSelector';
import NavigationWarning from 'components/NavigationWarning';
import PanelDropdown from 'components/PanelDropdown';
import Pill from 'components/pill';
import Grid from 'components/Grid/grid';
import GridHeader from 'components/Grid/header/grid-header';
import PortfolioSelector from 'components/DataAccessEditor/PortfolioSelector';
import SearchByMid from 'components/DataAccessEditor/SearchByMid/SearchByMid';
import HierarchyDescriptorLabel from 'components/DataAccessEditor/SearchByHierarchy/HierarchyDescriptorLabel';
import CustomFilter from 'components/Filters/CustomFilter';
import DateFilter from 'components/Filters/DateFilter';
import ListFilter from 'components/Filters/ListFilter';
import ListOption from 'components/Filters/ListOption';
import TextFilter from 'components/Filters/TextFilter';
import FilterBar from 'components/Filters/FilterBar';
import OmniSearch from 'components/Filters/OmniSearch';
import LogoutTimer from 'components/LogoutTimer/LogoutTimer';
import LogoutTimerLayout from 'components/LogoutTimer/LogoutTimerLayout';
import LocalizedText from 'components/Translations/LocalizedText';
import AccessibleIcon from 'components/Translations/AccessibleIcon';
import RemoveFilterButton from 'components/Filters/buttons/RemoveFilterButton';
import ExportFile from 'components/ExportFile/ExportFile';

// hooks
import useApi from 'hooks/use-api';
import useDataHierarchy from 'hooks/use-hierarchy';
import useMerchantList from 'hooks/use-merchant-list';
import useRoles from 'hooks/use-roles';
import useUser from 'hooks/use-user';
import getBrands from 'hooks/use-brands';
import useDataAccessGroup from 'hooks/use-data-access-group';
import useTranslations from 'hooks/use-translations';
import useLocale from 'hooks/use-locale';
import useTags from 'hooks/use-tags';
import useDateTimeFormats from 'hooks/use-date-time-formats';

// utils
import authSession from 'utils/authSession';
import useDebug from 'utils/debug';

// context
import { PortalAppContextProvider, usePortalAppContext} from 'context/portal-app-context';

// interfaces
import  { gridInterfaces, userInterfaces, applicationInterfaces, filter, assignedAppInterfaces} from 'interfaces';

// enums
import { CellShape } from 'enums';

import "broadcastchannel-polyfill";

LicenseManager.setLicenseKey('CompanyName=Global Payments (NA, Alpharetta, GA),LicensedGroup=Global Payments,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=5,LicensedProductionInstancesCount=0,AssetReference=AG-028742,SupportServicesEnd=19_July_2023_[v2]_MTY4OTcyMTIwMDAwMA==e80c95f8d12024092d89d64d2712ab0a');

export {
  // components
  AlertCard,
  AppsTray,
  NotificationCard,
  BVLoader20,
  Button,
  ButtonType,
  CommonLoader,
  DataAccessEditor,
  DropdownMenu,
  HierarchyDescriptorLabel,
  MidSelector,
  SearchByMid,
  NavigationWarning,
  PanelDropdown,
  Pill,
  Grid,
  GridHeader,
  PortfolioSelector,
  CustomFilter,
  DateFilter,
  ListFilter,
  ListOption,
  TextFilter,
  OmniSearch,
  FilterBar,
  LogoutTimer,
  LogoutTimerLayout,
  LocalizedText,
  AccessibleIcon,
  RemoveFilterButton,
  ExportFile,

  // context
  PortalAppContextProvider,

  // hooks
  useApi,
  useDataHierarchy,
  useMerchantList,
  usePortalAppContext,
  useRoles,
  useUser,
  getBrands,
  useDataAccessGroup,
  useTranslations,
  useLocale,
  useDateTimeFormats,
  useTags,

  // utils
  authSession,
  useDebug,

  // interfaces
  gridInterfaces,
  filter,
  userInterfaces,
  applicationInterfaces,
  assignedAppInterfaces,

  // enums
  CellShape
}
