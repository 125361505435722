import { usePortalAppContext } from "context/portal-app-context";

const useDateTimeFormats = () => {
    const { appPreferences }: { [key: string]: any} = usePortalAppContext();
    const { dateFormat = 'MM/DD/YYYY', timeFormat = 'hh:mm:xm' }: { dateFormat: string, timeFormat: string } = appPreferences;
    const formatDateTime = (d: string) => {
        const dateObject = d && new Date(d);

        const dateFormatMap: { [key: string]: string } = {
            'MM/DD/YYYY': 'en-US',
            'DD/MM/YYYY': 'en-GB',
            'YYYY/MM/DD': 'zh-TW'
        }
      
        const timeFormatMap: { [key: string]: string } = {
            'hh:mm:xm': 'en-US',
            'HH:mm': 'en-GB'
        }
      
        return {
            formattedDate: dateObject ? dateObject.toLocaleDateString(dateFormatMap[dateFormat], { year: "numeric", month: "2-digit", day: "2-digit"}) : '',
            formattedTime: dateObject ? dateObject.toLocaleTimeString(timeFormatMap[timeFormat], { hour: "2-digit", minute: "2-digit" }) : '',
            formattedTimeWithSecond: dateObject ? dateObject.toLocaleTimeString(timeFormatMap[timeFormat], { hour: "2-digit", minute: "2-digit", second: "2-digit" }) : '',
        }
    }

    return {
        formatDateTime,
        dateFormat, 
        timeFormat 
    }
}

export default useDateTimeFormats;
