const css = `.common-loader-wrapper {
  /* FOUC fix: */
  visibility: visible !important;
}
.common-loader-wrapper .loader-outer .loader svg#BV2 rect {
  rx: 3;
}
.common-loader-wrapper .loader-outer .loader svg#BV2 rect.pill {
  rx: 4;
}
.common-loader-wrapper .loader-outer .loader svg#BV2 rect#hp1 {
  animation: horizontal1 4.2s cubic-bezier(0.83, -0.49, 0.26, 1.47) forwards, horizontal1B 4.2s cubic-bezier(0.83, -0.49, 0.26, 1.47) infinite;
}
.common-loader-wrapper .loader-outer .loader svg#BV2 rect#hp2 {
  animation: horizontal2 4.2s cubic-bezier(0.83, -0.49, 0.26, 1.47) forwards, horizontal2B 4.2s cubic-bezier(0.83, -0.49, 0.26, 1.47) infinite;
}
.common-loader-wrapper .loader-outer .loader svg#BV2 rect#o1 {
  animation: translateo1up 4.2s cubic-bezier(0.83, -0.49, 0.26, 1.47) infinite forwards;
}
.common-loader-wrapper .loader-outer .loader svg#BV2 rect#o2 {
  animation: translateo2up 4.2s cubic-bezier(0.83, -0.49, 0.26, 1.47) infinite forwards;
}
.common-loader-wrapper .loader-outer .loader svg#BV2 rect#s1 {
  animation: startSolid 4.2s cubic-bezier(0.83, -0.49, 0.26, 1.47) forwards, finishSolid 4.2s cubic-bezier(0.83, -0.49, 0.26, 1.47) infinite;
}

@keyframes fadeLoader {
  0% {
    opacity: 0;
  }
  10%, 100% {
    opacity: 1;
  }
}
@keyframes translateo1up {
  0%, 30% {
    height: 39%;
    transform: translate(0, 5%);
  }
  37.15%, 74.5% {
    height: 39%;
    transform: translate(0, -46%);
  }
  81.65% {
    height: 39%;
    transform: translate(0, -94%);
  }
  83.25%, 88.55% {
    height: 0%;
    transform: translate(-46%, -98%);
  }
  89% {
    height: 39%;
    transform: translate(0, 48%);
  }
  96%, 100% {
    height: 39%;
    transform: translate(0, 5%);
  }
}
@keyframes translateo2up {
  0%, 30% {
    transform: translate(0, 2%);
  }
  37.15%, 44.6% {
    transform: translate(0, 52%);
  }
  52%, 61.5% {
    transform: translate(52%, 52%);
  }
  70%, 91% {
    transform: translate(52%, 104%);
  }
  98%, 100% {
    transform: translate(52%, 152%);
  }
}
@keyframes horizontal1 {
  0% {
    width: 0%;
  }
  12.85%, 21.4% {
    width: 93%;
  }
  28.5% {
    width: 0%;
  }
  30%, 45.6% {
    width: 0%;
    transform: translateY(55%);
  }
  53.5%, 74.5% {
    width: 42%;
    transform: translateY(55%);
  }
  84.65%, 96% {
    width: 42%;
    transform: translateY(0%);
  }
  100% {
    width: 93%;
    transform: translateY(0%);
  }
}
@keyframes horizontal1B {
  0%, 21.4% {
    width: 93%;
    transform: translateY(0%);
  }
  28.5% {
    width: 0%;
    transform: translateY(0%);
  }
  30%, 45.6% {
    width: 0%;
    transform: translateY(55%);
  }
  53.5%, 74.5% {
    width: 42%;
    transform: translateY(55%);
  }
  84.65%, 96% {
    width: 42%;
    transform: translateY(0%);
  }
  100% {
    width: 93%;
    transform: translateY(0%);
  }
}
@keyframes horizontal2 {
  0% {
    width: 0%;
    transform: translateY(0%);
  }
  12.85%, 21.4% {
    width: 42%;
    transform: translateY(0%);
  }
  28.5% {
    width: 0%;
    transform: translateY(0%);
  }
  30%, 45.6% {
    width: 0%;
    transform: translateY(55%);
  }
  53.5%, 74.5% {
    width: 42%;
    transform: translateY(55%);
  }
  84.65%, 96% {
    width: 42%;
    transform: translateY(0%);
  }
  100% {
    width: 42%;
    transform: translateY(0%);
  }
}
@keyframes horizontal2B {
  0%, 21.4% {
    width: 42%;
    transform: translateY(0%);
  }
  28.5% {
    width: 0%;
    transform: translateY(0%);
  }
  30%, 45.6% {
    width: 0%;
    transform: translateY(55%);
  }
  53.5%, 74.5% {
    width: 42%;
    transform: translateY(55%);
  }
  84.65%, 96% {
    width: 42%;
    transform: translateY(0%);
  }
  100% {
    width: 42%;
    transform: translateY(0%);
  }
}
@keyframes startSolid {
  0% {
    height: 0%;
    transform: translate(0, 0);
  }
  6.9% {
    height: 63.8%;
  }
  12%, 21.5% {
    height: 72.6%;
  }
  27%, 37% {
    height: 94%;
  }
  44%, 62% {
    height: 44%;
    transform: translate(0, 101%);
  }
  65% {
    height: 44%;
    transform: translate(0, 150%);
  }
  65.5% {
    height: 0%;
    transform: translate(0, 150%);
  }
  66% {
    height: 0%;
    transform: translate(0, 0);
  }
  70%, 92% {
    height: 44%;
    transform: translate(0, 50%);
  }
  98%, 100% {
    height: 72.6%;
    transform: translate(0, 50%);
  }
}
@keyframes finishSolid {
  0%, 21.5% {
    height: 72.6%;
    transform: translate(0, 50%);
  }
  27%, 37% {
    height: 94%;
    transform: translate(0, 50%);
  }
  44%, 62% {
    height: 44%;
    transform: translate(0, 101%);
  }
  65% {
    height: 44%;
    transform: translate(0, 150%);
  }
  65.5% {
    height: 0%;
    transform: translate(0, 150%);
  }
  66% {
    height: 0%;
    transform: translate(0, 0);
  }
  70%, 92% {
    height: 44%;
    transform: translate(0, 50%);
  }
  98%, 100% {
    height: 72.6%;
    transform: translate(0, 50%);
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvamVua2lucy9hZ2VudC93b3Jrc3BhY2UvRnJvbnRlbmRfcG9ydGFsLWNvbW1vbi11aV9tYXN0ZXIvc3JjL2NvbXBvbmVudHMvTG9hZGVycy9CVkxvYWRlcjIwIiwic291cmNlcyI6WyJCVkxvYWRlcjIwLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBR0E7QUFDRTtFQUFnQjs7QUFJVjtFQUNFOztBQUNBO0VBQ0U7O0FBRUY7RUFDRSxXQUNFOztBQUdKO0VBQ0UsV0FDRTs7QUFHSjtFQUNFLFdBQ0U7O0FBRUo7RUFDRSxXQUNFOztBQUVKO0VBQ0UsV0FDRTs7O0FBU2Q7RUFDRTtJQUNFOztFQUVGO0lBQ0U7OztBQUlKO0VBQ0U7SUFDRTtJQUNBOztFQUVGO0lBQ0U7SUFDQTs7RUFFRjtJQUNFO0lBQ0E7O0VBRUY7SUFDRTtJQUNBOztFQUVGO0lBQ0U7SUFDQTs7RUFFRjtJQUNFO0lBQ0E7OztBQUdKO0VBQ0U7SUFDRTs7RUFFRjtJQUNFOztFQUVGO0lBQ0U7O0VBRUY7SUFDRTs7RUFFRjtJQUNFOzs7QUFHSjtFQUNFO0lBQ0U7O0VBRUY7SUFDRTs7RUFFRjtJQUNFOztFQUVGO0lBQ0U7SUFDQTs7RUFFRjtJQUNFO0lBQ0E7O0VBRUY7SUFDRTtJQUNBOztFQUVGO0lBQ0U7SUFDQTs7O0FBR0o7RUFDRTtJQUNFO0lBQ0E7O0VBRUY7SUFDRTtJQUNBOztFQUVGO0lBQ0U7SUFDQTs7RUFFRjtJQUNFO0lBQ0E7O0VBRUY7SUFDRTtJQUNBOztFQUVGO0lBQ0U7SUFDQTs7O0FBR0o7RUFDRTtJQUNFO0lBQ0E7O0VBRUY7SUFDRTtJQUNBOztFQUVGO0lBQ0U7SUFDQTs7RUFFRjtJQUNFO0lBQ0E7O0VBRUY7SUFDRTtJQUNBOztFQUVGO0lBQ0U7SUFDQTs7RUFFRjtJQUNFO0lBQ0E7OztBQUdKO0VBQ0U7SUFDRTtJQUNBOztFQUVGO0lBQ0U7SUFDQTs7RUFFRjtJQUNFO0lBQ0E7O0VBRUY7SUFDRTtJQUNBOztFQUVGO0lBQ0U7SUFDQTs7RUFFRjtJQUNFO0lBQ0E7OztBQUdKO0VBQ0U7SUFDRTtJQUNBOztFQUVGO0lBQ0U7O0VBRUY7SUFDRTs7RUFFRjtJQUNFOztFQUVGO0lBQ0U7SUFDQTs7RUFFRjtJQUNFO0lBQ0E7O0VBRUY7SUFDRTtJQUNBOztFQUVGO0lBQ0U7SUFDQTs7RUFFRjtJQUNFO0lBQ0E7O0VBRUY7SUFDRTtJQUNBOzs7QUFJSjtFQUNFO0lBQ0U7SUFDQTs7RUFFRjtJQUNFO0lBQ0E7O0VBRUY7SUFDRTtJQUNBOztFQUVGO0lBQ0U7SUFDQTs7RUFFRjtJQUNFO0lBQ0E7O0VBRUY7SUFDRTtJQUNBOztFQUVGO0lBQ0U7SUFDQTs7RUFFRjtJQUNFO0lBQ0EifQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
