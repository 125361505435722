import useUser from "./use-user";

const usePermissions = () => {

    const user = useUser();

    // todo: Enum keys after typescript conversion
    const hasPermission = (permissionKey: string) => {
        return user.authenticatedUser.role.permissions.some((x: any) => x.key === permissionKey);
    };

    return {
        hasPermission,
    }
};

export default usePermissions;
