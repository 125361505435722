import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const RemoveSelectedRenderer = ({ node, removeSelection }) => {
    const removeItem = () => {
        removeSelection({ hierarchyId: node.data.hierarchyId, merchantId: node.data.merchantId });
    }

    return (
        <button onClick={removeItem} className="bg-theme-on-primary border-0">
            <FontAwesomeIcon icon={faTimes} size="md" className="text-theme-danger" />
        </button>
    );
}

export default RemoveSelectedRenderer;