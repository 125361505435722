
const useTranslations = () => {
  const messages = {};
  messages['alerts.generalLimit.limitError'] = 'We\'re sorry, but due to system limitations we cannot navigate you to the page you have requested. Please use the filtering or search functionality to narrow down the number of results.';
  messages['merchantDemographicsPage.actions.view-details'] = 'View Details';
  messages['dataAccessManagementPage.actions.view-group'] = 'View Group';
  messages['dataAccessManagementPage.actions.view-edit-group'] = 'View/Edit Group';
  messages['dataAccessManagementPage.actions.delete-group'] = 'Delete Group';
  messages['userManagementPage.actions.viewUser'] = 'View User';
  messages['userManagementPage.actions.viewEditUser'] = 'View/Edit User';
  messages['userManagementPage.actions.cloneUser'] = 'Clone User';
  messages['userManagementPage.actions.activate'] = 'Activate User';
  messages['userManagementPage.actions.deactivate'] = 'Deactivate User';
  messages['userManagementPage.actions.lock'] = 'Lock User';
  messages['userManagementPage.actions.delete'] = 'Delete User';
  messages['alerts.dataAccessGroup.confirmation.delete.title'] = 'Deleting Data Access Group';
  messages['alerts.dataAccessGroup.confirmation.delete.message'] = 'Are you sure you want to delete this data access group?';
  messages['alerts.dataAccessGroup.confirmation.delete.continueCaption'] = 'Yes, Delete';
  messages['alerts.dataAccessGroup.confirmation.delete.cancelCaption'] = 'Cancel';
  messages['alerts.dataAccessGroup.deleteSuccess.message'] = 'Data Access group has been successfully deleted.  Please note it may take a few minutes to see the changes reflected on the screen.';
  messages['alerts.dataAccessGroup.users.assigned.title'] = 'Cannot Delete Group';
  messages['alerts.dataAccessGroup.users.assigned.message'] = 'You cannot delete Custom Data Access groups that currently have users assigned to them.  You must remove all users from this group before deletion can occur.';
  messages['alerts.user.alreadyAssigned.full.access.title'] = 'Cannot Assign User to Group';
  messages['alerts.user.alreadyAssigned.full.access.message'] = ' This user is already assigned full access to the MIDs available and therefore cannot be assigned redundant access.';
  messages['alerts.user.hierarchy.accessDenied.message'] = 'Calling user does not have access to the requested hierarchy.';
  messages['alerts.generalError.title'] = 'Error';
  messages['alerts.generalError.message'] = 'An error occurred while processing your request.  Please try again later.';
  messages['alerts.cannot.delete.data.access.group.title'] = 'Cannot Remove User';
  messages['alerts.cannot.delete.data.access.group.message'] = 'This custom group is the only data access assigned to this user. In order to remove them from this group, they must be assigned separate data access using the User Management tool. If you do not have this permission, please contact your Account Administrator.';
  messages['alerts.cannot.create.data.access.group.title'] = 'Group Creation Failed';
  messages['alerts.cannot.create.data.access.group.message'] = "We’re sorry, but we experienced an error when trying to create this Data Access group. Please check the information you have entered and try again.";
  messages['alerts.user.hierarchyAssignment.alreadyAssigned.title'] = 'User Already Added';
  messages['alerts.user.hierarchyAssignment.alreadyAssigned.message'] = "The user you selected has already been added to this data access group.  If you intended to add a different user, please try again.";
  messages['notification.card.warning.title'] = "DATA ACCESS WARNING";
  messages['notification.card.warning.clone.user.notification.message1'] = "The user you have selected to clone has access to MIDs outside of your data set."
  messages['notification.card.warning.clone.user.notification.message2'] = "The new user you are creating will only be given access to the MIDs you both share."
  messages['cloneuser.basic.info.page'] ="User being cloned";
  messages['cloneuser.basic.info.page.create.new.user'] ="Please enter the information for the new user below"
  messages['clone.select.user'] = 'Please select the user you would like to clone:';
  messages['clone.user.search.results'] = 'Search Results:'
  messages['applications.mp-reporting.role.acquirerAdmin.name'] = 'Third Party Administrator';
  messages['applications.mp-reporting.role.acquirerUser.name'] = 'Third Party User';
  messages['applications.mp-reporting.role.customerService.name'] = 'Customer Service';
  messages['applications.mp-reporting.role.customUser.name'] = 'Custom User';
  messages['applications.mp-reporting.role.internalEmployee.name'] = 'Internal Employee';
  messages['applications.mp-reporting.role.limitedPartner.name'] = 'Limited Partner';
  messages['applications.mp-reporting.role.masterFileAnalyst.name'] = 'Master File Analyst';
  messages['applications.mp-reporting.role.merchantAccountAdministrator.name'] = 'Account Administrator';
  messages['applications.mp-reporting.role.merchantApiUser.name'] = 'System Administrator';
  messages['applications.mp-reporting.role.merchantSalesSupportManager.name'] = 'Sales Support Manager';
  messages['applications.mp-reporting.role.merchantUser.name'] = 'Account User';
  messages['applications.mp-reporting.role.regionalProductManager.name'] = 'Regional Product Manager';
  messages['applications.mp-reporting.role.techSupportAdmin.name'] = 'System Administrator';
  messages['applications.mp-reporting.role.worldwideProductManager.name'] = 'Global Product Manager';
  const translate = (key) => {
    return messages[key];
  };

  return {
    translate,
  };
};

export default useTranslations;