import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import LocalizedText from "components/Translations/LocalizedText";
import { localeKeys } from "hooks/use-locale";  

export interface OmniSearchProps {
  searchHandler: (val: string[]) => void;
  regex?: string | ((val: string) => boolean);
  classes?: string;
  minChars?: number;
  placeholder?: string;
  errorMessageKey?: string;
  allowedSpaces?: number;
  initialValue: string;
  allowDuplicateTrigger?: boolean;
  prefixIcon?: React.ReactNode;
}

export const OmniSearch: React.FC<OmniSearchProps> = ({
  searchHandler,
  classes,
  placeholder,
  errorMessageKey,
  regex,
  minChars = 2,
  allowedSpaces = 3,
  initialValue = '',
  allowDuplicateTrigger = false,
  prefixIcon,
}) => {
  const [serchText, setSerchText] = useState(initialValue);
  const [oldSerchText, setOldSerchText] = useState(initialValue);
  const [notValidText, setNotValidText] = useState(false);
  const [executed, setExecuted] = useState(false);
  const [omniSearchValues, setOmniSearchValues] = useState<string[]>([]);
  const initialRender = useRef(true);
  const inputRef = useRef(null);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      searchHandler(omniSearchValues);
    }
  }, [omniSearchValues]);

  useEffect(() => {
    if (regex) {
      if (typeof regex === "string") {
        const reg = new RegExp(regex);
        setNotValidText(!reg.test(serchText));
      } else {
        setNotValidText(!regex(serchText));
      }
    }
  }, [serchText]);

  let inputClasses = [
    "text-sm",
    "border",
    "border-solid",
    "border-gray-200",
    "rounded-sm",
    "p-3",
    "pl-10",
    "text-theme-dark",
    "font-normal",
    "h-input",
    "w-logo-page sml:w-full",
  ];

  if (classes) {
    inputClasses = [...inputClasses, ...classes.split(" ")];
  }

  const handleSearch = (e: any) => {
    setSerchText(e.target.value);
    setExecuted(false);
  };

  const onSearch = (e: any) => {
    const inputNotFocused = document.activeElement !== inputRef.current;

    if (
      (e.keyCode === 13 || (inputNotFocused && !executed)) &&
      !notValidText &&
      !inValidTokensCount &&
      (serchText.trim().length >= minChars || serchText.length === 0) &&
      (oldSerchText !== serchText || allowDuplicateTrigger)
    ) {
      setOldSerchText(serchText);
      setOmniSearchValues(searchTokens);
      setExecuted(true);
    }
  };

  const searchTokens = serchText.trim().split(" ") || [];
  const inValidTokensCount = searchTokens.length > allowedSpaces;

  return (
    <div className="relative sml:w-full">
      <div className="relative">
        {prefixIcon ? prefixIcon : <FontAwesomeIcon icon={faMagnifyingGlass as IconProp} className="absolute top-[18px] left-[12px] pointer-events-none"/>}
        <input
          type="text"
          id="quickFilter"
          value={serchText}
          onKeyDown={onSearch}
          onChange={handleSearch}
          onBlur={onSearch}
          className={inputClasses.join(" ")}
          placeholder={placeholder ?? "Search"}
          data-test="grid-omni-search"
          ref={inputRef}
        />
      </div>
      <div data-test="grid-omni-search-error-message">
      {inValidTokensCount ? (
        <div className="absolute text-xs w-full bg-gray-100 z-10 border-theme-light-border border border-solid px-2 py-2">
          <LocalizedText localeKey="filters.omniSearch.maxPhrases" templateVariables={{ allowedSpaces }} />
        </div>
      ) : null}
      {serchText && serchText.trim().length < minChars ? (minChars === 1 ?
        <div className="absolute text-xs w-full bg-gray-100 z-10 border-theme-light-border border border-solid px-2 py-2" data-test="grid-search-two-characters-error">
           <LocalizedText localeKey="filters.omniSearch.minChars"  templateVariables={{ minChars }} />
        </div> :
        <div className="absolute text-xs w-full bg-gray-100 z-10 border-theme-light-border border border-solid px-2 py-2" data-test="grid-search-two-characters-error">
            <LocalizedText localeKey="filters.omniSearch.minChars"  templateVariables={{ minChars }} />
        </div>
      ) : null}
      {serchText && serchText.trim().length >= minChars && errorMessageKey && notValidText ? (
        <div className="absolute text-xs w-full bg-gray-100 z-10 border-theme-light-border border border-solid px-2 py-2">
          <LocalizedText localeKey={errorMessageKey as localeKeys} />
        </div>
      ) : null}
    </div>
    </div>
  );
};

export default OmniSearch;
