import throttle from 'lodash.throttle';
import workerUrl from 'worker:./idleWorker.js';

const defaultEvents = ['mousedown', 'keydown', 'touchstart', 'wheel'];

const createIdleDetector = (seconds, onStateChange, onUserActivity, initialState = false, events = defaultEvents) => {
  let state = initialState;
  let lastActivityTime = Date.now();
  const ms = seconds * 1000;
  const bc = new BroadcastChannel('authSessionChannel');
  const worker = new Worker(workerUrl);

  const set = (newState) => {
    state = newState;
    if (onStateChange) {
      onStateChange(newState);
    }
  };

  const onEvent = throttle(() => {
    lastActivityTime = Date.now();
    const authSessionState = JSON.parse(sessionStorage.getItem('authSessionState'));
    
    onUserActivity();
    bc.postMessage({ action: 'user-activity', tabState: { tabId: authSessionState?.tabId, status: 'Active', instanceId: authSessionState?.instanceId }});
    sessionStorage.setItem('authSessionState', JSON.stringify({ ...authSessionState, status: 'Active'}));

    if (state) {
      set(false);
    }

    worker.postMessage({ type: 'update', lastActivity: lastActivityTime });
  }, 500);

  const onVisibility = () => {
    if (!document.hidden) {
      onEvent();
    }
  };

  const onIframeActivity = (msg) => {
    if (msg?.data?.type === '__IFRAME_USER_ACTIVITY') {
      onEvent();
    }
  };

  for (let i = 0; i < events.length; i += 1) {
    window.addEventListener(events[i], onEvent);
  }
  window.addEventListener('message', onIframeActivity);
  document.addEventListener('visibilitychange', onVisibility);

  worker.onmessage = (e) => {
    if (e.data.expired && !state) {
      set(true);
    }
  };

  worker.postMessage({ type: 'start', interval: ms, lastActivity: lastActivityTime });

  return {
    teardown: () => {
      for (let i = 0; i < events.length; i += 1) {
        window.removeEventListener(events[i], onEvent);
      }
      window.removeEventListener('message', onIframeActivity);
      document.removeEventListener('visibilitychange', onVisibility);
      worker.postMessage({ type: 'stop' });
      bc.close();
    },
    getState: () => state,
  };
};

export default createIdleDetector;
