import React from 'react'
import { ColumnFunctionCallbackParams } from 'ag-grid-community';

interface ICustomColumnItems {
    data: any,
    customString: string,
    customComponent: (data: any) => React.ReactNode,
}

export interface ICustomColumn extends ICustomColumnItems, ColumnFunctionCallbackParams {}; 

const TextColumn: React.FC<ICustomColumn> = ({ data, customComponent}) => {

    return (
        <div>
            {customComponent(data)}
        </div>
    )
}

export default TextColumn;
