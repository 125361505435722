import React, { useState, useEffect} from 'react';
import { AgGridEvent } from 'ag-grid-community';

export interface ICheckboxHeader {
  onToggleSelectAll: (checked: boolean, api: AgGridEvent['api']) => void,
  selectAllIsChecked: boolean,
  setSelectAllIsChecked: React.Dispatch<React.SetStateAction<boolean>>,
  areAllVisibleRowsSelected: (api: AgGridEvent['api']) => boolean,
  api: AgGridEvent['api'],
}

export const CheckboxHeader: React.FC<ICheckboxHeader> = ({ onToggleSelectAll, areAllVisibleRowsSelected, api }) => {

  const [selectAllIsChecked, setSelectAllIsChecked] = useState(false);

  useEffect(() => {
    if (!areAllVisibleRowsSelected) return;
    const allChecked = areAllVisibleRowsSelected(api);
    setSelectAllIsChecked(allChecked);
  }, []);

  const onChangeSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setSelectAllIsChecked(checked)
    onToggleSelectAll(checked, api);
  };
  
  return (
      <>
        <div className={`flex flex-col w-fit-content z-50`} aria-label="Checbox Select All">
          <div className={`flex items-center justify-center text-sm border-solid border-gray-200 rounded-sm`}>
          <input type="checkbox" onChange={onChangeSelectAll} checked={selectAllIsChecked} />
          </div>
        </div>
      </>
  );
};

export default CheckboxHeader;
