import useFetch from 'use-http';
import { usePortalAppContext } from 'context/portal-app-context';
import { v4 as createUuid} from 'uuid';

const useApi = (url, options) => {
  const { getSessionToken } = usePortalAppContext();
  const getToken = () => {
    return getSessionToken();
  };

  let tid = '';
  const defaultOptions = {
    interceptors: {
      request: async ({ options, url, path, route }) => {
        tid = createUuid();
        options.headers.Authorization = options.headers.Authorization || getToken();
        // todo: reenable this next sprint so it can be tested the full sprint
        // options.headers.tid = tid;
        return options;
      }
    }
  };
  const httpOptions = { ...defaultOptions, ...options };
  const { get, post, put, patch, del, options:opts, response, loading, error, abort } = useFetch(url, httpOptions);

  const setToken = (t) => {
    token = t;
  };


  return {
    setToken,
    get,
    post,
    put,
    patch,
    del,
    opts,
    response,
    getLastTid: () => { console.log('getLastTid'); return tid; },
    loading,
    error,
    abort,
  };
}

export default useApi;
