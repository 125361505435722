import React, { SyntheticEvent, useEffect, useState, useRef } from 'react';
import { localeKeys } from 'hooks/use-locale';
import Button, { ButtonType } from 'components/button';

export interface LocalizedTextEditorProps {
    localeName: string;
    localeKey: localeKeys;
    englishText: string;
    translatedText?: string;
    onSave: (translatedText?: string) => void;
    onCancel: (e?: SyntheticEvent) => void;
}

const LocalizedTextEditor: React.FC<LocalizedTextEditorProps> = ({ localeName, englishText, translatedText, onSave, onCancel }) => {

    const translateTextRef = useRef(null);
    useEffect(() => {
        (translateTextRef?.current as any)?.focus();        
    }, []);
    
    const [currentTranslation, setCurrentTranslation] = useState<string | undefined>(translatedText);

    const onClickSave = (e?: SyntheticEvent) => {
        e?.stopPropagation();
        e?.nativeEvent.stopImmediatePropagation();     
        e?.preventDefault();
        if (currentTranslation !== translatedText) {
            onSave(currentTranslation) 
        } else { 
            onCancel();
        }
    };

    const onTextClick = (e?: SyntheticEvent) => {
        e?.stopPropagation();
        e?.nativeEvent.stopImmediatePropagation();
        e?.preventDefault();
    }

    const onTranslationTextChanged = (e?: SyntheticEvent) => {
        setCurrentTranslation((e?.target as any).value);
    };

    const onDialogClick = (e: SyntheticEvent) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        e.preventDefault();
    }

    const translationElement: JSX.Element =  
        <div className="px-4 py-6" onClick={(e: SyntheticEvent) => onDialogClick(e)}>
            <table className="border-spacing-px">
                <tbody>
                    <tr>
                        <td><div className="pr-2 font-semibold">English (US):</div></td>
                        <td>
                            <input className="bg-slate-100 border p-1 w-[360px]" readOnly value={englishText} />
                        </td>
                    </tr>
                    <tr>
                        <td><div className="pr-2 font-semibold">{localeName}:</div></td>
                        <td><input ref={translateTextRef} className="border mt-4 p-1 w-[360px]" type="text" onMouseDown={onTextClick} placeholder='Enter translation text' defaultValue={translatedText} onChange={onTranslationTextChanged}></input></td>
                    </tr>
                </tbody>
            </table>
            <div className="mt-2 pt-2 flex">
                <div className="flex-none">
                    <Button 
                        title="Cancel"
                        buttonClass="text-red-700"
                        onClick={onCancel}
                        buttonType={ButtonType.LinkButton} 
                    />
                    
                </div>
                <div className="flex flex-1 justify-end">
                    <Button
                        title="Save"
                        onClick={(e?: SyntheticEvent) => onClickSave(e)}
                        buttonType={ButtonType.PrimaryButton} />
                    
                </div>
            </div>
        </div>;

    return translationElement;
}

export default LocalizedTextEditor;
