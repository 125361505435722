import React, { useState } from 'react';
import { usePortalAppContext } from 'context/portal-app-context';
import TrashImage from 'assets/icons/trash.svg';
import TrashDisabledImage from 'assets/icons/trash-disabled.svg';
import LocalizedText from 'components/Translations/LocalizedText';

const ViewByMid = ({ selectedMids, isDisabled = false, rowKey, onUpdateDirectMids, onDeleteRow , iconColor, lockRowFromEditing = false, multiPortfolio, canEdit }) => {

  const { enqueueAlert } = usePortalAppContext();
  const deleteSelectedMid = (deletedMid) => {
    if (selectedMids?.length > 1) {
      onUpdateDirectMids(selectedMids.filter(m => m.value !== deletedMid ));
    } else {
      onDeleteRow(rowKey);
    }
  };

  const onDelete = (deletedMid) => {
    enqueueAlert({ 
      alertType: 'modal',
      title: <LocalizedText  localeKey="hierarchySelector.selectDataAccess.alerts.deleteWarning.title" />, 
      messageType: 'warning',
      continueCaption: <LocalizedText localeKey="hierarchySelector.selectDataAccess.alerts.deleteWarning.continueCaption" />,
      cancelCaption: <LocalizedText localeKey="hierarchySelector.selectDataAccess.alerts.deleteWarning.cancelCaption" />,
      continueAction: () => {deleteSelectedMid(deletedMid)},
      cancelAction: () => {},
      message: <LocalizedText localeKey="hierarchySelector.selectDataAccess.alerts.deleteSelectedMID.message" templateVariables={{ deletedMid }}/>,
      message2: null,
      modalDialog: true,
    });    
  };
  
  const midsClass = isDisabled ? 'mx-auto border border-solid border-theme-light-border flex text-theme-dark flex-row items-center static px-4 w-10/12 h-full  bg-theme-on-primary rounded-sm'
  : 'bg-theme-on-primary border-theme-light-border mx-auto border border-solid flex text-theme-dark flex-row items-center static pl-[8px] w-full h-full  disabled:bg-theme-on-primary rounded-sm bg-opacity-100 ';

  return (selectedMids.map(m => (
    <div className="h-[40px] font-bold text-base text-theme-dark w-full">
      <div className={midsClass}>
        {multiPortfolio && <div className={`whitespace-nowrap mx-2 my-auto ${lockRowFromEditing ? 'text-theme-disabled' : 'text-theme-info'} font-medium text-base`}>{m.portfolioName}</div>}
        <div className={`pl-2 whitespace-nowrap ${lockRowFromEditing ? 'text-theme-disabled' : 'text-theme-dark'}`}>
          {m.value}
        </div>
        <div className="flex w-full justify-end">
          {canEdit && <div className="flex w-[32px]" data-test="dataaccess-delete-button" >
            <button type="button" className="bg-theme-on-primary border-0 p-[4px]" onClick={() => onDelete(m.value)} disabled={lockRowFromEditing}>
              {!lockRowFromEditing && <TrashImage/>}
              {lockRowFromEditing && <TrashDisabledImage/>}   
            </button>
          </div>}
        </div>
        {/* {isDropDownListVisible && (
        <button
          disabled="true"
          className="border border-solid border-theme-light-border static ml-auto w-6 h-6 disabled:bg-white"
        >
          <img src={TrashGrey} alt="close alert" />
        </button>
        )} */}
      </div><br />
  </div>
  )));
};

export default ViewByMid;
