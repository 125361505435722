import React, { useState, useEffect, useCallback } from 'react';
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-enterprise';
import { 
        DetailGridInfo, 
        AgGridEvent, 
        GridOptions, 
        IServerSideDatasource, 
        ColumnFunctionCallbackParams, 
        GridReadyEvent,
        RowClassParams,
        ColDef,
        ColGroupDef
    } from "ag-grid-community";
import useList from '../../hooks/use-list';
import useSortList from '../../hooks/use-sort-list';
import { gridInterfaces } from 'interfaces';
import TextColumn, { ITextColumn } from './columns/text-column';
import ActionsColumn from './columns/actions-column';
import ActionsEditor from './columns/actions-editor';
import SortableHeader from './columns/sortable-header';
import CheckboxColumn, { ICheckboxColumn } from './columns/checkbox-column';
import CheckboxHeader from './columns/checkbox-header';
import PillColumn, { IPillColumn } from './columns/pill-column';
import CustomColumn, { ICustomColumn } from './columns/custom-column';
import Pager from './pagination/pagination';
import PaginationPicker from './pagination/pagination-picker';
import DateColumn, { IDateColumn } from './columns/date-column';
import CustomLoader, { ICustomSkeletonLoader } from './misc/custom-skeleton-loader';
import CustomNoRowsOverlay from './misc/custom-no-rows-overlay';
import './grid.scss';
import RadioButtonColumn, { IRadioButtonColumn } from './columns/radio-button-column'; 
import useLocale, { localeKeys } from 'hooks/use-locale';

interface GridProps {
    pagination?: boolean,
    suppressContextMenu?: boolean,
    rowHeight?: number,
    headerHeight?: number,
    rowModelType: 'serverSide' | 'clientSide',
    serverSideStoreType?: GridOptions['serverSideStoreType'],
    cacheBlockSize?: number,
    enableCellTextSelection?: boolean,
    wrapperClass: string,
    agGridClassName: string,
    serverDatasource: gridInterfaces.IServerDatasource,
    rowData?: any[],
    selectedRows?: { [key: string]: any }[],
    onRowSelected: (row: {[key: string]: any}) => void,
    rowIdFieldName: string,
    onSelectedRowsChanged: (currentSelections: {[key: string]: any}[]) => void,
    loading?: boolean,
    setLoading?: (isLoading: boolean) => void,
    sortDefault: string,
    directionDefault?: string,
    loaderColumnDefs: ICustomSkeletonLoader['columnDefinitions'],
    customMessage: string
    paginationRowsTitleKey?: localeKeys,
    paginationClass?: string
    suppressHorizontalScroll?: boolean
    onGridLoadingChange?: (gridState: {[key: string]: any}) => void,
    retainSortings?: boolean;
    children?: React.ReactNode;
    onRowClicked?: (row: any) => void,
}

interface IUseListItems { 
    totalRowCount: number, 
    isLoading: boolean, 
    setSearchCriteria: (searchItems: gridInterfaces.IServerDatasource['reqBody'], queryStringItems: gridInterfaces.IServerDatasource['queryParams'])=> void };
interface IUseList extends IServerSideDatasource, IUseListItems  {};

type GridSubcomponents = {
    ActionsColumn: React.FC<gridInterfaces.IActionsColumn>,
    CheckboxColumn: React.FC<ICheckboxColumn>,
    TextColumn: React.FC<ITextColumn>,
    PillColumn: React.FC<IPillColumn>,
    RadioButtonColumn: React.FC<IRadioButtonColumn>,
    DateColumn: React.FC<IDateColumn>,
    CustomColumn: React.FC<ICustomColumn>,
    Pagination: React.FC,
}

const Grid: React.FC<GridProps> & GridSubcomponents = ({
    pagination = true,
    suppressContextMenu = true,
    rowHeight = 60,
    headerHeight = 44,
    rowModelType,
    serverSideStoreType = 'partial',
    cacheBlockSize = 100,
    enableCellTextSelection = false,
    wrapperClass,
    agGridClassName,
    serverDatasource,
    rowData = [],
    selectedRows,
    rowIdFieldName,
    onSelectedRowsChanged = () => {},
    onRowSelected = () => {},
    loading = false,
    setLoading = () => {},
    onGridLoadingChange,
    sortDefault,
    directionDefault = 'asc',
    loaderColumnDefs,
    paginationRowsTitleKey,
    paginationClass,
    suppressHorizontalScroll = false,
    children,
    customMessage,
    retainSortings = false,
    onRowClicked = () => {},
}) => {

    const [frameworkComponents] = useState({
        textColumnRenderer: TextColumn,
        actionsColumnRenderer: ActionsColumn,
        actionsEditor: ActionsEditor,
        checkboxColumnRenderer: CheckboxColumn,
        checkboxHeader: CheckboxHeader,
        pillColumnRender: PillColumn,
        radioButtonColumnRenderer: RadioButtonColumn,
        dateColumn: DateColumn,
        customColumnRenderer: CustomColumn,
        customLoadingOverlay: CustomLoader,
        customNoRowsOverlay: CustomNoRowsOverlay,
    });
    const [columnDefs, setColumnDefs] = useState<GridOptions['columnDefs']>([]);
    const { setSort, getSortState } = useSortList(sortDefault, retainSortings, directionDefault);
    const gridServerSideSource: IUseList = useList(serverDatasource, getSortState);
    const [gridApi, setGridApi] = useState<DetailGridInfo['api'] | null>(null);
    const [paginationPageSize, setPaginationPageSize] = useState<number>(25);
    const [pagingState, setPagingState] = useState<{[key: string]: number | null} | null>(null);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [showPagination, setShowPagination] = useState(false);
    const totalRowCount = rowModelType === 'serverSide' ? gridServerSideSource.totalRowCount : rowData.length;
    const isLoading = gridServerSideSource.isLoading || loading;
    const clientSideGridIsSet = rowModelType === 'clientSide' ? !!gridApi && !isLoading : null;  // needed to hide overlay on initial load for client side only 
    const clientRows = rowModelType === 'clientSide' ? rowData : null;
    const { translateToString } = useLocale();

    useEffect(() => {
        setTotalPages(Math.ceil(totalRowCount / paginationPageSize));
        if (rowModelType === 'clientSide') gridApi?.setRowData(rowData);
        if (rowModelType === 'serverSide') setLoading(isLoading);

        if (gridApi) {
          if (isLoading) {
            const isLoaderDisplayed = !!document.getElementById('grid-common-loader');
            if (!isLoaderDisplayed) gridApi.showLoadingOverlay();
          } else if (totalRowCount === 0) {
            setTimeout(() => gridApi.showNoRowsOverlay(), 200);  // to do: find solution for timeout
          } else {
            gridApi.hideOverlay();
            gridApi.sizeColumnsToFit();
            if (rowModelType === 'clientSide') {
                gridApi.refreshHeader();
                gridApi.sizeColumnsToFit();
            }
          }
    
          if (onGridLoadingChange) onGridLoadingChange({ isLoading, totalRowCount });
          
        }
        if(isLoading) gridApi?.deselectAll();
    
    }, [isLoading, rowData.length, selectedRows, clientRows, gridServerSideSource.totalRowCount, paginationPageSize, clientSideGridIsSet]);

    useEffect(() => {
        if(!gridApi) { return };
        gridServerSideSource.setSearchCriteria(serverDatasource.reqBody, serverDatasource?.queryParams);
        gridApi.refreshServerSideStore({purge: true});
        gridApi.paginationGoToFirstPage();
    }, [serverDatasource.filtersUpdatedToggle]);

    useEffect(() => {
        if (selectedRows && !isLoading) {
            const colDefs = getColumnDefs();
            setColumnDefs(colDefs);
            gridApi?.redrawRows();
            if(selectedRows?.length === 0) gridApi?.deselectAll();

        }
    }, [selectedRows]);

    const onBtNext = () => {
        gridApi?.paginationGoToNextPage();
    };
    
    const onBtPrevious = () => {
        gridApi?.paginationGoToPreviousPage();
    };
    
    const onPaginationChanged = (params: AgGridEvent) => {
        const api = params.api;
        if (api && pagingState) {
            const page = api.paginationGetCurrentPage();
            const newPagingState: {[key: string]: number | null} = { 
                totalPages: api.paginationGetTotalPages(),
                currentPage: page + 1,
                nextPage: page + 2,
            }
            const needsUpdate: boolean = !Object.keys(pagingState).every(x => pagingState[x] === newPagingState[x]);
            if (needsUpdate) setPagingState(newPagingState);
            api.refreshHeader();
        }
    };

    const setPaginationSize = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPaginationPageSize(parseInt(event.target.value));
        gridApi?.paginationGoToFirstPage();
        gridApi?.paginationSetPageSize(parseInt(event.target.value));
    };

    const getCurrentPageRows = (api: AgGridEvent['api']) => {
        const rowsToToggle: {[key: string]: any}[] = [];
        const currentPageIndex = api.paginationGetCurrentPage();
        const currentPageSize = api.paginationGetPageSize();
        const firstIndex = currentPageIndex * currentPageSize;
        const lastIndex = firstIndex + currentPageSize - 1;
        api.forEachNode((node) => {
            if (node?.data && node?.rowIndex !== null && node?.rowIndex >= firstIndex && node?.rowIndex <= lastIndex) {
                rowsToToggle.push({ ...node.data, [rowIdFieldName]: node.data[rowIdFieldName].toString() });
            }
        });
        return rowsToToggle;
    };

    const areAllVisibleRowsSelected = useCallback((api: any) => {
        if (!api) return false;
        const visibleRows = getCurrentPageRows(api);
        return visibleRows.length && visibleRows.every(visibleRow => isRowSelected(visibleRow));
      }, [selectedRows]);

    const isRowSelected = (row: {[key: string]: any}) => {
        return !!selectedRows?.find(selectedRow => selectedRow[rowIdFieldName] === row[rowIdFieldName]);
    };

    const onToggleSelectAll = (checked: boolean, api: AgGridEvent['api']) => {
        const rowsToToggle = getCurrentPageRows(api);
        if (checked) {
            const newToggleRows = rowsToToggle.filter(rowToToggle => !selectedRows?.find(selectedRow => selectedRow[rowIdFieldName] === rowToToggle[rowIdFieldName]));
            selectedRows && onSelectedRowsChanged([...new Set([...selectedRows, ...newToggleRows])]);
        } else {
            selectedRows && onSelectedRowsChanged([...selectedRows].filter(selectedRow => !rowsToToggle.find(rowToToggle => rowToToggle[rowIdFieldName] === selectedRow[rowIdFieldName])));
        }
    };

    const onToggleRow = (rowToToggle: {[key: string]: any}) => {
        if (selectedRows?.find(selectedRow => selectedRow[rowIdFieldName] === rowToToggle[rowIdFieldName])) {
            onSelectedRowsChanged([...selectedRows].filter(selectedRow => selectedRow[rowIdFieldName] !== rowToToggle[rowIdFieldName]));
        } else {
            selectedRows && onSelectedRowsChanged([...selectedRows, rowToToggle]);
        }
    };

    const onRowSelect = (row: any) => {
        let selectedRow: any[] = [];
        selectedRow.push(row);
        onRowSelected(selectedRow);
    }

    const getRowStyle = (params: RowClassParams): any => {
        if (params.data) return;
        return { display: 'none' };
    };

    const onGridSizeChanged = (params: AgGridEvent) => {
        params.api.sizeColumnsToFit();
    };

    const defaultNoRecordsMessage = {
        title: translateToString("grid.noRecsFound.title"),
        description: translateToString("grid.noRecsFound.description"),
    }

    const noRowsParams = customMessage ? customMessage : defaultNoRecordsMessage;
    const loaderParams: ICustomSkeletonLoader = { numberOfRows: paginationPageSize, columnDefinitions: loaderColumnDefs };
    
    const isColDef = (item: ColDef | ColGroupDef | undefined): item is ColDef => {
        return !!item
    }

    const getColumnDefs: () => GridOptions['columnDefs'] = () => React.Children.map(children, (child: any) => {
        
        if (child && child?.type.displayName === 'pagination') {
            if (!showPagination) setShowPagination(true);
            return;
        }

        const { field, headerNameKey, sortable, getActionItems, accentuate, getTheme, loaderWidth = 50, customComponent, ...rest } = child?.props;

        // frameworkComponents items need to be named with consistent pattern in order for the component to be matched with a renderer
        const componentRenderer = Object.keys(frameworkComponents).find(item => item.includes(child?.type.displayName));    
        return child?.type.displayName ? {
            field,
            colId: field,
            headerName: headerNameKey, // map locale key to header column name
            sortable,
            width: child?.type.displayName === 'checkboxColumn' ? 20 : child?.props.width, 
            cellStyle: child?.type.displayName === 'textColumn' ? { 'display': 'block' } : undefined,
            ...rest,
            cellRenderer: componentRenderer,
            cellRendererParams: child?.type.displayName === 'checkboxColumn' ? { rowIdFieldName, isRowSelected, onToggleRow } : child?.type.displayName === 'radioButtonColumn' ? {onRowSelect, isRowSelected} :  child?.type.displayName === 'customColumn' ? {customComponent} : { accentuate, getTheme, getActionItems },
            headerComponentFramework: child?.type.displayName === 'checkboxColumn' ? CheckboxHeader : undefined,
            headerComponentParams: sortable 
                ? { sortable: sortable, sortColumnName: field, getSortState: getSortState, setSort: setSort } 
                : child?.type.displayName === 'checkboxColumn' ? { onToggleSelectAll, areAllVisibleRowsSelected } : undefined,
            cellEditor: child?.type.displayName === 'actionsColumn' ? 'actionsEditor' : undefined,
            cellEditorParams: child?.type.displayName === 'actionsColumn' ? (params: ColumnFunctionCallbackParams) => { 
                return { params, getActionItems }
            } : undefined,
        } : null
    })?.filter(isColDef);
    
    const defaultColDef = {
        editable: false,
        sortable: false,
        flex: 1,
        minWidth: 50,
        filter: true,
        resizable: false,
        suppressNavigable: true,
        cellClass: 'no-border',
        headerComponentFramework: SortableHeader,
    };

    const onGridReady: (params: GridReadyEvent) => void = (params) => {
        const colDefs = getColumnDefs();
        setColumnDefs(colDefs);
        setGridApi(params.api);
        if (onGridLoadingChange) onGridLoadingChange({ isLoading, totalRowCount });
        params.api?.sizeColumnsToFit();
        if (rowModelType === 'serverSide') params.api?.setServerSideDatasource(gridServerSideSource);
        params.api?.paginationGoToPage(0);
        setPagingState({ totalPages: null, currentPage: 1, nextPage: null });
    };

    const onColumnResized = (params: any) => {
        if (!isLoading && params?.finished) {
            const headerDiv = document.querySelector('.ag-header-viewport') as HTMLElement;
            const unPinnedColumns: ColDef[] = params.columnApi.getColumnState().filter((col: ColDef) => !col.pinned);
            const widthSum: number = unPinnedColumns.map((col: ColDef) => col.width || 0).reduce((widthSum: number, width: number) => widthSum + width, 0);
            const lastColumn = unPinnedColumns[unPinnedColumns.length - 1];
            if (headerDiv?.offsetWidth && headerDiv?.offsetWidth > widthSum) {
                params.columnApi.applyColumnState({
                    state: [{
                        colId: lastColumn?.colId,
                        width: (headerDiv.offsetWidth - widthSum) + (lastColumn?.width || 0)  
                    }],
                });
            }
        }
    };

    // removes the column separator bar from the last resizable column that is not pinned
    const removeLastColumnSeparator = (params: any) => {
        
        const columns = params.columnApi.getAllDisplayedColumns();
        const headerCells = document.querySelectorAll('.ag-header-cell .ag-header-cell-resize');
               
        if (columns && columns.length > 0) {
            const columnsNotPinned = columns.filter((column: any) => !column.pinned && column?.colDef?.resizable);
            const lastResizableColumn = columnsNotPinned[columnsNotPinned.length - 1];
            headerCells.forEach(function(cell) {
                const columnId = (cell.parentNode as HTMLElement)?.getAttribute('col-id');
                const cellIncluded = columnsNotPinned.map((column: any) => column?.colId).includes(columnId);
                if (columnId === lastResizableColumn?.colId) {
                    cell.classList.add('ag-hidden');
                } else if (cellIncluded) {
                    cell.classList.remove('ag-hidden');
                }
            });
        }
    }
    

    const onColumnMoved = (params: any) => {
        setTimeout(function() {
            removeLastColumnSeparator(params);
        }, 0);
    };

    const onModelUpdated = (params: any) => {
        setTimeout(function() {
            removeLastColumnSeparator(params);
        }, 0)
    };

    return (
        <div 
            className={wrapperClass 
                ? wrapperClass
                : 'ag-grid__container w-full grid-common-ag-grid bg-theme-on-primary border border-solid border-gray-300 rounded-sm mt-3.5}'}
            data-test="grid-container"
        >
            <AgGridReact
                getRowStyle={getRowStyle}
                pagination={pagination}
                suppressContextMenu={suppressContextMenu}
                frameworkComponents={frameworkComponents}
                loadingOverlayComponent='customLoadingOverlay'
                loadingOverlayComponentParams={loaderParams}
                noRowsOverlayComponent='customNoRowsOverlay'
                noRowsOverlayComponentParams={noRowsParams}
                rowHeight={rowHeight}
                headerHeight={headerHeight}
                rowModelType={rowModelType}
                serverSideStoreType={serverSideStoreType}
                cacheBlockSize={cacheBlockSize}
                defaultColDef={defaultColDef}
                enableCellTextSelection={enableCellTextSelection}
                onPaginationChanged={onPaginationChanged}
                onGridReady={onGridReady}
                onGridSizeChanged={onGridSizeChanged}
                paginationPageSize={paginationPageSize}
                columnDefs={columnDefs}
                accentedSort={rowModelType === 'clientSide'}
                className={agGridClassName ? agGridClassName : 'ag-grid w-full ag-grid-height overflow-auto'}
                onColumnResized={onColumnResized}
                onColumnMoved={onColumnMoved}
                onModelUpdated={onModelUpdated}
                suppressHorizontalScroll={suppressHorizontalScroll}
                onRowClicked={onRowClicked}
            />
            {showPagination && !!pagingState?.currentPage && (
                <div className={paginationClass ? paginationClass : 'ag-grid__footer flex justify-between items-center p-4'}>
                    <PaginationPicker
                        rowCount={totalRowCount} 
                        paginationPageSize={paginationPageSize} 
                        setPaginationSize={setPaginationSize}
                        titleKey={paginationRowsTitleKey} 
                    />
                    <Pager
                        paginationPageSize={paginationPageSize} 
                        currentPage={pagingState.currentPage}
                        totalPages={totalPages}
                        goBack={onBtPrevious}
                        goForward={onBtNext}
                        goToPage={page => gridApi?.paginationGoToPage(page)}
                        loading={isLoading}
                    />
                </div>
            )}
        </div>
    );
}

Grid.TextColumn = TextColumn;
Grid.TextColumn.displayName = "textColumn";
Grid.ActionsColumn = ActionsColumn;
Grid.ActionsColumn.displayName = "actionsColumn";
Grid.CheckboxColumn = CheckboxColumn;
Grid.CheckboxColumn.displayName = "checkboxColumn";
Grid.PillColumn = PillColumn;
Grid.PillColumn.displayName = "pillColumn";
Grid.RadioButtonColumn = RadioButtonColumn;
Grid.RadioButtonColumn.displayName = "radioButtonColumn"
Grid.DateColumn = DateColumn;
Grid.DateColumn.displayName = 'dateColumn';
Grid.CustomColumn = CustomColumn;
Grid.CustomColumn.displayName = "customColumn";
Grid.Pagination = () => <></>;
Grid.Pagination.displayName = "pagination";

export default Grid;