import React from 'react';
import { ColumnFunctionCallbackParams } from 'ag-grid-community';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import LocalizedText from 'components/Translations/LocalizedText';
import { localeKeys } from 'hooks/use-locale';

interface ISortableHeaderItems {
  sortable: boolean,
  sortColumnName: string | undefined, 
  getSortState: undefined | (() => { column: string | undefined, direction: string | undefined }),
  setSort: (forColumnName: string | undefined, columnApi: ColumnFunctionCallbackParams['columnApi'], api: ColumnFunctionCallbackParams['api']) => void,
  displayName: localeKeys,
}

interface ISortableHeader extends ISortableHeaderItems, ColumnFunctionCallbackParams {};

const SortableHeader: React.FC<ISortableHeader> = ({ sortable, sortColumnName, columnApi, api, setSort, displayName, getSortState }) => {

  const onSortRequested: React.MouseEventHandler = () => {
      if (sortable) setSort(sortColumnName, columnApi, api);
  };
  
  const sortDirectionIcon = () => {
    if(getSortState) {
      const sortState = getSortState && getSortState();
      const isSortColumn = sortState && sortState.column === sortColumnName;
      const isAscSort = sortState.direction === 'asc';
      return (
        <span 
          key={isAscSort ?  `up${sortColumnName}` : `down${sortColumnName}`} 
          className="customSortUpLabelactive ml-3 flex flex-col items-center"
        >            
          <FontAwesomeIcon icon={faCaretUp} 
            className={isSortColumn && !isAscSort ? 'text-theme-primary' : 'text-theme-medium'} 
            viewBox="0 -100 320 512" 
          />
          <FontAwesomeIcon 
            icon={faCaretDown} 
            className={isSortColumn && isAscSort ? 'text-theme-primary' : 'text-theme-medium'}
            viewBox="0 100 320 512" 
          />
        </span>
      );
    } else {
      return (<></>)
    }
   
  }

  let headerLabelClass = '';
  if (getSortState) {
    const sortState = getSortState();
    if (sortState.column === sortColumnName) {
      headerLabelClass = "text-theme-primary !font-medium flex items-center content-center";
    } else {
		  headerLabelClass = "flex items-center content-center";
	  }
  }
  
  return (
    <React.Fragment>
      <div className={`ag-grid__column--label ${headerLabelClass}`} onClick={onSortRequested}>
        <LocalizedText localeKey={displayName}/> {sortable && sortDirectionIcon()}
      </div>
    </React.Fragment>
  )

};

export default SortableHeader;
