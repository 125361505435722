import { useEffect } from 'react';
import useStateRef from "react-usestateref";
import { usePortalAppContext } from "context/portal-app-context";
import { ColumnFunctionCallbackParams } from "ag-grid-community";

const useSortList = (sortDefault: string, retainSortings: boolean | undefined, direction = 'asc') => {
    const { appSortState, routerHistory }: { [key: string]: any} = usePortalAppContext();
    const { sortings, updateSortings } = appSortState;
    const route = routerHistory?.location?.pathname;
    const initialSorting = (retainSortings && sortings?.[route]) || { column: sortDefault, direction };
    const [, setCurrentSorting, currentSortingRef] = useStateRef<{ [key: string]: string | undefined }>(initialSorting);

    useEffect(() => {
      
      if(retainSortings) {
        updateSortings({ 
          [route]: { ...currentSortingRef.current } 
        });
      }
    }, [currentSortingRef.current]);

    const setSort = (forColumnName: string | undefined, columnApi: ColumnFunctionCallbackParams['columnApi'], api: ColumnFunctionCallbackParams['api']) => {
        let newDirection = 'asc';
        if (currentSortingRef.current.column === forColumnName) {
          newDirection = currentSortingRef.current.direction === 'asc' ? 'desc' : 'asc';
        } 
        setCurrentSorting({
          column: forColumnName,
          direction: newDirection,
        });
        currentSortingRef.current.column = forColumnName;
        currentSortingRef.current.direction = newDirection;
        columnApi.applyColumnState({
          state: [{
              colId: forColumnName,
              sort: newDirection,
          }],
          defaultState: {
            // important to say 'null' as undefined means 'do nothing'
            sort: null
        }
        });
        api.refreshHeader();
    };
    
    const getSortState = () => {
        return currentSortingRef.current;
    };

    return {
      setSort,
      getSortState
    }
}

export default useSortList;