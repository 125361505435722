import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';

export const getTokenExpiry = (token) => {
    try {
        const parsedToken = jwt_decode(token);
        const expiry = new Date((parsedToken.exp * 1000));
        return { expiry, epoch: parsedToken.exp * 1000 };
    } catch (e) {
        console.error("Failed to decode token: ", e);
        return null;
    }
};

