import React, { forwardRef, useRef, useImperativeHandle, useEffect } from 'react'; 
import { ColumnFunctionCallbackParams } from 'ag-grid-community';
import {useClickAway} from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { gridInterfaces } from 'interfaces';
import ActionsColumnButton from './actions-column-button';

export interface IActionsEditor {
    api: ColumnFunctionCallbackParams['api'],
    getActionItems: gridInterfaces.IActionsColumn['getActionItems'],
    data: ColumnFunctionCallbackParams['data'],
}

const ActionsEditor: React.FC<IActionsEditor> = forwardRef(({ api, getActionItems, data }, ref) => {
    const actionsContainerRef = useRef<HTMLDivElement | null>(null);
    const actionItems = getActionItems(data, undefined);
    
    useEffect(() => {
        // scroll into view if needed
        const agGridViewport = document.querySelector('.ag-body-viewport') as HTMLElement;
        if ((actionsContainerRef?.current?.getBoundingClientRect().bottom || 0) > (agGridViewport?.getBoundingClientRect().bottom || 0)) {
            actionsContainerRef.current?.scrollIntoView(false);
        }
    }, []); 
    
    useClickAway(actionsContainerRef, () => api && api.stopEditing());

    useImperativeHandle(ref, () => {
        return {
          getValue() {
            return true;
          },
        };
    });

    return (
        <div className="relative" role="button" aria-expanded="true" data-test="grid-column-actions">
            <FontAwesomeIcon icon={faEllipsisH} className="text-lg text-theme-primary cursor-pointer" />
            <div
                className="inline-flex flex-col absolute top-5 right-0 pb-1"
                ref={actionsContainerRef}
            >
                <ul className='action-items-container bg-theme-on-primary border border-solid border-gray-300 rounded-sm text-11px overflow-hidden outline-none'>
                    {actionItems.map(actionItem => {
                        const { icon, iconClass, captionKey, captionClass, disabled, handler, hidden, divider }: gridInterfaces.IActionItem = actionItem;

                        return (
                            <>
                            {divider && <hr className="mx-2"/>}
                            <li>
                                <ActionsColumnButton
                                    buttonClass='bg-transparent flex text-left cursor-pointer p-2.5 hover:bg-theme-light-background w-full items-center'
                                    icon={icon}
                                    api={api}
                                    data={data}
                                    iconClass={iconClass}
                                    captionKey={captionKey}
                                    captionClass={captionClass}
                                    disabled={disabled}
                                    handler={handler} 
                                    hidden={hidden}
                                />
                            </li>
                            </>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
})

export default ActionsEditor;
