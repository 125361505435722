import React, { useState, useEffect } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faFilter } from "@fortawesome/pro-light-svg-icons";
import Options from "./ListOption";
import DateFilter from "./DateFilter";
import ListFilter from "./ListFilter";
import TextFilter from "./TextFilter";
import CheckToggle from "./CheckToggle";
import OmniSearch, { OmniSearchProps } from "./OmniSearch";
import LocalizedText from "components/Translations/LocalizedText";
import AccessibleIcon from "components/Translations/AccessibleIcon";
import Select, { SelectRenderer } from "react-dropdown-select";
import ReactSelect, { GroupBase, MenuProps, PlaceholderProps, components } from "react-select";
import { usePortalAppContext } from "context/portal-app-context";
import { FilterValueType, IFilterData, IFilterOption } from "interfaces/filter";
import { localeKeys } from "hooks/use-locale";
import { isMobileOnly } from "react-device-detect";

interface omniSearchProps extends Omit<OmniSearchProps, "searchHandler" | "initialValue"> {
  onSearchHandler?: (val: string[]) => void;
}

interface FilterBarProps {
  onClearHandler: () => void;
  filterOptions: IFilterOption[];
  onSearchHandler: (
    val: { [key: string]: FilterValueType },
    omniSearchValues: string[],
    selectedFilters: string[]
  ) => void;
  className?: string;
  retainFilters: boolean;
  omniSearchEnable?: boolean;
  omniSearchProps?: omniSearchProps;
  children?: React.ReactNode;
  legacy?: boolean;
  disabled?: boolean;
}

export const FilterBar: React.FC<FilterBarProps> = ({
  filterOptions,
  onSearchHandler,
  onClearHandler,
  retainFilters = false,
  omniSearchEnable = false,
  className = "",
  omniSearchProps = {},
  children,
  legacy = true,
  disabled = false,
}) => {
  const { appFilterState, routerHistory }: { [key: string]: any } = usePortalAppContext();
  const { filterItems, updateFilterItems } = appFilterState;
  const route = routerHistory?.location?.pathname;
  // not able to preserve component in global state, thus need for following reseed
  const filterState = filterItems?.[route]?.filters.map((f: IFilterOption) => {
    const componentSeededFilter = filterOptions.find(
      (filterOption) => filterOption.nameKey === f.nameKey && filterOption.component && !f.component
    );
    return componentSeededFilter ? { ...f, component: componentSeededFilter.component } : f;
  });
  const [filters, setFilters] = useState<IFilterOption[]>((retainFilters && filterState) || []);
  const [filterData, setFilterData] = useState<IFilterData[]>(
    (retainFilters && filterItems?.[route]?.filterData) || []
  );
  const [omniSearchValues, setOmniSearchValues] = useState<string[]>(
    (retainFilters && filterItems?.[route]?.omniSearchValues) || []
  );

  useEffect(() => {
    let apiFormatedFiltersObj: { [key: string]: FilterValueType } = {};
    filterData.forEach((filter) => {
      if (filter.type === "DATE") {
        Object.keys(filter.value).forEach(
          (dateKey: string) => (apiFormatedFiltersObj[dateKey] = filter.value[dateKey])
        );
      } else {
        apiFormatedFiltersObj[filter.field] = filter.value;
      }
    });
    onSearchHandler(
      apiFormatedFiltersObj,
      omniSearchValues,
      filters.map((filter) => filter.nameKey)
    );

    if (retainFilters) {
      updateFilterItems({
        [route]: {
          ...filterItems[route],
          filterData,
          filters,
          omniSearchValues: omniSearchValues.filter((str) => !!str),
        },
      });
    }
  }, [filters, filterData, omniSearchValues]);

  useEffect(() => {
    if (omniSearchProps?.onSearchHandler && typeof omniSearchProps?.onSearchHandler === "function") {
      omniSearchProps?.onSearchHandler(omniSearchValues);
    }
  }, [omniSearchValues]);

  const getListOption = (filter: IFilterOption) =>
    filterOptions.find((o: IFilterOption) => o.nameKey === filter.nameKey)?.options || [];

  const onClearFilters = () => {
    setFilters([]);
    setFilterData([...filterData.filter((f) => f.type === "CHECKBOX_TOGGLE")]); // filtering per DE108737, see discussion
  };

  const handleFilters = (item: IFilterOption) => {
    let newFilters = [...filters];
    const foundIndex = filters.findIndex((e) => e.nameKey === item.nameKey);
    if (foundIndex === -1) {
      item.query = undefined;
      newFilters.push(item);
    } else {
      newFilters.splice(foundIndex, 1);
      setFilterData((prevState) => {
        const i = prevState.findIndex((f) => f.field === item.field);
        const d = [...prevState];
        if (i !== -1) {
          d[i].value = item.defaultValue || getDefaultValue(item.type);
        }
        return d;
      });
    }
    setFilters(newFilters);
    if (newFilters.length === 0) {
      return onClearHandler();
    }
  };

  const handleOnFilter = (val: FilterValueType, field: string) => {
    const filter = filterOptions.find((f) => f.field === field);
    setFilterData((prevState) => {
      const i = prevState.findIndex((f) => f.field === field);
      const d = [...prevState];
      if (i === -1) {
        d.push({
          field: field,
          value: filter?.formatValue ? filter.formatValue(val) : val,
          type: filter?.type ? filter?.type : "CUSTOM",
        });
      } else {
        d[i].value = filter?.formatValue ? filter.formatValue(val) : val;
      }
      return d;
    });
  };

  const getCheckToggleDefault = () => {
    const checkToggleAppState = filterItems?.[route]?.filterData?.find(
      (filter: { [key: string]: any }) => filter.type === "CHECKBOX_TOGGLE"
    )?.value;
    return retainFilters && checkToggleAppState !== undefined ? checkToggleAppState : true;
  };

  const customStyles = {
    control: (provided: any) => {
      return { ...provided, border: 0, boxShadow: "none" };
    },
    menu: (provided: any) => {
      return {
        ...provided,
        top: "unset",
        fontWeight: 500,
        fontSize: "14px",
        minWidth: "200px",
        borderRadius: "2px",
        border: "1px solid rgb(204, 204, 204)",
        boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 10px 0px",
      };
    },
    valueContainer: (provided: any) => {
      return { ...provided, padding: 0 };
    },
    placeholder: (provided: any) => {
      return { ...provided, marginLeft: 0, marginRight: 0 };
    },
  };

  const CustomMenu = (props: React.JSX.IntrinsicAttributes & MenuProps<unknown, boolean, GroupBase<unknown>>) => {
    const newProps = { ...props, onDropdownClose: props.selectProps.onMenuClose };
    return (
      <components.Menu className="leading-snug" {...props}>
        <Options props={newProps} width={200} filters={filters} onClick={handleFilters} onClear={onClearFilters} />
      </components.Menu>
    );
  };

  const CustomPlaceholder = (
    props: React.JSX.IntrinsicAttributes & PlaceholderProps<unknown, boolean, GroupBase<unknown>>
  ) => {
    const {
      selectProps: { menuIsOpen },
    } = props;
    return (
      <components.Placeholder {...props}>
        <label
          className="flex items-center relative border border-gray-300 hover:border-theme-light-background h-[42px] w-[123px] rounded-sm p-4 text-sm text-theme-info font-medium"
          htmlFor="list-filter-input"
        >
          <div className="flex flex-1 items-center">
            <AccessibleIcon
              icon={faFilter as IconProp}
              labeledBy="filters-label"
              classes="pointer-events-none text-theme-primary"
            />
            <div className="ml-[9px] text-theme-primary">
              <LocalizedText localeKey={"filters.filters"} id="filters-label" />
            </div>
          </div>
          <div
            className={
              "custom-dropdown-handle-arrow " +
              (menuIsOpen ? "custom-dropdown-handle-arrow-up" : "custom-dropdown-handle-arrow-down")
            }
          />
        </label>
      </components.Placeholder>
    );
  };

  const IndicatorsContainer = () => <div />;

  const customComponents = {
    Menu: CustomMenu,
    Placeholder: CustomPlaceholder,
    IndicatorsContainer,
  };

  let containerClasses = [
    "flex",
    "relative",
    "justify-between",
    "items-center",
    "py-4",
    "px-[1.125rem] sml:px-0 sml:border-none",
  ];

  if (className) {
    containerClasses = [...containerClasses, ...className.split(" ")];
  }

  const keyDownHandler = (e: { key: string; preventDefault: () => void; }) => {
		if (e.key === 'Enter') e.preventDefault();
	};

  return (
    <div className={containerClasses.join(" ")} data-test="grid-filterbar">
      <div className="flex w-full justify-between">
        {!isMobileOnly && (
          <div className="flex flex-wrap" data-test="grid-filters-dropdown">
            {legacy ? (
              <Select
                closeOnScroll={false}
                inputRenderer={({ inputRef }: { inputRef: React.RefObject<HTMLInputElement> }) => (
                  <div ref={inputRef} className="flex items-center text-theme-info">
                    <AccessibleIcon
                      icon={faFilter as IconProp}
                      labeledBy="filters-label"
                      classes="pointer-events-none text-theme-primary"
                    />
                    <div className="ml-[9px] text-theme-primary">
                      <LocalizedText localeKey={"filters.filters"} id="filters-label" />
                    </div>
                  </div>
                )}
                closeOnSelect={true}
                dropdownRenderer={({ props }: SelectRenderer<any>): JSX.Element => (
                  <Options
                    filters={filters}
                    props={props}
                    onClick={handleFilters}
                    onClear={onClearFilters}
                    width={200}
                  />
                )}
                dropdownHandleRenderer={({ state }: SelectRenderer<any>): JSX.Element => (
                  <div
                    className={
                      "custom-dropdown-handle-arrow " +
                      (state.dropdown ? "custom-dropdown-handle-arrow-up" : "custom-dropdown-handle-arrow-down")
                    }
                  />
                )}
                options={filterOptions?.filter((o) => !o.alwaysShown)}
                labelField="name"
                valueField="id"
                className="flex-1 text-sm font-medium leading-snug text-gray-600"
                style={{ width: 123, height: 42, padding: 16, paddingRight: 16, marginRight: 16 }}
                values={[]}
                onChange={() => null}
              />
            ) : (
              <div className="mr-4 mb-4">
                <ReactSelect
                  styles={customStyles}
                  isSearchable={false}
                  inputId="list-filter-input"
                  components={customComponents}
                  options={filterOptions?.filter((o) => !o.alwaysShown)}
                  onKeyDown={keyDownHandler}
                  isDisabled={disabled}
                />
                </div>
            )}

            {filters.map((filter) => {
              const filterDataItem = filterData?.find((filterDataItem) => filterDataItem?.field === filter?.field);
              if (filter.type === "LIST" && filter.options) {
                const initialSelected = filter.options.filter(
                  (option) => Array.isArray(filterDataItem?.value) && filterDataItem?.value.includes(option.id)
                );
                return (
                  <ListFilter
                    key={filter.nameKey}
                    filter={filter}
                    onRemove={handleFilters}
                    onFilter={(val: FilterValueType) => handleOnFilter(val, filter.field)}
                    options={getListOption(filter)}
                    name={filter.nameKey}
                    initialValue={retainFilters ? initialSelected : []}
                  />
                );
              } else if (filter.type === "DATE") {
                const filterDataDateItem = filterDataItem?.value;
                return (
                  <DateFilter
                    key={filter.nameKey}
                    filter={filter}
                    onRemove={handleFilters}
                    onFilter={(val: FilterValueType) => handleOnFilter(val, filter.field)}
                    initialValue={(retainFilters && filterDataDateItem) || undefined}
                  />
                );
              } else if (filter.type === "TEXT" || filter.type === "NUMBER") {
                const filterDataTextItem = filterDataItem?.value;
                return (
                  <TextFilter
                    key={filter.nameKey}
                    filter={filter}
                    onRemove={handleFilters}
                    onFilter={(val: FilterValueType) => handleOnFilter(val, filter.field)}
                    placeholder={filter.placeholder}
                    type={filter.type}
                    regex={filter.regex}
                    errorMessageKey={filter.errorMessageKey}
                    initialText={retainFilters && filterDataTextItem ? filterDataTextItem : ""}
                  />
                );
              } else if (filter.type === "CUSTOM") {
                return filter.component ? filter.component(handleFilters, handleOnFilter, filterDataItem?.value) : "";
              }
            })}
          </div>
        )}

        <div className="flex sml:w-full">
          <>
            {children}
            {filterOptions?.some((o) => o.alwaysShown && o.type === "CHECKBOX_TOGGLE") && (
              <div className="flex px-3 text-sm text-theme-dark font-normal items">
                <CheckToggle
                  defaultChecked={getCheckToggleDefault()}
                  onChange={(toggleState: boolean) =>
                    handleOnFilter(
                      toggleState,
                      filterOptions?.find((o) => o.alwaysShown && o.type === "CHECKBOX_TOGGLE")?.field || ""
                    )
                  }
                  disabled={false}
                />
                &nbsp;&nbsp;
                <LocalizedText
                  localeKey={
                    filterOptions?.find((o) => o.alwaysShown && o.type === "CHECKBOX_TOGGLE")?.nameKey as localeKeys
                  }
                />
              </div>
            )}
          </>
          {omniSearchEnable && (
            <OmniSearch
              searchHandler={setOmniSearchValues}
              initialValue={retainFilters && omniSearchValues.length ? omniSearchValues.join(" ") : ""}
              {...omniSearchProps}
            />
          )}
        </div>
      </div>
    </div>
  );
};

function getDefaultValue(type: string) {
  if (type === "TEXT") {
    return "";
  } else if (type === "LIST") {
    return [];
  } else if (type === "DATE") {
    return {};
  }
  return [];
}

export default FilterBar;
