import React from "react";
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import AccessibleIcon from "components/Translations/AccessibleIcon";

interface RemoveFilterProps {
    onRemove: () => void; 
}

const RemoveFilterButton: React.FC<RemoveFilterProps> = ({ onRemove }) => {
    return (
        <button type="button" onClick={onRemove} data-test="grid-filters-remove-filter">
            <AccessibleIcon localeKey={'filters.removeFilter'} icon={faTimesCircle}/>
        </button>
    )
}

export default RemoveFilterButton;