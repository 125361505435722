import React from 'react';

export interface ICustomNoRowsOverlay {
  title: string,
  description: string,
}

const CustomNoRowsOverlay: React.FC<ICustomNoRowsOverlay> = ({ title, description }) => {
  return (
    <div className="w-full" data-test="grid-overlay-message">
      <p className="text-theme-medium font-bold text-xl mt-20 mb-3">{title}</p>
      <p className="text-theme-medium">{description}</p>
    </div>
  );
};

export default CustomNoRowsOverlay;