import React from "react";
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { localeKeys } from "hooks/use-locale";
import AccessibleIcon from "./Translations/AccessibleIcon";
import LocalizedText from "./Translations/LocalizedText";

export interface PillProps {
  labelText: string,
  labelKey: localeKeys
  themeColor: string,
  bgColor: string,
  minWidth?: number,
  showBullet:boolean,
};

const Pill : React.FC<PillProps> = ({
    labelText,
    labelKey,
    themeColor,
    bgColor,
    minWidth = 144,
    showBullet=true,
  }) => {
  const bgColorClass = bgColor ? bgColor : '';
  return (
    <div className="pill-container flex h-[22px]" style={{minWidth}} data-test="pill-cy">
      <div className={`flex justify-center items-center rounded-full px-[4px] py-[2px] border-solid border w-max border-${themeColor} ${bgColorClass}`}>
        {showBullet &&
          <AccessibleIcon icon={faCircle} classes={`text-${themeColor} text-[8px]`} labeledBy={labelKey}/>
        }
        <div
          className={`ml-[1px] px-[4px] text-${themeColor}`}
          style={{ fontFamily: 'roboto', fontStyle: 'normal', fontWeight: 500, fontSize: '11.24px', lineHeight: 0}}>
          {labelKey ? <LocalizedText localeKey={labelKey} id={labelKey}/> : labelText}
        </div>
      </div>
    </div>
  );
};

export default Pill;