import React, { useState, useEffect } from 'react';
import { usePortalAppContext } from 'context/portal-app-context';
import useApi from 'hooks/use-api';
import LocalizedText from 'components/Translations/LocalizedText';
import useLocale from './use-locale';

const useRoles = (context, appGuid, applicationId) => {
  const portalContext = usePortalAppContext();
  const { config } = context || portalContext;
  const applicationUrl = config.API.applicationUrl;
  const accountsAppGuid = appGuid ?? 'MERCHANT_PORTAL_ACCOUNTS';
  const relativeUrl = `/${accountsAppGuid}`;

  const applicationApi = useApi(`${applicationUrl}${relativeUrl}`);
  const appId = applicationId ?? '734fe31a-e670-11eb-ba80-0242ac130004'; //Default is Accounts Applications
  const permissionsApi = useApi(`${config.API.userUrl2}`);
  const optionalPermissionsApi = useApi(config.API.userUrl2);
  const { translateToString } = useLocale(); 

  // convert names from format ACCOUNT_USER to Account User
  const getRoleDisplayName = (roleGuid) => {
    let roleName =
      roleGuid
        .split('_')
        .map(r => r.charAt(0) + r.substr(1).toLowerCase())
        .join(' ');
    return roleName;
  };

  const getLocalizedName = (localeKey) => <LocalizedText localeKey={localeKey} />

  const getRoles = async (email = 'testemail@globalpay.com', roleGuid = '') => {    
    try {
      applicationApi.abort()
      if (roleGuid) {
        await applicationApi.get(`available-roles/${email}?roleGuid=${roleGuid}`);
      } else {
        await applicationApi.get(`roles/available/${email}`);
      }
      const returnedRoles = applicationApi.response.data.content;
      const mappedRoles = returnedRoles.map(({ roleId, roleGuid, roleType, name: nameKey }) => {
        return { roleId, roleGuid, roleType, label: getLocalizedName(nameKey), nameKey, labelKey: nameKey, value: roleId };
      });
      mappedRoles.sort((a, b) => (a.roleGuid < b.roleGuid) ? -1 : (a.roleGuid > b.roleGuid) ? 1 : 0);
      return mappedRoles;
    } catch (e) {
      return []
    }
  };

  const getPermissions = async (roleId, payload) => {
    try {
      permissionsApi.abort()
      if (payload) {
        await permissionsApi.post('/application/permissions', payload);
      } else {
        await permissionsApi.get(`/profile/application/${appId}/role/${roleId}`);
      }
      const permissions = permissionsApi.response.data.content;
      return permissions

    } catch (e) {
      return []
    }
  }

  const getOptionalPermissions = async (userId, userAppId) => {
    try {
      const url = `/application/assignments/users/${userId}/${userAppId}?includeInactive=false&hydrateAssignedApplication=true&${new Date()}`
      optionalPermissionsApi.abort()
      await optionalPermissionsApi.get(url);
      const data = optionalPermissionsApi.response.data.content;
      return data

    } catch (e) {
      return []
    }
  }

  const getNotificationForRoleChange = (originalRole, newRole, hasFullPortfolioAssigned) => {
    let notification = {};
    const originalRoleName = translateToString(originalRole.nameKey);
    const newRoleName = translateToString(newRole.nameKey);
    if (newRole.roleType !== 'INTERNAL' && hasFullPortfolioAssigned)  {
      notification.type = 'error';
      notification.title = <LocalizedText localeKey="roleChange.notifications.titles.dataAccessConfigError" />
      notification.message = <LocalizedText localeKey="roleChange.notifications.messages.reconfigureDataAccess" templateVariables={{ originalRoleName, newRoleName }} />;
      notification.message2 = <LocalizedText localeKey="roleChange.notifications.messages.removeFullPortfolio" />;
    } else if (originalRole.roleGuid !== newRole.roleGuid) {
      notification.type = 'warning';
      notification.title = <LocalizedText localeKey="roleChange.notifications.titles.dataAccessWarning" />;
      notification.message = (
        <>
          <LocalizedText localeKey="roleChange.notifications.messages.reconfigureDataAccess" templateVariables={{ originalRoleName, newRoleName }} />
          &nbsp;&nbsp;
          <LocalizedText localeKey="roleChange.notifications.messages.reviewTab" />
        </>
      )

    } else {
      notification = null;
    }
    return notification;
  };

  return {
    appId,
    getRoles,
    getPermissions,
    getOptionalPermissions,
    getRoleDisplayName,
    getLocalizedName,
    getNotificationForRoleChange,
  };
};

export default useRoles;