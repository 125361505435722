import { create } from "zustand";
import streamSaver from "streamsaver";
import { Action, State } from "interfaces/export";

export const useExportRecords = create<State & Action>()((set, get) => ({
  failed: false,
  aborted: false,
  requestApi: null,
  downloaded: false,
  fetchedPercetage: 0,
  isDownloading: false,
  fetchRecords: async ({ requestParams, headers, mapFileRow, transformResponse, transformExportRow }) => {
    const { requestApi, url, maxRecord, method, mapRequestBody, fileName } = requestParams;
    set({ isDownloading: true, fetchedPercetage: 0, requestApi, aborted: false, downloaded: false, failed: false });
    let pageSize;
    let page = 1;
    let fetchedRecord = 0;
    if (maxRecord <= 1000) {
      pageSize = 100;
    } else {
      const pages = 10 + Math.ceil(maxRecord / 1000) - 1;
      pageSize = Math.ceil(maxRecord / pages);
    }
    const fileStream = streamSaver.createWriteStream(fileName);
    const writer = fileStream.getWriter();
    const uInt8 = new TextEncoder().encode(headers.join(";"));
    writer.write(uInt8);
    while (maxRecord > fetchedRecord) {
      if (get().aborted) {
        break;
      }
      if (method === "GET") {
        await requestApi.get(url);
      } else {
        await requestApi.post(url, {
          ...mapRequestBody(page, pageSize)
        });
      }
      if (requestApi.response.ok) {
        const content = transformResponse(requestApi.response.data);
        fetchedRecord += content.results.length;
        const uInt8n = new TextEncoder().encode("\n");
        writer.write(uInt8n);
        const data = transformExportRow(content.results)
          .map((row: any) => mapFileRow(row).join(";"))
          .join("\n");
        const uInt8 = new TextEncoder().encode(data);
        writer.write(uInt8);
      } else {
        set({ failed: true, isDownloading: false });
        break;
      }
      page += 1;
      set({ fetchedPercetage: Math.ceil((fetchedRecord / maxRecord) * 100) });
      if (maxRecord <= fetchedRecord) {
        set({ isDownloading: false, downloaded: true });
        writer.close();
      }
    }
  },
  cancelDownload: () => {
    get().requestApi.abort();
    set({ isDownloading: false, fetchedPercetage: 0, aborted: true, downloaded: false });
  },
}));
