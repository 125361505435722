import { useState } from "react";
import useApi from "hooks/use-api";
import { usePortalAppContext } from "context/portal-app-context";

const useTags = (context: any) => {
  const portalContext = usePortalAppContext();
  const { config } = context || portalContext;
  const options = {
    cachePolicy: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const newUserUrl = config.API.userUrl2;
  const newUserApi = useApi(newUserUrl, options);

  const [isLoading, setIsLoading] = useState(false);

  const updateApplicationAlertDismissed = async (userId: string, value: string) => {
    setIsLoading(true);
    const response = await newUserApi.patch(`tags/user/${userId}/APPLICATION_ALERT_DISMISSED_FOREVER`, {
      userId,
      value,
      tagId: "APPLICATION_ALERT_DISMISSED_FOREVER",
      status: "ACTIVE",
    });
    setIsLoading(false);
    return response;
  };

  return {
    isLoading,
    updateApplicationAlertDismissed,
  };
};

export default useTags;
