import React from 'react';
import { ColumnFunctionCallbackParams, ColDef } from 'ag-grid-community';
interface ICheckboxColumnItems {
  data?: ColumnFunctionCallbackParams['data'],
  rowIdFieldName?: string,
  isRowSelected?: (id: string) => boolean,
  onToggleRow?: (userId: string) => void;
  loaderWidth?: number | string;
}

export interface ICheckboxColumn extends ICheckboxColumnItems, ColDef {};

const CheckboxColumn: React.FC<ICheckboxColumn> = ({ data, isRowSelected, onToggleRow }) => {

  const toggle = () => {
    if(onToggleRow) onToggleRow(data);
  };

  if (data && isRowSelected) {
    const selected = isRowSelected(data);
    return (
      <input type="checkbox" defaultChecked={selected} onClick={toggle} />
    );
  }
  return <div />;
};

export default CheckboxColumn;
