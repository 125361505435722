import React, { useState, useEffect } from "react";
import Select, { SelectRenderer } from "react-dropdown-select";
import { FilterProps } from "interfaces/filter";
import LocalizedText from "components/Translations/LocalizedText";
import { localeKeys } from "hooks/use-locale";
import RemoveFilterButton from "./buttons/RemoveFilterButton";

export interface CustomFilterProps extends Omit<FilterProps, "options"> {
  dropdownRenderer: (val: any) => JSX.Element;
  formatValue: (val: any) => void
}

export const CustomFilter: React.FC<CustomFilterProps> = ({
  filter,
  onRemove,
  onFilter,
  dropdownRenderer,
  classes,
  formatValue,
  initialValue = []
}) => {

  const [selected, setSelected] = useState(initialValue);

  const handleFilters = (item: any) => {

    setSelected(formatValue ? formatValue(item) : item);
  };

  const onClearFilters = () => {
    setSelected([]);
  };

  useEffect(() => {
    onFilter(selected);
  }, [selected]);

  let selectClasses = [
    "flex-1",
    "text-sm",
    "font-medium",
    "leading-snug",
    "text-gray-600",
    "border",
    "border-solid",
    "border-gray-300",
  ];

  if (classes) {
    selectClasses = [...selectClasses, ...classes.split(" ")];
  }

  return (
    <Select
      inputRenderer={({
        inputRef,
      }: {
        inputRef: React.RefObject<HTMLInputElement>;
      }) => (
        <div
          ref={inputRef}
          className="mx-1 py-1 flex items-center relative"
          style={{
            backgroundColor: filter.query
              ? "rgba(241, 249, 255, 0.7)"
              : "#FFFFFF",
            color: "#9296A5",
          }}
        >
          <RemoveFilterButton onRemove={() => onRemove(filter)}/>
          <span className="text-xs uppercase ml-1 font-medium cursor-pointer">
            <LocalizedText localeKey={filter.nameKey as localeKeys} />
          </span>
          <span
            id={`${filter.nameKey}-all`}
            className="cursor-pointer text-theme-info mx-2 font-medium text-sm"
          >
            {selected.length
              ? <span><span>{selected.length}</span>&nbsp;<LocalizedText localeKey={'filters.selected'} /></span>
              : <LocalizedText localeKey={'filters.all'} />}
          </span>
        </div>
      )}
      closeOnSelect={true}
      dropdownRenderer={({ props }: SelectRenderer<any>): JSX.Element =>
        dropdownRenderer({
          props,
          selected,
          handleFilters,
          onClearFilters,
        })
      }
      dropdownHandleRenderer={({ state }: SelectRenderer<any>): JSX.Element => (
        <div
          className={
            "empty-arrow " +
            (state.dropdown ? "empty-arrow-up" : "empty-arrow-down")
          }
        />
      )}
      options={[]}
      valueField="id"
      className={selectClasses.join(" ")}
      style={{
        width: "auto",
        minHeight: 42,
        marginRight: 16,
        marginBottom: 16,
        backgroundColor: "white",
        boxShadow: "none",
      }}
      values={[]}
      onChange={function (): void {
        throw new Error("Function not implemented.");
      }}
    />
  );
};

export default CustomFilter;
