import React, {ChangeEvent, useEffect,useState} from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { usePortalAppContext } from 'context/portal-app-context';
import AccessibleIcon from 'components/Translations/AccessibleIcon';
import LocalizedText from 'components/Translations/LocalizedText';

interface IPagination {
  paginationPageSize: number,
  currentPage: number, 
  totalPages: number, 
  goBack: () => void, 
  goForward: () => void,
  goToPage: (page: number) => void, 
  loading: boolean,
}

const Pagination: React.FC<IPagination> = ({paginationPageSize, currentPage, totalPages, goBack, goForward, goToPage, loading }) => {
  const [thisPage,setPage]=useState<number|''>(currentPage);
  const { config, enqueueAlert }: any  = usePortalAppContext();
  const elasticSearchLimit = config?.constants?.elasticSearchLimit || 20000;

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  const onBackOrForward=(num: number)=>{
    const newPage = currentPage+num;
    if (newPage * paginationPageSize > elasticSearchLimit) {
      displayError();
    } else if (newPage > 0 && newPage <= totalPages) {
      if(num===1){
        goForward()
      }
      if(num===-1){
        goBack()
      }
      setPage(newPage)
    }
  }

  const displayError = () => enqueueAlert({ 
    alertType: 'modal',
    messageType: 'error',
    titleKey: 'alerts.generalError.title', 
    messageKey: 'alerts.generalLimit.message',
  });

  return (
    <div className="ag-grid__pagination--controls font-medium text-xs z-[1] flex items-center">
      <button 
        disabled={currentPage<=1} 
        className={`border border-solid border-gray-200 px-1 text-xs ${currentPage > 1 ?"text-theme-info font-medium":"text-theme-medium-600"}`} 
        onClick={() => onBackOrForward(-1)}
        data-test="pagination-back-cy"
        aria-label="Pagination Back"
      >
        <AccessibleIcon icon={faChevronLeft} labeledBy='pagination-back-label' />
        <span id="pagination-back-label" className="hidden">Pagination Back</span>
      </button>

      <span className="pr-1 pl-1 mr-1 sml:hidden" id="paginationPageLabel"><LocalizedText localeKey={'grid.pagination.page'} /></span>
      <input 
        id="paginationPager"
        className="border border-solid border-gray-200 py-2 px-0 text-center sml:hidden"
        style={{outline:"none",width:30}}
        type="text"
        value={thisPage}
        onKeyDown={(e)=>{
          if(!thisPage) {
            return;
          }
          const page = Number(thisPage)
          if(e.keyCode===13 && page<=totalPages){
            if(page * paginationPageSize <= elasticSearchLimit) {
               goToPage(page - 1)
            } else {
              displayError();
            }
          }
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>)=>{
          if(parseInt(e.target.value)) {
            setPage(parseInt(e.target.value))
          } else {
            setPage('');
          }
        }}
        aria-labelledby="paginationPageLabel"
      />
      <span className="pr-1 pl-1 sml:hidden" data-test="pagination-total-pages"><LocalizedText localeKey="grid.pagination.pageCountDesc" templateVariables={{ totalPages }} /></span>

      <button 
        disabled={currentPage >= totalPages || loading} 
        className={`border border-solid border-gray-200 px-1 text-xs ${(currentPage >= totalPages || loading) ? "text-theme-medium-600" : "text-theme-info font-medium"}`} 
        onClick={() => onBackOrForward(1)}
        data-test="pagination-forward-cy" 
        aria-label="Pagination Forward"
      >
          <AccessibleIcon icon={faChevronRight} labeledBy='pagination-forward-label' />
          <span id="pagination-forward-label" className="hidden">Pagination Forward</span>
        </button>
    </div>
  );
};

export default Pagination;
