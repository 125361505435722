const css = `.mid-selector .ag-grid__column--label {
  font-size: 10px;
  font-weight: 700;
  line-height: 20px;
  margin-right: 5px;
}
.mid-selector .ag-grid__pagination--picker {
  display: flex;
  align-items: center;
}
.mid-selector .ag-grid__pagination--picker select {
  color: #0F7DC4;
  font-weight: 500;
  margin: 0 10px;
  height: 30px;
  width: 55px;
  padding: 0 8px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADkSURBVHgBldI7EoIwEADQXaLWHsEjcB4dBS4gQ8VQCZUDDerYgwVcgyPoDfQI1M6EGPCHSAhslUl2X3YzQf+YakpR7Bhgzhi6tjk/wYDYhtGMjCcbKAAovXsYHJIr35+9Eziq90UrjEyyTz3CReHEtJ6EyGJ/n2qDsaobUBVK0Womy9AgTNQRGZ9/sNJjhYfP2xKdEIiahW3jlxgSlvE3nzYx21y6+B1Bjsqwarr6QRcKBOD1G4TYH9iFtkUTawX7om2YEJShIqwTFKEUqOGsV7GoRoGOcKxFTCkYfHnjfeUyrIwHVK2MFQhid5AAAAAASUVORK5CYII=) no-repeat center;
  background-size: 10px;
  -webkit-appearance: none;
  background-position-x: 33px;
  border: 1px solid #D7DCE1;
  border-radius: 5px;
}
.mid-selector .ag-grid__pagination--controls button {
  background-color: transparent;
  font-size: 14px;
  width: 30px;
  height: 30px;
  margin: 0 6px;
}
.mid-selector .ag-grid__pagination--controls button:focus {
  outline: none;
}
.mid-selector .ag-grid__pagination--controls button.active {
  color: #fff;
  background: #0F7DC4;
  border-radius: 2px;
}
.mid-selector .ag-grid__actionsIcon {
  font-size: 18px;
  color: #0F7DC4;
}
.mid-selector .ag-grid [col-id=actions] {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.mid-selector .ag-grid .ag-row,
.mid-selector .ag-grid .ag-header-row {
  border-bottom: 1px solid #D7DCE1;
}
.mid-selector .ag-grid .ag-header-row #mid-selector-view {
  background-color: #F4F5F6;
  width: 918px !important;
}
.mid-selector .ag-grid .ag-header-cell {
  padding: 20px;
}
.mid-selector .ag-grid ag-grid__column--label .ag-column__label {
  font-size: 13px;
  font-weight: 600;
}
.mid-selector .ag-grid .ag-popup-child:focus {
  outline: none;
}
.mid-selector .ag-grid .ag-react-container {
  display: flex;
}
.mid-selector .ag-grid .ag-react-container:focus {
  outline: none;
}
.mid-selector .ag-grid .ag-cell {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: #3A3D46;
  padding: 20px;
  overflow: visible;
}
.mid-selector .ag-grid .ag-cell span {
  font-weight: 500;
}
.mid-selector .ag-grid .no-border.ag-cell:focus {
  border: none !important;
  outline: none;
}
.mid-selector .ag-grid .ag-paging-panel {
  display: none;
}
.mid-selector .ag-grid .customSortDownLabel,
.mid-selector .ag-grid .customSortUpLabel {
  display: flex;
  font-size: 20px;
  align-items: center;
}
.mid-selector .ag-grid__midSelector--height {
  height: calc(100vh - 404px) !important;
}
.mid-selector .ag-grid__midSelector--small-merchant-height {
  height: calc(100vh - 550px) !important;
}
.mid-selector .ag-grid__pagination--controls button:focus,
.mid-selector .ag-grid__pagination--controls button:hover {
  cursor: pointer;
  border: 1px solid #0F7DC4;
  border-radius: 2px;
}
.mid-selector .pagination-button {
  border: 1px solid #D7DCE1;
  box-sizing: border-box;
  border-radius: 2px;
}
.mid-selector .ag-overlay-wrapper {
  display: block;
}
.mid-selector [col-id=status].ag-cell-popup-editing .status__dropdown--control .Dropdown-arrow-wrapper {
  transform: rotate(180deg);
}
.mid-selector input[name=filterOption]:focus {
  outline: none;
  border: 1px solid #0F7DC4;
}
.mid-selector .headerClass {
  background-color: #F4F5F6;
}
.mid-selector .lastHeaderClass {
  justify-content: end !important;
}
.mid-selector .ag-body-viewport {
  overflow-x: hidden !important;
}
.mid-selector .ag-body-horizontal-scroll-viewport {
  display: none;
}
.mid-selector .pill-container {
  min-width: 75px;
}
.mid-selector .ag-grid__filter input {
  height: auto !important;
}
.mid-selector .ag-grid__filter i {
  top: 0.75rem !important;
}

.modali-wrapper .modali-size-large {
  min-width: 960px !important;
}

div.modali-wrapper-centered {
  top: 0 !important;
}

@media (max-width: 1024px) {
  .modali-wrapper .modali-size-large {
    min-width: 750px !important;
  }
}
@media (max-width: 768px) {
  .modali-wrapper .modali-size-large {
    min-width: 100% !important;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvamVua2lucy9hZ2VudC93b3Jrc3BhY2UvRnJvbnRlbmRfcG9ydGFsLWNvbW1vbi11aV9tYXN0ZXIvc3JjL2NvbXBvbmVudHMvRGF0YUFjY2Vzc0VkaXRvci9NaWRTZWxlY3RvciIsInNvdXJjZXMiOlsiTWlkU2VsZWN0b3Iuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFHSTtFQUNFO0VBQ0E7RUFDQTtFQUNBOztBQUlBO0VBQ0U7RUFDQTs7QUFDQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFJRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBQ0E7RUFDRTs7QUFFRjtFQUNFO0VBQ0E7RUFDQTs7QUFLUjtFQUNFO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7QUFFRjtBQUFBO0VBRUU7O0FBRUY7RUFDRTtFQUNBOztBQUVGO0VBQ0U7O0FBR0Y7RUFFRTtFQUNBOztBQUdBO0VBQ0U7O0FBR0o7RUFDRTs7QUFDQTtFQUNFOztBQUdKO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBQ0E7RUFDRTs7QUFLSjtFQUNFO0VBQ0E7O0FBR0Y7RUFDRTs7QUFFRjtBQUFBO0VBRUU7RUFDQTtFQUNBOztBQUdGO0VBQ0U7O0FBR0Y7RUFDRTs7QUFHSjtBQUFBO0VBRUU7RUFDQTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBOztBQUVGO0VBQ0U7O0FBS0U7RUFDRTs7QUFLTjtFQUNFO0VBQ0E7O0FBR0Y7RUFDRTs7QUFHRjtFQUNFOztBQUlGO0VBQ0U7O0FBR0Y7RUFDRTs7QUFHRjtFQUNFOztBQUlBO0VBQ0U7O0FBR0Y7RUFDRTs7O0FBT0o7RUFDRTs7O0FBSUo7RUFDRTs7O0FBR0Y7RUFDRTtJQUNFOzs7QUFJSjtFQUNFO0lBQ0kifQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
