import React, { useEffect, useState, Fragment, useLayoutEffect } from 'react';
import Select, { components } from 'react-select';
import useStateRef from 'react-usestateref';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import useDataAccessGroup from 'hooks/use-data-access-group';
import TrashImage from 'assets/icons/trash.svg';
import CloseImage from 'assets/icons/close.svg';
import { usePortalAppContext } from 'context/portal-app-context';
import LocalizedText from 'components/Translations/LocalizedText';

export default function SearchByGroup({ onSelectGroup, selectedGroups, setEditing, setEditMode, setSavedGroup }) {
    const { enqueueAlert } = usePortalAppContext();
    const portalContext = usePortalAppContext();
    const { sessionUser } =  portalContext;
    const groupList = useDataAccessGroup();
    const { searchDataAccessGroup ,isLoading } = useDataAccessGroup();
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [error,setError] =useState('');
    const [groupListFetched, setGroupListFetched] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [identicalGroups,setIdenticalGroups] =useState(false);
    const [menuIsOpen, setMenuIsOpen, menuIsOpenRef] = useStateRef(false);

    useLayoutEffect(() => {
  
      if (menuIsOpenRef.current) {
        const element = document.getElementsByClassName('search-by-group__menu')[0];
        element?.scrollIntoView();
      }
  
    },[options, menuIsOpenRef.current]);
   
    const onKeyDown = (e) => {
        var key = e.keyCode || e.charCode;
        if ((!/^[A-Za-z]+$/.test(e.key) && key !== 8 && key!==32 && !(e.ctrlKey && (e.keyCode === 86 || e.keyCode === 67 )))||(e.keyCode === 13 && inputValue.length >= 2)) {
            fetchSearchApiData(inputValue);
             e.preventDefault();
        }
    };

    const handleInputChange = (e, event) => {
        if (event.action === 'input-change' ) {
            setInputValue(e);
        }
        setGroupListFetched(false);
    }

    const CaretDownIcon = () => {
        return <FontAwesomeIcon icon={faCaretDown} className="mr-1 content-center" />;
    };

    async function fetchSearchApiData (inputValue) {
        try {
            const searchTokens = (inputValue).trim().split(' ') || [];
            groupList.setSearchCriteria({
                omniSearchValues: searchTokens
            });
            const userAccessibleGroups = await groupList.searchDataAccessGroup({
                omniSearchValues: searchTokens,
                includeOnlyOwnedDAGs: false,
                userName: "",
                ownerName: "",
                merchantNumbers: [],
                sortBy: "GROUP_NAME",
                descendingSort:true,
                page:"1",
                pageSize: 100
            });
            setTotalRowCount(userAccessibleGroups?.content?.totalCount);
            const groupIdFromApi =userAccessibleGroups?.content?.results?.map((o) => (o.groupId));
            const groupIdsSelected =selectedGroups?.map(sg => sg.id);
            if(groupIdFromApi.length===groupIdFromApi?.filter(x => groupIdsSelected?.includes(x))?.length)
            {
                setIdenticalGroups(true);
            }
            setOptions(userAccessibleGroups?.content?.results?.map((o) => ({
                name: o.name,
                value: o.name,
                label: [o.createdByFirstName, o.createdByLastName].join(' '),
                id: o.groupId,
            })).filter((o) => !selectedGroups?.some(sg => o.id === sg.id)));
            setGroupListFetched(true);
            
        } catch (error) {
            setError(error.message || 'Unexpected Error!');
        }
    }

    const MenuList = props => {
        const searchTokens = (inputValue).trim().split(' ') || [];
        const optionsToDisplay = options.filter(
            opt => new RegExp(searchTokens.join('|')).test(opt.value.toLowerCase()) || new RegExp(searchTokens.join('|')).test(opt.label.toLowerCase())
        );

        return (
            <div>
                <components.MenuList {...props}>
                    <div className="m-0 p-0">
                        {Array.isArray(props?.children) ? props?.children?.slice(0, 50) : props.children}
                    </div>
                </components.MenuList>
                {optionsToDisplay.length > 50 && <div className='pl-2 bg-slate-200 border text-[7px]'>
                    <LocalizedText localeKey="hierarchySelector.selectDataAccess.dataAccessGroup.search.limit" />
                </div>}
            </div>

        );
    };

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon />
            </components.DropdownIndicator>
        );
    };

    const Placeholder = props => {
        return (
            <components.Placeholder {...props}>
                <div className="text-[0.7rem] xl:text-sm font-normal text-light-theme-medium">
                    <LocalizedText localeKey="hierarchySelector.selectDataAccess.dataAccessGroup.search.placeholder" />
                </div>
            </components.Placeholder>
        );
    };

    const handleSelect = (data) => {
            setInputValue('');
            setSavedGroup(true);
            setEditing(false);
            setEditMode(false);
            onSelectGroup(data);     
    }

    const noOptionsMessage = (
        <div className="text-theme-medium text-[12px] text-left" >
          {isLoading && 
          <>
            <LocalizedText localeKey="filters.midSearch.searching" id="mid-searching-label" />
            <AccessibleIcon icon={faSpinner} labeledBy="mid-searching-label" size="md" spin classes="text-sm pl-[4px]" />
          </>
          }
           {!isLoading && inputValue.length < 2 &&
                <>
                    <LocalizedText localeKey="hierarchySelector.selectDataAccess.dataAccessGroup.search.minChar" templateVariables={{ minChar: 2 }} />
                </>
            }
          {!isLoading  && groupListFetched && inputValue.length >= 2 && (totalRowCount === 0 || identicalGroups) &&
          <>
            <LocalizedText localeKey="hierarchySelector.selectDataAccess.dataAccessGroup.search.noMatch" />
          </>
          }
        </div>
      );
      
    return (
        <>
            <div>
                {
                    <div className="items-start mb-0 flex flex-col">
                        <>
                            <Select
                                className="bg-theme-on-primary  w-[679px] min-h-[40px] font-bold  rounded focus:outline-none focus:shadow-outline border border-solid border-theme-light-border focus:border-theme-primary"
                                components={{ MenuList, Placeholder, DropdownIndicator }}

                                styles={{
                                    control: (provided) => ({
                                        border: 'none',
                                        display: 'flex',
                                        padding: 1,
                                    }),

                                    indicatorSeparator: (styles) => ({ display: 'none' }),
                                    indicatorsContainer: (provided, state) => ({
                                        ...provided,
                                        padding: 0,
                                    }),
                                    menuList: (provided) => ({
                                        ...provided,
                                        padding: 0,
                                        margin: 0,
                                        
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        padding: '8px 12px 8px 12px',
                                        'lineHeight': '18px',
                                        'fontSize': '12px',
                                        'borderBottom': '1px solid #D7DCE1',      
                                      })      
                                }}
                                autoFocus 
                                isSearchable={true}
                                onInputChange={handleInputChange}
                                inputValue={inputValue}
                                options={options}
                                onChange={handleSelect}
                                onKeyDown={onKeyDown}
                                onMenuOpen={() => setMenuIsOpen(true)}
                                onMenuClose={() => setMenuIsOpen(false)}
                                noOptionsMessage={() => noOptionsMessage}
                                formatOptionLabel={
                                    (option, { context }) => {
                                        return context === "value" ?
                                            option.value : (
                                                <div className="flex text-theme-dark space-x-2">
                                                  <div className="text-[12px] font-bold">{option.name} - </div>
                                                    <div className="text-[12px] font-normal">{option.label}</div>
                                                 </div>
                                                );
                                    }
                                }
                                classNamePrefix="search-by-group"
                            />
                        </>
                    </div>
                }

            </div>
        </>
    );
}