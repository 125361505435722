const css = `.grid-common-ag-grid .ag-grid__column--label {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-right: 5px;
}
.grid-common-ag-grid .ag-grid__pagination--picker {
  display: flex;
  align-items: center;
}
.grid-common-ag-grid .ag-grid__pagination--picker select {
  color: #0F7DC4;
  font-weight: 500;
  margin: 0 10px;
  height: 30px;
  width: 55px;
  padding: 0 8px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADkSURBVHgBldI7EoIwEADQXaLWHsEjcB4dBS4gQ8VQCZUDDerYgwVcgyPoDfQI1M6EGPCHSAhslUl2X3YzQf+YakpR7Bhgzhi6tjk/wYDYhtGMjCcbKAAovXsYHJIr35+9Eziq90UrjEyyTz3CReHEtJ6EyGJ/n2qDsaobUBVK0Womy9AgTNQRGZ9/sNJjhYfP2xKdEIiahW3jlxgSlvE3nzYx21y6+B1Bjsqwarr6QRcKBOD1G4TYH9iFtkUTawX7om2YEJShIqwTFKEUqOGsV7GoRoGOcKxFTCkYfHnjfeUyrIwHVK2MFQhid5AAAAAASUVORK5CYII=) no-repeat center;
  background-size: 10px;
  -webkit-appearance: none;
  background-position-x: 33px;
  border: 1px solid #D7DCE1;
  border-radius: 5px;
}
.grid-common-ag-grid .ag-grid__pagination--controls button {
  background-color: transparent;
  font-size: 14px;
  width: 30px;
  height: 30px;
  margin: 0 6px;
}
.grid-common-ag-grid .ag-grid__pagination--controls button:focus {
  outline: none;
}
.grid-common-ag-grid .ag-grid__pagination--controls button.active {
  color: #fff;
  background: #0F7DC4;
  border-radius: 2px;
}
.grid-common-ag-grid .ag-grid__actionsIcon {
  font-size: 18px;
  color: #0F7DC4;
}
.grid-common-ag-grid .ag-grid [col-id=actions] {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.grid-common-ag-grid .ag-grid .action-items-container {
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 3px 3px 3px);
}
.grid-common-ag-grid .ag-grid .ag-row,
.grid-common-ag-grid .ag-grid .ag-header-row {
  border-bottom: 1px solid #D7DCE1;
}
.grid-common-ag-grid .ag-grid .ag-header-cell {
  padding: 0 20px 0 20px;
}
.grid-common-ag-grid .ag-grid .ag-column__label {
  font-size: 13px;
  font-weight: 600;
}
.grid-common-ag-grid .ag-grid .ag-popup-child:focus {
  outline: none;
}
.grid-common-ag-grid .ag-grid .ag-react-container {
  display: flex;
}
.grid-common-ag-grid .ag-grid .ag-react-container:focus {
  outline: none;
}
.grid-common-ag-grid .ag-grid .ag-cell {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 400;
  color: #3A3D46;
  padding: 20px;
  overflow: visible;
}
.grid-common-ag-grid .ag-grid .ag-cell span {
  font-weight: 400;
}
.grid-common-ag-grid .ag-grid .no-border.ag-cell:focus {
  border: none !important;
  outline: none;
}
.grid-common-ag-grid .ag-grid .ag-paging-panel {
  display: none;
}
.grid-common-ag-grid .ag-grid .customSortDownLabel,
.grid-common-ag-grid .ag-grid .customSortUpLabel {
  display: flex;
  font-size: 20px;
  align-items: center;
}

.grid-common-ag-grid .ag-row-selected {
  background-color: #E5F4FF;
  border: 1px solid #0F7DC4 !important;
  border-radius: 2px;
}

.grid-common-ag-grid .ag-grid__pagination--controls button:focus,
.grid-common-ag-grid .ag-grid__pagination--controls button:hover {
  cursor: pointer;
  border: 1px solid #0F7DC4;
  border-radius: 2px;
}

.grid-common-ag-grid .pagination-button {
  border: 1px solid #D7DCE1;
  box-sizing: border-box;
  border-radius: 2px;
}

.grid-common-ag-grid .ag-overlay-wrapper {
  display: block;
}

.grid-common-ag-grid [col-id=status].ag-cell-popup-editing .status__dropdown--control .Dropdown-arrow-wrapper {
  transform: rotate(180deg);
}

input[name=filterOption]:focus {
  outline: none;
  border: 1px solid #0F7DC4;
}

.headerClass {
  background-color: #F4F5F6;
}

.lastHeaderClass {
  justify-content: center !important;
}

.empty-arrow {
  border: solid #9296A5;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  margin: 3px 3px 3px 0;
}

.empty-arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  margin-top: 8px;
}

.empty-arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.custom-dropdown-handle-arrow {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  margin-top: -1px;
}

.custom-dropdown-handle-arrow-up {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #9296A5;
}

.custom-dropdown-handle-arrow-down {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #9296A5;
}

.grid-common-ag-grid .ag-grid-height {
  height: calc(100vh - 20rem) !important;
}

.grid-common-ag-grid .userassigned-ag-grid-height {
  height: calc(60vh - 14.5rem) !important;
}

.grid-common-ag-grid .ag-header-cell-resize::after {
  content: "";
  background-color: #9296A5;
  position: absolute;
  width: 1px;
  height: 50%;
  top: 25%;
  left: calc(50% - 1px);
  display: block;
}

.grid-common-ag-grid .ag-grid .ag-header-row {
  border: 0 !important;
}

.grid-common-ag-grid .ag-header {
  background-color: #F4F5F6 !important;
  border-bottom: 1px solid #D7DCE1 !important;
}

.grid-common-ag-grid .ag-body-horizontal-scroll-viewport {
  display: block !important;
}

.ag-pinned-right-header, .ag-pinned-right-cols-container {
  border-left: 1px solid #D7DCE1 !important;
}

.midFilter .react-dropdown-select-dropdown {
  overflow: visible;
  width: auto;
}

.loginFilter .react-dropdown-select-dropdown {
  max-height: 450px;
  width: 633px;
}

.month-item-header div, .month-item-year, .month-item-weekdays-row {
  cursor: default;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvamVua2lucy9hZ2VudC93b3Jrc3BhY2UvRnJvbnRlbmRfcG9ydGFsLWNvbW1vbi11aV9tYXN0ZXIvc3JjL2NvbXBvbmVudHMvR3JpZCIsInNvdXJjZXMiOlsiZ3JpZC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVFO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBSUE7RUFDRTtFQUNBOztBQUNBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUlGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFDQTtFQUNFOztBQUVGO0VBQ0U7RUFDQTtFQUNBOztBQUtSO0VBQ0U7RUFDQTs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBOztBQUdGO0VBQ0U7O0FBR0Y7QUFBQTtFQUVFOztBQUVGO0VBQ0U7O0FBRUY7RUFDRTtFQUNBOztBQUdBO0VBQ0U7O0FBR0o7RUFDRTs7QUFDQTtFQUNFOztBQUdKO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBQ0E7RUFDRTs7QUFJSjtFQUNFO0VBQ0E7O0FBR0Y7RUFDRTs7QUFFRjtBQUFBO0VBRUU7RUFDQTtFQUNBOzs7QUFHSjtFQUNFO0VBQ0E7RUFDQTs7O0FBR0Y7QUFBQTtFQUVFO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7OztBQUVGO0VBQ0U7OztBQUtFO0VBQ0U7OztBQUtOO0VBQ0U7RUFDQTs7O0FBR0Y7RUFDRTs7O0FBR0Y7RUFDRTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTs7O0FBRUY7RUFDRTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBOzs7QUFFRjtFQUNFO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDQzs7O0FBR0Q7RUFDQzs7O0FBR0Q7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNDOzs7QUFHRDtFQUNDO0VBQ0E7OztBQUlEO0VBQ0M7OztBQUdEO0VBQ0M7OztBQUVEO0VBQ0U7RUFDQTs7O0FBRUY7RUFDRTtFQUNFOzs7QUFFSjtFQUNFIn0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
