const css = `.selected-mids-common .ag-grid .ag-react-container {
  display: flex;
}
.selected-mids-common .ag-grid .ag-react-container:focus {
  outline: none;
}
.selected-mids-common .ag-grid .ag-header {
  display: none;
}
.selected-mids-common .ag-grid .ag-row {
  border: none;
}
.selected-mids-common .ag-grid .ag-cell {
  font-size: 12px;
  font-weight: 500;
  color: #3A3D46;
  padding: 0 10px;
}
.selected-mids-common .ag-grid .ag-cell span {
  font-weight: 500;
}
.selected-mids-common .ag-grid .no-border.ag-cell:focus {
  border: none !important;
  outline: none;
}
.selected-mids-common .ag-grid .ag-paging-panel {
  display: none;
}
.selected-mids-common .ag-grid .remove-item {
  justify-content: flex-end;
  padding-right: 15px;
}
.selected-mids-common .ag-grid .ag-root-wrapper-body.ag-layout-normal {
  height: auto;
  max-height: calc(100vh - 389px);
  overflow-y: auto;
}
.selected-mids-common .ag-body-viewport {
  overflow-x: hidden !important;
}
.selected-mids-common .ag-body-horizontal-scroll {
  display: none;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvamVua2lucy9hZ2VudC93b3Jrc3BhY2UvRnJvbnRlbmRfcG9ydGFsLWNvbW1vbi11aV9tYXN0ZXIvc3JjL2NvbXBvbmVudHMvRGF0YUFjY2Vzc0VkaXRvci9NaWRTZWxlY3RvciIsInNvdXJjZXMiOlsiU2VsZWN0ZWRNaWRzLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRU07RUFDRTs7QUFDQTtFQUNFOztBQUlKO0VBQ0k7O0FBR0o7RUFDSTs7QUFHSjtFQUNFO0VBQ0E7RUFDQTtFQUNBOztBQUNBO0VBQ0U7O0FBSUo7RUFDRTtFQUNBOztBQUdGO0VBQ0U7O0FBR0Y7RUFDRTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBOztBQUtOO0VBQ0k7O0FBR0o7RUFDSSJ9 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
