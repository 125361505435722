import React from 'react';
import PropTypes from 'prop-types';
import HierarchyImage from 'assets/icons/hierarchy.svg';
import MIDImage from 'assets/icons/mid.svg';
import DataAccessGroupImage from 'assets/icons/data-access-group.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassLocation, faFolderTree } from '@fortawesome/pro-light-svg-icons';
import AccessibleIcon from 'components/Translations/AccessibleIcon';
import PortfolioSelector from './PortfolioSelector';

import usePermissions from 'hooks/use-permissions';
import LocalizedText from 'components/Translations/LocalizedText';
import useLocale from 'hooks/use-locale';

const AddBySelector = ({ 
    onSearchBySelected,  
    portfolios = [],
    enabledSelectMethods = [], 
  }) => {

  const { hasPermission } = usePermissions();
  const { translateToString } = useLocale();
  const multiPortfolio = portfolios.length > 1;
  const selectMidsByOptions = [
    {
      labelKey: "hierarchySelector.selectDataAccess.hierarchy.name",
      labelID: "select-by-hierarchy-label",
      value: 'Hierarchy',
      icon: faFolderTree,
      isHidden: !enabledSelectMethods.find(m => m === 'Hierarchy') || !portfolios.length,
      allowsPortfolioSelection: true
    },
    {
      labelKey: "hierarchySelector.selectDataAccess.mid.name",
      labelID: "select-by-mid-label",
      value: 'MID',
      icon: faMagnifyingGlassLocation,
      isHidden: !enabledSelectMethods.find(m => m === 'MID'),
    },
    {
      labelKey: 'hierarchySelector.selectDataAccess.dataAccessGroup.name',
      labelID: "select-by-dataAccessGroup-label",
      value: 'DataAccessGroup',
      image: <DataAccessGroupImage aria-labelledby="select-by-dataAccessGroup-label" />,
      isHidden: !enabledSelectMethods.find(m => m === 'DataAccessGroup'),
    },
  ];

  const onChange = (selectedOption, selectedPortfolio = null) => {
    onSearchBySelected({ label: selectedOption.label, value: selectedOption.value,}, selectedPortfolio);
  };


  return (
    <div className="flex items-center h-[74px] w-full text-sm border border-solid border-theme-light-border bg-theme-light-background px-[20px]">
      <div className="mr-[20px] text-theme-dark font-medium"><LocalizedText localeKey="hierarchySelector.selectDataAccess.title" /></div>
      <div className="flex" role="group">
        {selectMidsByOptions.map(option => {
          const hiddenClass = option.isHidden ? 'hidden' : '';
          return (multiPortfolio && option.allowsPortfolioSelection) ? (
            <div className='ml-[8px]' key={option.labelID}>
              <PortfolioSelector 
                portfolios={portfolios} 
                onPortfolioChanged={(selectedPortfolio) => {
                  onChange(option, selectedPortfolio);
                }}
                title={<LocalizedText  localeKey={option.labelKey} />}
                icon={option.icon}
                containerClass={`${hiddenClass} w-[160px]`}
              />
            </div>
          )
          :
          (
            <button
              id={option.labelID}
              key={option.labelID}
              className={`${hiddenClass} flex items-center h-[34px] bg-theme-on-primary border border-solid border-theme-light-border px-[6px] py-[12px] text-theme-primary font-medium ml-[8px]`}
              onClick={() => onChange(option)}
              data-test={`dataaccess-${translateToString(option.labelKey).replace(/\s/g, '-').toLowerCase()}-button`}
            >
              <span className="mr-2">
                {option.icon ? <AccessibleIcon icon={option.icon} labeledBy={option.labelID}/> : option.image}
              </span>
              <LocalizedText localeKey={option.labelKey} id={option.labelID}/>
            </button>
          );
        })}
      </div>

    </div>
  );
};

AddBySelector.propTypes = {
  onSearchBySelected: PropTypes.func,
};

AddBySelector.defaultProps = {
  onSearchBySelected: () => {},
};

export default AddBySelector;
