import LocalizedText from 'components/Translations/LocalizedText';
import React from 'react';
import { localeKeys } from 'hooks/use-locale';

interface IPaginationPicker {
  paginationPageSize: number,
  rowCount: number, 
  setPaginationSize: React.ChangeEventHandler<HTMLSelectElement>, 
  titleKey?: localeKeys,
}

const PaginationPicker: React.FC<IPaginationPicker> = ({ paginationPageSize, rowCount, setPaginationSize }) => {

  return (
  <div className="ag-grid__pagination--picker text-sm font-medium px-5 sml:!px-0" >
    <p className="text-sm font-medium m-0 sml:hidden"><LocalizedText localeKey={'grid.pagination.showing'} /></p>
    <select name="ag-users-per-page" value={paginationPageSize} onChange={setPaginationSize} aria-label="Page Count">
      { [25, 50, 75, 100].map( value =>
        <option key={`pageSize-${value}`} value={value}>{value}</option>
      )}
    </select>
    <p className="text-sm font-medium m-0"><LocalizedText localeKey="grid.pagination.rowCountDesc" templateVariables={{ rowCount }}/></p>
  </div>
  );
};
export default PaginationPicker;