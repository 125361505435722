import React, { useState } from 'react';
import { usePortalAppContext } from 'context/portal-app-context';
import TrashImage from 'assets/icons/trash.svg';
import TrashDisabledImage from 'assets/icons/trash-disabled.svg';
import LocalizedText from 'components/Translations/LocalizedText';

const ViewByGroup = ({ lockRowFromEditing,showDelete, selectedHierarchyRow, onDAGDelete}) => {

  const { enqueueAlert } = usePortalAppContext();

  const onDelete = (groupToDelete) => {
    enqueueAlert({ 
      alertType: 'modal', 
      titleKey: "hierarchySelector.selectDataAccess.alerts.deleteWarning.title", 
      messageType: 'warning', 
      continueCaptionKey: "hierarchySelector.selectDataAccess.alerts.deleteWarning.continueCaption",
      cancelCaptionKey: "hierarchySelector.selectDataAccess.alerts.deleteWarning.cancelCaption",
      continueAction: () => {onDAGDelete(groupToDelete)},
      cancelAction: () => {},
      messageKey: "hierarchySelector.selectDataAccess.alerts.deleteWarning.message",
      message2: null,
      modalDialog: true,
    });
  };

  return (
    <>
                    {selectedHierarchyRow?.selectedGroup &&
                            <>
                                <div className="border py-2 pl-4 pb-0">
                                    <div className="h-3 not-italic font-normal text-xs leading-3"><LocalizedText localeKey="hierarchySelector.selectDataAccess.dataAccessGroup.selectedTitle" /></div>
                                    <div className="flex items-center justify-start">
                                        <div className={"w-auto pl-2  h-10 not-italic font-bold text-lg leading-7 flex items-center " + (lockRowFromEditing ? 'text-theme-disabled' : 'text-theme-dark')} >{selectedHierarchyRow?.selectedGroup?.name}</div>
                                        <>
                                        {showDelete &&
                                            <div className="flex flex-grow justify-end w-8" >
                                              <div className="flex w-[32px]">
                                                <button type="button" className=" border-0 p-[4px]"  disabled={lockRowFromEditing} onClick={() => onDelete(selectedHierarchyRow)}>
                                                  {!lockRowFromEditing && selectedHierarchyRow.userHierarchyAssignmentId !== 'TRANSIENT' && <TrashImage/>}
                                                  {lockRowFromEditing && <TrashDisabledImage/>}                                              
                                                </button>
                                              </div>
                                            </div>
                                        }
                                        </>
                                    </div>
                                </div>
                            </>
                    }
            </>
            );


  
};

export default ViewByGroup;