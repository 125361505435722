import portalLocaleKeys from "../constants/masterTranslations";
import { usePortalAppContext } from "context/portal-app-context";

type LocaleKeysMap = typeof portalLocaleKeys;
  
type RootName = keyof LocaleKeysMap;

type RootType = LocaleKeysMap[RootName];

let rootKey: RootName = 'globalTranslate';

export type PathInto<T extends Record<string, any>> = keyof {
  [K in keyof T as T[K] extends string
    ? K
    : T[K] extends Record<string, any>
    ? `${K & string}.${PathInto<T[K]> & string}`
    : never]: any;
};

export type localeKeys = PathInto<RootType>;  // localeKeys

function getTranslation(
  object: Record<string, unknown>,
  path: Array<string>,
  index = 0,
): string {
  const key = path[index];
  if (key === undefined) {
    return '';
  }
  const result = object[key];
  if (result === undefined) {
    return '';
  }
  if (typeof result === 'string') {
    return result;
  }
  return getTranslation(Object(result), path, index + 1);
}

interface translateResults {
  translation:  string | null;
  defaultTranslation: string
}

const useLocale = () => {

  const { appLocaleKeys, localeDictionary }: Record<string, any> = usePortalAppContext(); 
  const combinedMasterTranslations = { globalTranslate: { ...(appLocaleKeys?.globalTranslate || {}), ...portalLocaleKeys.globalTranslate }}; // combine portal and app master translations
  const runtimeTranslations = localeDictionary || combinedMasterTranslations;

  const loadTranslationsFromSession = () => {
    const overrides = sessionStorage.getItem('__translationOverrides__');
    if (overrides) {
      return JSON.parse(overrides);
    }
  }

  const translate = (localeKey: localeKeys): translateResults => {
    
    const translation = localeKey ? getTranslation(runtimeTranslations[rootKey], localeKey.split('.')) : '';
    const defaultTranslation = localeKey ? getTranslation(combinedMasterTranslations[rootKey], localeKey.split('.')) : '';
    const overrideList: [string] = (window['__translationOverrides__' as any] as unknown) || loadTranslationsFromSession() || [];
    const overrideTranslation = overrideList[localeKey as any];
    const t = overrideTranslation === undefined ? translation : overrideTranslation;
    const result = { translation: (t as string), defaultTranslation};
    return result;
  }

  const translateToString = (localeKey: localeKeys): string => {
    const translateResultsObj = translate(localeKey);
    const translatedText = translateResultsObj.translation || translateResultsObj.defaultTranslation;
    return translatedText;
  }

  return {
    translate,
    translateToString,
  };
};

export default useLocale; // useLocale