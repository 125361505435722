/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useImmer } from "use-immer";
import { usePortalAppContext } from 'context/portal-app-context';
import useUser from 'hooks/use-user';
import useDataHierarchy from "hooks/use-hierarchy";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import NotificationCard from 'components/NotificationCard';
import HierarchySelector from "./SearchByHierarchy/HierarchySelector";
import MidSelector from "./MidSelector/MidSelector";
import CommonLoader from 'components/Loaders/CommonLoader';
import AddBySelector from "./AddBySelector";
import LocalizedText from "components/Translations/LocalizedText";
import useLocale from "hooks/use-locale";

const DataAccessEditor = ({
  preSelectedPortfolio,
  dataRows,
  setCanSave,
  showEmptyAccessError = false,
  showViewMids = false,
  hasMidsExcludedFromLoggedInUser = false,
  lazyLoadItems = false,
  canEdit = true,
  allowFullPortfolioAccess,
  onRowAddOrUpdate,
  onRowDeleted,
  onBulkRowUpdate,
  onDataAccessUpdate,
  onRowIsEditing,
  onSetPageTitle,
  setSavedGroup,
  redirectSingleLineageToMidSelector = true,
  hideSelectedMidsText = false,
  selectedGroups,
  isCreateMode = false, // sets if we are creating new data access or editing existing
  someoneElseEmail,
  myself = true,
  showSelectedMidsSection = true,
  enabledSelectMethods = [],
  allowSmallMerchantMidSelection,
 }) => {
  const { enqueueAlert } = usePortalAppContext();
  const { getAuthenticatedSessionUser, getUserByEmailDataAccess } = useUser();
  const { hasSingleLineage } = useDataHierarchy();
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [isSingleLineage, setIsSingleLineage] = useState(null);
  const [showAddMids, setShowAddMidsState] = useState(dataRows && dataRows.filter(r => r && (r.selectedHierarchy !== null || r.selectedMids?.length > 0 ||  r.searchBy?.value === 'DataAccessGroup')).length > 0);
  const [nextRowKey, setNextRowKey] = useState(dataRows && dataRows.length > 0 ? Math.max(...dataRows.map(x => x?.rowKey).filter(n => n)) : 1);
  const [activeEditRow, setActiveEditRow] = useState(null);
  const [hasPendingUpdates, setHasPendingUpdates] = useState(false);
  const [hierarchyRows, setHierarchyRows] = useImmer(dataRows || [{
    rowKey: nextRowKey,
    selectedHierarchy: null,
    selectedMids: [],
    nodes: null,
    isNewRow: true,
    searchBy: null,
  }]);
  const { translateToString } = useLocale();
  const shouldRunAddMidsRef = useRef(false);

  const portfolios =  preSelectedPortfolio 
    ? loggedInUser?.portfolios.filter(p => p.affiliation === preSelectedPortfolio) 
    : loggedInUser?.portfolios
        .filter(p => {
          const hasfullPortfolioAccess = p?.dataAccess?.some(row => row.nodes[0]?.selectedChild.label === 'All' && row.nodes[0]?.selectedChild.hasDirectAccess);
          return !(p.singleTierMerchant && (!allowFullPortfolioAccess || !hasfullPortfolioAccess))  // filter out single tiered portfolios that don't have full access
        }).map(p => {
          const fullPortfolioHierarchySelected = hierarchyRows.some(r => {
            return !!(p?.affiliation === r?.selectedPortfolio && (r?.searchBy?.value !== 'DataAccessGroup' && r?.nodes && r.nodes[0]?.selectedChild?.label === 'All'));
          }); 
          return { ...p, fullPortfolioHierarchySelected };
        });

  const isSmallMerchantMidSelection = allowSmallMerchantMidSelection && loggedInUser?.rawDataAccessFromApi.length < 51 && loggedInUser?.rawDataAccessFromApi.every(h => h?.hierarchyDescendent?.level === "1");


  useEffect(() => {
    if (hasPendingUpdates) {
      if (onDataAccessUpdate) onDataAccessUpdate(hierarchyRows);
      if (setCanSave) setCanSave(showAddMids);
      setHasPendingUpdates(false);
    }

    if (shouldRunAddMidsRef.current) {
      addMids();
      shouldRunAddMidsRef.current = false;
    }

  }, [hasPendingUpdates, hierarchyRows]);

  useEffect(() =>{
    // console.log(dataRows);
  }, [dataRows]);

  useEffect(() => {
    async function checkForSingleLineage() {
      const user = !myself ? await getUserByEmailDataAccess(someoneElseEmail) : await getAuthenticatedSessionUser();
      setLoggedInUser(user);
      const singleLineage = hasSingleLineage(user.rawDataAccessFromApi, user.dataAccess);
      if (singleLineage) { 
        onSetPageTitle('Use the list below to select all of the MIDs you would like to view.');
      } else {
        onSetPageTitle('Use the hierarchy selector box to find and select the MIDs you wish to filter your reports by, or enter individual MIDs.'); 
      }
      setIsSingleLineage(singleLineage);
    }
    checkForSingleLineage();
  }, []);

  useEffect(() => {
    if (setCanSave) setCanSave(showAddMids);
  }, [showAddMids]);

  const setShowAddMids = (show) => {
    setShowAddMidsState(show);
  };

  const isStepValid = () => {
    return hierarchyRows.length > 0 && hierarchyRows.every(row => !row || row.selectedHierarchy !== null) && showAddMids;
  };

  const getSelectedPortfolio = () => {
    if (preSelectedPortfolio) return preSelectedPortfolio;
    if (portfolios.length === 1) return portfolios[0].affiliation;
    return null;
  }

  const addMids = (updatedSearchBy = null, selectedPortfolio) => {
    const blankRowKey = nextRowKey + 1;
    setNextRowKey(blankRowKey);
    setHierarchyRows(draftRows => {
      draftRows.push({
        rowKey: blankRowKey,
        selectedHierarchy: null,
        selectedMids: [],
        nodes: null,
        isNewRow: true,
        searchBy: updatedSearchBy,
        selectedPortfolio: selectedPortfolio || getSelectedPortfolio(),
      });
    });
    setShowAddMids(false);
    if (onRowIsEditing) onRowIsEditing(true);
  };

  const onHierarchyPortfolioSelected = (rowKey, selectedPortfolio) => {
    setHierarchyRows(draftRows => {
      const existingRow = draftRows.find(r => r && r.rowKey === rowKey);
      if (existingRow) {      
        existingRow.selectedPortfolio = selectedPortfolio;
      }
    });
  };

  const onHierarchyEditModeChanged = (rowKey, isEditing) => {  
    if (isEditing) {
      setShowAddMids(false);
      setActiveEditRow(rowKey);
    } else {
      setShowAddMids(true);
      setActiveEditRow(null);
    }  
    if (onRowIsEditing) onRowIsEditing(isEditing);
  };


  const onSetSearchBy = (rowKey, updatedSearchBy, selectedPortfolio) => {
    // showChangeSearchByWarning(() => onSetSearchByConfirmed(rowKey, updatedSearchBy), () => {});
    onSetSearchByConfirmed(rowKey, updatedSearchBy, selectedPortfolio);
  };

  const onSetSearchByConfirmed = (rowKey, updatedSearchBy, selectedPortfolio) => {

    setHierarchyRows(draftRows => {
      const existingRowIndex = draftRows.findIndex(r => r && r.rowKey === rowKey);
      if (existingRowIndex >= 0) {
        const existingRow = draftRows[existingRowIndex];
        if (existingRow.searchBy?.value && existingRow.searchBy?.value !== updatedSearchBy.value) {
            if (onRowDeleted) {
              onRowDeleted(rowKey);
            }
            draftRows[existingRowIndex] = null;
            addMids(updatedSearchBy);
        } else {
          existingRow.searchBy = updatedSearchBy;
          existingRow.selectedPortfolio = selectedPortfolio || getSelectedPortfolio(); 
        }
      }
    });
    setHasPendingUpdates(true);
  };

  const onDiscardRowChanges = (rowKey) => {
    const discardRowIndex = hierarchyRows.findIndex(r => r && r.rowKey === rowKey);
    if (discardRowIndex >= 0) {
      const row = hierarchyRows[discardRowIndex];
      setActiveEditRow(null);
      if (row.selectedHierarchy === null) {
        setHierarchyRows(draftRows => {
          draftRows[discardRowIndex] = null;
          if (!draftRows.some(r => r)) {
            addMids();
          } else {
            setShowAddMids(true);
          }
        });
      } else {
        setHierarchyRows(hierarchyRows);
      }
    }
  };

  const showChangeSearchByWarning = (continueAction, cancelAction) => {
      enqueueAlert({ 
        alertType: 'modal', 
        title: 'Warning', 
        titleKey : "hierarchySelector.selectDataAccess.alerts.deleteWarning.title",
        messageType: 'warning', 
        continueCaption: 'Yes, continue',
        continueCaptionKey: "dataAccessEditor.warning.continueCaption",
        cancelCaptionKey: "hierarchySelector.selectDataAccess.alerts.deleteWarning.cancelCaption",
        continueAction: continueAction,
        cancelAction: cancelAction,
        message: 'Changing the search by will clear any selections made for this row.  Continue?',
        messageKey: "dataAccessEditor.warning.message",
      });  
  }

  const showRedundantAccessWarning = (continueAction, cancelAction) => {
    enqueueAlert({ 
      alertType: 'modal', 
      title: 'Warning',
      titleKey: "hierarchySelector.selectDataAccess.alerts.deleteWarning.title", 
      messageType: 'warning', 
      continueCaptionKey: "hierarchySelector.alerts.addingFullPortfolio.continueCaption",
      cancelCaptionKey: "hierarchySelector.selectDataAccess.alerts.deleteWarning.cancelCaption",
      continueAction: continueAction,
      cancelAction: cancelAction,
      messageKey: "hierarchySelector.alerts.addingFullPortfolio.message"
    });
  };

  const showMultiPortfolioWarning = (continueAction, cancelAction) => {
    enqueueAlert({ 
      alertType: 'modal', 
      titleKey: "hierarchySelector.selectDataAccess.alerts.deleteWarning.title", 
      messageType: 'warning', 
      continueCaptionKey: "dataAccessEditor.warning.continueCaption",
      cancelCaptionKey: "hierarchySelector.selectDataAccess.alerts.deleteWarning.cancelCaption",
      continueAction: continueAction,
      cancelAction: cancelAction,
      messageKey: "hierarchySelector.selectDataAccess.alerts.multiPortfolio.message"
    });
  };

  const onHierarchyUpdated = (updatedHierarchyRow) => {
    const selectedPortfolioRowsCount = hierarchyRows.reduce((total, r) => {
      const selectedPortfolioCount = r && r?.selectedPortfolio === updatedHierarchyRow?.selectedPortfolio ? 1 : 0;
      const searchByMidCount = r && r?.selectedMids.filter(m => m?.affiliation === updatedHierarchyRow?.selectedPortfolio).length;
      return total + selectedPortfolioCount + (searchByMidCount || 0);
    }, 0);

    if (updatedHierarchyRow.nodes[0].selectedChild.label === 'All' && selectedPortfolioRowsCount > 1) {
      showRedundantAccessWarning(() => updateHierarchy(updatedHierarchyRow), () => onDiscardRowChanges(updatedHierarchyRow.rowKey));
    } else {
      updateHierarchy(updatedHierarchyRow);
    }
  };

  // checks if adding a new selected portfolio (different from what was previously selected)
  const isAddingFromNewPortfolio = (selectedPortfolio) => { 
    const filteredHierarchyRows = hierarchyRows.filter(row => row && !row.isNewRow && ['Hierarchy', 'MID'].includes(row?.searchBy?.value) );
    if(filteredHierarchyRows.length === 0) return false;
  
    return filteredHierarchyRows.every(row => {
      const { searchBy, isNewRow, selectedMids, selectedPortfolio: rowSelectedPortfolio } = row;
  
      if (searchBy.value === 'MID') {
        return selectedMids.length > 0 && selectedMids.every(m => m.affiliation !== selectedPortfolio);
      } else {
        return selectedPortfolio !== rowSelectedPortfolio;
      }
    });
  };

  const onUpdateDirectMids = (updatedHierarchyRow, mids) => {

    const updateDirectMids = (updatedHierarchyRow, mids) => {
      setActiveEditRow(null);
      setHierarchyRows(draftRows => {
        const existingRowIndex = draftRows.findIndex(r => r && r.rowKey === updatedHierarchyRow.rowKey);
        const existingRow = draftRows.find(r => r && r.rowKey === updatedHierarchyRow.rowKey);
        if (existingRow && mids.length > 0) {
          existingRow.selectedMids = mids;
          existingRow.isNewRow = false;
        } else if (existingRow && mids.length === 0) {
          if (onRowDeleted) {
            onRowDeleted(updatedHierarchyRow.rowKey);
          }
          draftRows[existingRowIndex] = null;
        } else {
          const newRow = {...updatedHierarchyRow};
          updatedHierarchyRow.isNewRow = false;
          updatedHierarchyRow.selectedMids = mids;
          draftRows.push(newRow);
        }
        setShowAddMids(true);
        if (onRowAddOrUpdate) {
          onRowAddOrUpdate(updatedHierarchyRow, mids);
        }
      });
      setHasPendingUpdates(true);
    };

    const isAddingMultiPortfolios = () => {
      const hasSearchByHierarchyOrMidRows = hierarchyRows.find(row => row && !row.isNewRow && ['Hierarchy', 'MID'].includes(row?.searchBy?.value));
      const midPortfolios = Array.from(new Set(mids.map(m => m?.affiliation))); // unique set of portfolios associated with the mids being added
      if (!hasSearchByHierarchyOrMidRows) return midPortfolios?.length > 1; // 
      return midPortfolios.some(p => isAddingFromNewPortfolio(p));
    }

    if (isAddingMultiPortfolios() && portfolios.length > 1) {
      onHierarchyEditModeChanged(updatedHierarchyRow.rowKey, true);
      // show initial warning if adding from a new portfolio
      showMultiPortfolioWarning(() => {
        updateDirectMids(updatedHierarchyRow, mids);
        onHierarchyEditModeChanged(updatedHierarchyRow.rowKey, false);
      }, () => {} );   
    } else {
      updateDirectMids(updatedHierarchyRow, mids);
    }

  };

  const updateHierarchy = (updatedHierarchyRow) => {
    setActiveEditRow(null);
    setHierarchyRows(draftRows => {
      const existingRow = draftRows.find(r => r && r.rowKey === updatedHierarchyRow.rowKey);
      if (existingRow) {
        const originalHierarchyId = existingRow.selectedHierarchy && existingRow.selectedHierarchy.hierarchyId;
        existingRow.selectedHierarchy = updatedHierarchyRow.selectedHierarchy;
        existingRow.selectedPortfolio = updatedHierarchyRow.selectedPortfolio || existingRow.selectedPortfolio;
        existingRow.nodes = updatedHierarchyRow.nodes;
        existingRow.selectedMids = updatedHierarchyRow.selectedMids;
        existingRow.selectedMidRows = updatedHierarchyRow.selectedMidRows;
        existingRow.selectedMerchants = updatedHierarchyRow.selectedMerchants;
        existingRow.selectedMerchantNumbers = updatedHierarchyRow.selectedMerchantNumbers;
        existingRow.selectedLineage = updatedHierarchyRow.selectedLineage;
        existingRow.totalAvailableMids = updatedHierarchyRow.totalAvailableMids;
        existingRow.isNewRow = false;
        // if (onRowAddOrUpdate) onRowAddOrUpdate(originalHierarchyId, updatedHierarchyRow.selectedHierarchy.hierarchyId);
        if (updatedHierarchyRow.nodes[0].selectedChild.label === 'All' && hierarchyRows.length > 1) {
          draftRows
            .filter((row, idx)=> row && row.rowKey !== updatedHierarchyRow.rowKey && row.selectedPortfolio === updatedHierarchyRow.selectedPortfolio)
            .forEach((row) => {
              const index = draftRows.findIndex(r => r && r.rowKey === row.rowKey);
              draftRows[index] = null;
            });
        }  
        if (onRowAddOrUpdate) onRowAddOrUpdate(updatedHierarchyRow);
      } else {
        draftRows.push(updatedHierarchyRow);
      }
      const hasBlankRow = draftRows.find(r => r && r?.selectedHierarchy === null && r?.selectedMids?.length === 0 && r?.searchBy?.value !== 'DataAccessGroup');
      setShowAddMids(!hasBlankRow);
    });
    setHasPendingUpdates(true);
  };

  const onHierarchyDeleted = (rowKey) => {
    setHierarchyRows(draftRows => {
      const existingRowIndex = draftRows.findIndex(r => r && r.rowKey === rowKey);
      if (existingRowIndex >= 0) {
        if (onRowDeleted) {
          onRowDeleted(rowKey);
        }
        draftRows[existingRowIndex] = null;
      }
      if (draftRows.filter(r => r && r.rowKey).length === 0) {
        if (!showEmptyAccessError) {
          shouldRunAddMidsRef.current = true;
        }
      }
    });
    setHasPendingUpdates(true);
  };

  const onSelectGroup = (group) => {
    setHierarchyRows(draftRows => {
     const existingRow = draftRows.find(r => r && r.rowKey === group.rowKey);
     if (existingRow) {
       existingRow.selectedGroup = group.selectedGroup
     }
     if (onRowAddOrUpdate) onRowAddOrUpdate(group);
   });
    setHasPendingUpdates(true);
 }

 const onDAGDelete = (row) => {
   setHierarchyRows(draftRows => {
     const existingRowIndex = draftRows.findIndex(r => r && r.rowKey === row?.rowKey);
     if (existingRowIndex >= 0) {
       if (onRowDeleted) {
         onRowDeleted(row?.rowKey);
       }
       draftRows[existingRowIndex] = null;
     }
   });
   setHasPendingUpdates(true);
  }

  const showSelectedText = ((hierarchyRows.filter(m => m !== null && (m.selectedHierarchy !== null || m.selectedMids.length > 0 || m?.selectedGroup)).length !== 0));
  const renderRows = () => {
    return (
      <div className="flex flex-col gap-3">
       <div className = {`${hideSelectedMidsText ? 'hidden'  : ''} text-theme-dark text-[14px] font-bold`}>
        {showSelectedText && <><LocalizedText localeKey="midSelector.selectedColumn.title" />:</>}
        </div> 
      {hierarchyRows.map((row, idx) => {
          if (row) {       
            return (
              <HierarchySelector 
                key={row.rowKey}
                rowKey={row.rowKey}
                selectedHierarchyRow={row} 
                showViewMids={showViewMids}
                preSelectedPortfolio={preSelectedPortfolio}
                lockRowFromEditing={(activeEditRow !== null && row.rowKey !== activeEditRow) || (showAddMids === false && showSelectedText)}
                allowFullPortfolioAccess={allowFullPortfolioAccess}
                onSetSearchBy={onSetSearchBy}
                onHierarchyEditModeChanged={onHierarchyEditModeChanged}
                onHierarchyPortfolioSelected={onHierarchyPortfolioSelected}
                onHierarchyUpdated={onHierarchyUpdated}
                onHierarchyDeleted={onHierarchyDeleted}
                onUpdateDirectMids={onUpdateDirectMids}
                onDiscardRowChanges={onDiscardRowChanges}
                lazyLoadItems={lazyLoadItems}
                canEdit={canEdit}
                setSavedGroup={setSavedGroup}
                onSelectGroup={onSelectGroup}
                allSelectedGroups={selectedGroups}
                onDAGDelete={onDAGDelete}
                isCreateMode={isCreateMode}
                myself={myself}
                someoneElseEmail={someoneElseEmail}
                portfolios={portfolios}
                enabledSelectMethods={enabledSelectMethods}
              />
            );
          }
        })
      }
      </div>
    );
  };

  // todo: add this common utility function to hierarchy.js hook and import it here and the other place it's used in hierarchySelector.jsx
  const getSelectedLineage = (tiers, hierarchyId) => {
    let nodeFound = false;
    const selectedLineage = tiers.reduce((lineage, node) => {
      if (!nodeFound) {
        const prefix = lineage ? `${lineage}|` : '';
        let label = node.selectedChild.label;
        if (label === '000000') label = '';
        const result = `${prefix}${label}`;
        if (node.selectedChild.hierarchyId === hierarchyId) {
          nodeFound = true;
        }
        return result;
      } else {
        return lineage;
      }
    }, null);
    return selectedLineage;
  };

  const onMidsSelected = (mids) => {
    const getSelectedMidsHierarchyRow = ({ hierarchyMids, selectedHierarchy, rowKey, index }) => {
      const nodes = loggedInUser?.dataAccess[index].nodes;
  
      return {
        rowKey,
        selectedHierarchy,
        selectedLineage: getSelectedLineage(nodes, String(selectedHierarchy?.hierarchyId)),
        selectedMids: hierarchyMids.map(m => m.hierarchyId) || [],
        selectedMidRows: hierarchyMids,
        selectedMerchantNumbers: hierarchyMids.map(m => m.merchantId.trim()),
        nodes,
        searchBy: { value: 'Hierarchy', label: 'Hierarchy' },
        selectedPortfolio: loggedInUser?.dataAccess[index]?.selectedPortfolio,
      };
    };

    let hierarchyMidsRowKey = nextRowKey;
    let selectedMidsHierarchyRows = [];
  
    loggedInUser.dataAccess.forEach((access, index) => {
      const selectedHierarchy = access.selectedHierarchy;
      const hierarchyId = String(selectedHierarchy?.hierarchyId);
      const hierarchyMids = isSmallMerchantMidSelection ? mids.filter(m => access.selectedMids.includes(m.hierarchyId)) : mids; // single lineage will always get unfiltered mids 
      const existingHierarchyRow = hierarchyRows?.find(h => h?.selectedHierarchy?.hierarchyId === hierarchyId);

      if (hierarchyMids.length > 0) {
        const rowKey = existingHierarchyRow ? existingHierarchyRow.rowKey : hierarchyMidsRowKey + 1;
        const selectedRow = getSelectedMidsHierarchyRow({ hierarchyMids, selectedHierarchy, rowKey, index });
        selectedMidsHierarchyRows.push(selectedRow);

        if (!existingHierarchyRow) {
          hierarchyMidsRowKey++;
        }
      } 

    });
  
    setHierarchyRows(selectedMidsHierarchyRows);
    if (onDataAccessUpdate) onDataAccessUpdate(selectedMidsHierarchyRows);
    if (onBulkRowUpdate) onBulkRowUpdate(selectedMidsHierarchyRows);
    if (setCanSave) setCanSave(selectedMidsHierarchyRows.some(h => h.selectedMerchantNumbers?.length > 0))
    setNextRowKey(hierarchyMidsRowKey);
  };

  const onSearchBySelected = (searchByType, selectedPortfolio) => {
    
    const differentSelectedPortfolio = selectedPortfolio && isAddingFromNewPortfolio(selectedPortfolio);  // check to see if adding from a new portfolio
    
    if (differentSelectedPortfolio && portfolios.length > 1) {
      showMultiPortfolioWarning(() => addMids(searchByType, selectedPortfolio), () => {} );
    } else {
      addMids(searchByType, selectedPortfolio);
    }
  };

  const numberOfFullPortfolioAssigned = hierarchyRows.filter(r => r?.searchBy?.value !== 'DataAccessGroup'&& r?.nodes && r.nodes[0]?.selectedChild?.label === 'All')?.length;
  const allFullPortfolioAssigned = numberOfFullPortfolioAssigned === loggedInUser?.portfolios?.length;  // all portfolios have full portfolio assignment
  const selectedHierarchyId = loggedInUser?.dataAccess[0]?.selectedHierarchy?.hierarchyId;
  const midRows = useMemo(() => loggedInUser?.dataAccess?.flatMap(h => h?.selectedMidRows) || [], [loggedInUser?.dataAccess]);
  const initialSelectedRows = useMemo(() => dataRows?.length > 0 ? dataRows.flatMap(h => h?.selectedMidRows || []) : [], [dataRows]);
  const hasDirectAccessToSelectedHierarchy = loggedInUser?.dataAccess?.length === 1 && loggedInUser?.dataAccess[0]?.selectedMids?.length === 0;

  return (
    <React.Fragment>
      <div className="w-full h-full">
        {/* {renderRows()} */}
        {(isSingleLineage === false || !redirectSingleLineageToMidSelector) && !isSmallMerchantMidSelection && renderRows()}
        {((isSingleLineage === true && redirectSingleLineageToMidSelector) || isSmallMerchantMidSelection) &&
          <MidSelector
            viewOnly={!canEdit}
            hasDirectAccessToSelectedHierarchy={hasDirectAccessToSelectedHierarchy}
            title={<LocalizedText localeKey="midSelector.title.primary" />}
            onMidsSelected={onMidsSelected}
            selectedHierarchyId={loggedInUser?.dataAccess[0]?.selectedHierarchy?.hierarchyId}
            midList={midRows}
            selectedTiers={loggedInUser?.dataAccess[0].nodes}
            initialSelectedMids={initialSelectedRows.map(x => x?.hierarchyId)}
            selectedMidRows={initialSelectedRows}
            showApplyChanges={false}
            gridHeightClass="ag-grid-height-inline"
            showSelectedMidsSection={showSelectedMidsSection}
            targetUserEmail={someoneElseEmail}
            isSmallMerchantMidSelection={isSmallMerchantMidSelection}
          />
        }
        {isSingleLineage === null && <CommonLoader width={'80px'}/>}
        {showEmptyAccessError && !hierarchyRows.some(row => row) &&
        <div className="pt-0 pb-4">
          <NotificationCard
            iconClass="items-start justify-start pt-3 pb-8 pr-3" 
            type="error"
            titleKey="hierarchySelector.notifications.noDataAccess.title"
            messageLine1Key="hierarchySelector.notifications.noDataAccess.message"
          />
      </div>    
        }
        {hasMidsExcludedFromLoggedInUser && <div className="text-theme-dark pt-[8px]">
          <LocalizedText localeKey="hierarchySelector.outsideMIDs" />
        </div>
        }
        {showAddMids && activeEditRow === null && canEdit && !allFullPortfolioAssigned && (!isSingleLineage || !redirectSingleLineageToMidSelector) && !isSmallMerchantMidSelection &&
          <div className="mt-[10px] flex items-center min-w-[635px] xl:min-w-[761px]">
            <AddBySelector
              onSearchBySelected={onSearchBySelected}
              portfolios={portfolios}
              enabledSelectMethods={enabledSelectMethods}
            />
          </div>
        }
      </div>
    </React.Fragment>
  );
};

export default DataAccessEditor;
