import { useState, useEffect, useRef } from 'react';
import { usePortalAppContext } from 'context/portal-app-context';
import useDataHierarchy from "hooks/use-hierarchy";
import useApi from 'hooks/use-api';
import { CellShape } from 'enums';

const useMerchantList = (parentHierarchyId, relativeUrl = `/hierarchy/hierarchies/mids/${parentHierarchyId}`, targetUserEmail, portfolio) => {
  const options = {
    cachePolicy: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const { config, enqueueAlert } = usePortalAppContext();
  const { portfolioNameMap } = useDataHierarchy();
  const userServiceBaseUrl = config.API.userUrl2;
  const merchantListUrl = `${userServiceBaseUrl}${relativeUrl}`;
  const merchantListApi = useApi(merchantListUrl, options);

  const [isLoading, setIsLoading] = useState(false);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [currentPageCount, setCurrentPageCount] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const searchCriteria = useRef('');

  const setSearchCriteria = (search) => {
    searchCriteria.current = search;
  };

  useEffect(() => {
    if (merchantListApi.error) {
      enqueueAlert({ alertType: 'modal', title: 'Error', messageType: 'error', message: 'An error occurred while processing your request.  Please try again later.' });
      setIsLoading(false);
    }
  }, merchantListApi.error);

  const isNumber = (val) => {
    if (val == undefined || val == '')
      return false;
    else
      return !/[^0-9]/.test(val);
  };

  const getRows = async (params) => {
    try {
      merchantListApi.abort();
      setIsLoading(true);
      const pageSize = params.request.endRow - params.request.startRow;
      let startPage = (params.request.startRow / pageSize) + 1;
      let response;
      const searchVal = params.type !== 'DBA' ? `?searchValue=${searchCriteria.current}` : `?searchValueForDBAName=${searchCriteria.current}&searchValue=`;
      await merchantListApi.get(`${searchVal}&page=${startPage}&pageSize=${pageSize}&targetUserEmail=${targetUserEmail}${portfolio ? `&portfolio=${portfolio}` : ''}`);
      response = merchantListApi.response;
      if (searchCriteria.current === '') {
        setTotalRow(merchantListApi.response.data.content.totalCount);
      }
      if (response.ok && response?.data?.content?.results) {
        const data = response.data;
        const inRows = data.content.results;
        const outRows = inRows.map(inRow => {
          const lineage = inRow.lineageDetails.map(l => l.value === 'top_level' ? null : l.value === null ? '000000' : l.value);
          const lineageDelimited = `${lineage.filter(l => l !== null).join('|')}|${inRow.merchantDemographics.merchantNumber}`;
          const lineageGuids = `${inRow.lineage.substr(inRow.lineage?.indexOf('|') + 1)}|${inRow.hierarchyId}`;
          const outRow = {
            hierarchyId: inRow.hierarchyId,
            merchantId: inRow.merchantDemographics.merchantNumber,
            merchantName: inRow.merchantDemographics.dbaName,
            address: `${inRow.merchantDemographics.dbaAddress1}, ${inRow.merchantDemographics.dbaCity}, ${inRow.merchantDemographics.dbaPostalCode}`,
            lineage: lineageDelimited,
            lineageGuids,
            hierarchy: `${lineage.filter(l => l !== null).join(' ')}`,
            portfolioName: portfolioNameMap[inRow.hierarchyConfig.affiliation],
            affiliation: inRow.hierarchyConfig.affiliation
          };
          return outRow;
        });
        setTotalRowCount(data.content.totalCount);
        setCurrentPageCount(outRows.length);
        if (params.successCallback) params.successCallback(outRows, data.totalElements);
        setIsLoading(false);
        return outRows;
      } else {
        return [];
      }
    }
    catch (err) {
      console.log(err);
    }
  };

  const getDetails = async (hierarchyId,ownerName,taxId) => {
    ///api/v1/hierarchy/profile/merchants/value/{hierarchyId}?ownerName=true&taxId=false
    if (!ownerName && !taxId)
    {
        setIsLoading(true);
    }
      const data = await merchantListApi.get(`/${hierarchyId}?ownerName=${ownerName}&taxId=${taxId}`);   
      if (merchantListApi.response.ok) { 
        setIsLoading(false);
          return data;
      } else {
        setIsLoading(false);
         return [];
      }
  }

  const transformRow = (rows) => {
    const inRows = rows;
    const outRows = inRows.map(inRow => {
      const lineage = inRow.lineageDetails.map(l => l.value === 'top_level' ? null : l.value === null ? '000000' : l.value);
      const lineageDelimited = `${lineage.filter(l => l !== null).join('|')}|${inRow.merchantDemographics.merchantNumber}`;
      const lineageGuids = `${inRow.lineage.substr(inRow.lineage?.indexOf('|') + 1)}|${inRow.hierarchyId}`;
      const outRow = {
        hierarchyId: inRow.hierarchyId,
        merchantId: inRow.merchantDemographics.merchantNumber,
        merchantName: inRow.merchantDemographics.dbaName,
        address: `${inRow.merchantDemographics.dbaAddress1}, ${inRow.merchantDemographics.dbaCity}, ${inRow.merchantDemographics.dbaPostalCode}`,
        lineage: lineageDelimited,
        lineageGuids,
        hierarchy: `${lineage.filter(l => l !== null).join(' ')}`
      };

      return outRow;
    });
    return outRows;
  }

  const sortMap = {}//{'default': 'GROUP_NAME', 'groupName': 'GROUP_NAME', 'groupOwner': 'GROUP_OWNER'};

  const loaderColumnDefs = [
    { width: "7%", cellShape: CellShape.Bar },
    { width: "26%", cellShape: CellShape.Bar },
    { width: "27%", cellShape: CellShape.Bar },
    { width: "40%", cellShape: CellShape.Bar },
  ];


  return {
    getRows,
    setSearchCriteria,
    totalRowCount,
    totalRow,
    currentPageCount,
    isLoading,
    getDetails,
    transformRow,
    sortMap,
    loaderColumnDefs,
    isNumber,
    abort: () => merchantListApi.abort()
  }
};

export default useMerchantList;
