import React, { useState, useEffect } from "react";
import Options from "./ListOption";
import Select from "react-dropdown-select";
import { FilterProps, IFilter } from "interfaces/filter";
import LocalizedText from "components/Translations/LocalizedText";
import { localeKeys } from "hooks/use-locale";
import RemoveFilterButton from "./buttons/RemoveFilterButton";

export const ListFilter: React.FC<FilterProps> = ({
  filter,
  onRemove,
  onFilter,
  options,
  classes,
  initialValue = []
}) => {
  const [selected, setSelected] = useState<IFilter[]>(initialValue);

  const handleFilters = (item: IFilter) => {
    let newFilters: IFilter[] = [...selected];
    const foundIndex = selected.findIndex((e: IFilter) => e.id === item.id);
    if (foundIndex === -1) {
      item.query = undefined;
      newFilters.push(item);
    } else {
      newFilters.splice(foundIndex, 1);
    }
    setSelected(newFilters);
  };

  const onClearFilters = () => {
    setSelected([]);
  };

  useEffect(() => {
    onFilter(selected.map((item: IFilter) => item.id));
  }, [selected]);

  let selectClasses = [
    "flex-1",
    "text-sm",
    "font-medium",
    "leading-snug",
    "text-gray-600",
    "border",
    "border-solid",
    "border-gray-300",
  ];

  if (classes) {
    selectClasses = [...selectClasses, ...classes.split(" ")];
  }

  return (
    <Select
      inputRenderer={({ inputRef }) => (
        <div
          ref={inputRef}
          className="mx-1 py-1 flex items-center relative"
          style={{
            backgroundColor: filter.query
              ? "rgba(241, 249, 255, 0.7)"
              : "#FFFFFF",
            color: "#9296A5",
          }}
        >
          <RemoveFilterButton onRemove={() => onRemove(filter)}/>
          <span className="text-xs uppercase ml-1 font-medium cursor-pointer">
            <LocalizedText localeKey={filter.nameKey as localeKeys} />
          </span>
          <span
            id={`${filter.nameKey}-all`}
            className="cursor-pointer text-theme-info mx-2 font-medium text-sm"
          >
            {selected.length ? <span><span>{selected.length}</span>&nbsp;<LocalizedText localeKey={'filters.selected'}/></span> : <LocalizedText localeKey={"filters.all"} />}
          </span>
        </div>
      )}
      closeOnSelect={true}
      dropdownRenderer={({ props }) => (
        <Options
          props={props}
          width={225}
          filters={selected}
          onClick={handleFilters}
          onClear={onClearFilters}
        />
      )}
      dropdownHandleRenderer={({ state }) => (
        <div
          className={
            "empty-arrow " +
            (state.dropdown ? "empty-arrow-up" : "empty-arrow-down")
          }
        />
      )}
      options={options}
      valueField="id"
      className={selectClasses.join(" ")}
      style={{
        width: "auto",
        height: 42,
        marginRight: 16,
        marginBottom: 16,
        boxShadow: "none",
        backgroundColor: "white",
      }}
      values={[]}
      onChange={function (): void {
        throw new Error("Function not implemented.");
      }}
    />
  );
};

export default ListFilter;