import React from 'react';
import SkeletonLoader from  'components/Loaders/SkeletonLoader/SkeletonLoader';

export interface ICustomSkeletonLoader {
  numberOfRows: number, 
  columnDefinitions: { cellShape: CellShape, width: string | number,  }[],
}

const CustomSkeletonLoader: React.FC<ICustomSkeletonLoader> = ({ numberOfRows, columnDefinitions }) => {
  return (
    <div id="grid-common-loader" style={{ height: '100%', width: '100%', display: 'block' }}>
      <SkeletonLoader numberOfRows={numberOfRows} columnDefinitions={columnDefinitions}/>
    </div>
  )
};

export default CustomSkeletonLoader;

export enum CellShape {
  Bar = "Bar",
  Pill = "Pill",
  Ellipsis = "Ellipsis"
}