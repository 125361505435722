import React, { useState } from 'react';
import { AgGridReact } from "ag-grid-react";
import AllCommunityModules from "ag-grid-community";
import TextRenderer from 'components/GridOld/textRenderer';
import RemoveSelectedRenderer from 'components/GridOld/removeSelectedRenderer';
import './SelectedMids.scss';
import LocalizedText from 'components/Translations/LocalizedText';

const SelectedMids = ({ selectedMids, setSelectedMids, onClearAll }) => {

    const [frameworkComponents, setFrameworkComponents] = useState({
        textRenderer: TextRenderer,
        removeSelectedRenderer: RemoveSelectedRenderer,
    });

    const colDefs = [
        {
            field: 'merchantId',
            colId: 'MerchantId',
            width: 60,
            sortable: false,
        },
        {
            colId: 'removeItem',
            cellRenderer: 'removeSelectedRenderer',
            cellRendererParams: params => {
                return {
                    removeSelection: (mid) => {
                        const midStr = mid.hierarchyId.toString();
                        setSelectedMids(oldMids => [...oldMids].filter(x => x.hierarchyId !== midStr));
                    },
                } 
            },
            cellClass: pararms => 'remove-item'
            

        }
      ];
    
      const [columnDefs, setColumnDefs] = useState(colDefs);
    
      const defaultColDef = {
        editable: false,
        sortable: false,
        flex: 1,
        minWidth: 20,
        resizable: false,
        suppressNavigable: true,
      };

    return (

        <div className="selected-mids-common w-[192px] mt-[16px] mr-[20px] border border-solid border-gray-200 rounded-sm">
            <div className="text-sm font-medium text-theme-dark border-0 border-b border-solid border-gray-200 p-[8px] mb-[8px]">
                <LocalizedText localeKey="midSelector.selectedColumn.title" />
            </div>
            <div className="ag-grid">
                <AgGridReact
                    suppressContextMenu
                    frameworkComponents={frameworkComponents}
                    rowHeight={30}
                    defaultColDef={defaultColDef}
                    enableCellTextSelection={false}
                    autoHeight={true}
                    onCellClicked={() => {}}
                    modules={AllCommunityModules}
                    columnDefs={columnDefs}
                    rowData={selectedMids}
                />
            </div>
            <button 
                className="text-sm font-medium text-theme-danger w-full text-left bg-theme-light-background border-0 border-t border-solid border-gray-200 p-[8px] mt-[8px]"
                onClick={onClearAll}
            >
                <LocalizedText localeKey="midSelector.buttons.clearAll" />
            </button>
        </div>
    );
}

export default SelectedMids;