import React from 'react'
import { ColumnFunctionCallbackParams } from 'ag-grid-community';

interface ITextColumnItems {
    accentuate?: boolean,
    value?: string,
}

export interface ITextColumn extends ITextColumnItems, ColumnFunctionCallbackParams {}; 

const TextColumn: React.FC<ITextColumn> = ({ accentuate, value, colDef }) => {
    return (
        <div className="overflow-hidden" data-test={`grid-column-${colDef?.colId}`}>
            <div 
                className={`block truncate`} 
                style={accentuate ? { fontWeight: 500, fontSize: '0.875rem' } : undefined} 
            >
                {value}
            </div>
        </div>
    )
}

export default TextColumn;
