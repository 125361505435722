
import React, { SyntheticEvent } from 'react';
import LocalizedText from './Translations/LocalizedText';
import { localeKeys } from 'hooks/use-locale';
import AccessibleIcon from './Translations/AccessibleIcon';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export enum ButtonType {
  PrimaryButton = "PrimaryButton",
  SecondaryButton = "SecondaryButton",
  AltButton = "AltButton",
  Alt = "Alt",
  LinkButton = "LinkButton",
  MobilePrimary = "MobilePrimary",
  MobileSecondary = "MobileSecondary",
}

export interface ButtonProps {
    title: string,
    titleKey?: string,
    id?: string,
    child?: React.ReactNode,
    buttonClass?: string,
    defaultContainerClass?: string,
    iconClasses?: string,
    minWidth?: number,
    buttonType?: ButtonType,
    disabled?: boolean,
    isProcessing?: boolean,
    processingCaption?: string,
    processingCaptionKey?: string,
    onClick?: (e?: SyntheticEvent)=>void,
    testDataAttribute?: string,
};

const Button: React.FC<ButtonProps> = ({ 
    title,
    titleKey,
    id='', 
    child=null,
    buttonClass='',
    defaultContainerClass = '',
    iconClasses = null,
    buttonType = '',
    onClick = () => {},
    disabled = false,
    isProcessing = false,
    processingCaption = '',
    processingCaptionKey,
    testDataAttribute,
  }) => {
  
  const buttonTypeClasses = 
    (buttonType === ButtonType.PrimaryButton && !isProcessing) ? 'bg-theme-primary text-theme-on-primary border-0 border px-4 py-2 rounded-sm font-bold hover:bg-theme-primary-hover disabled:text-theme-disabled disabled:bg-theme-light-background' : 
    (buttonType === ButtonType.PrimaryButton && isProcessing) ? 'bg-theme-disabled text-theme-on-primary px-4 py-2 rounded-sm' : 
    (buttonType === ButtonType.SecondaryButton) ? 'bg-theme-on-primary text-theme-primary border border-theme-light-border px-4 py-2 rounded-sm hover:text-theme-primary-hover' : 
    (buttonType === ButtonType.AltButton) ? 'bg-theme-light-background text-theme-info' :
    (buttonType === ButtonType.LinkButton) ? 'text-sm text-theme-primary px-4 py-2 rounded-sm disabled:text-theme-disabled font-medium' :

    (buttonType === ButtonType.MobilePrimary) ? 'bg-theme-primary text-theme-on-primary px-4 py-2 rounded-full text-sm font-bold cursor-pointer disabled:bg-theme-light-border disabled:text-theme-disabled-mobile' :
    (buttonType === ButtonType.MobileSecondary) ? 'bg-theme-on-primary text-black px-4 py-2 border border-2 border-theme-primary rounded-full text-sm font-bold cursor-pointer disabled:text-theme-disabled-mobile disabled:border-theme-light-border' : '';
  

  let classNames = `inline-flex flex-row mr-3 items-center  \
                    text-sm font-bold leading-snug text-center ${buttonTypeClasses} focus:btn-blue-shadow focus:disabled:btn-blue-shadow \
                     ${buttonClass}`;
    
  const buttonTitle = titleKey ? <LocalizedText localeKey={titleKey as localeKeys} /> : title;

    return (
      <div className={defaultContainerClass}>

        <button
          id={id}
          type="button"
          disabled={disabled}
          className={classNames}
          aria-label='Button Back'
          onMouseDown={onClick}
          data-test={testDataAttribute}
        >          
          {child}

          <div>
            {iconClasses !== null &&
            <i className={`${iconClasses} mr-2 text-xl`} />
            }
            {isProcessing &&
            <React.Fragment>
              <AccessibleIcon icon={faSpinner} labeledBy='processing-label' classes='fa-spin mr-2'/>
              {processingCaptionKey ? <LocalizedText localeKey={processingCaptionKey as localeKeys} id="processing-label"/> : processingCaption}
            </React.Fragment>
            }
            {!isProcessing && buttonTitle}
          </div>
        </button>

      </div>
    );
}

export default Button;
