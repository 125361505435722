import React from 'react';
import './LogoutTimerLayout.scss';
import Button, { ButtonType } from 'components/button';
import LocalizedText from 'components/Translations/LocalizedText';
import useLocale from 'hooks/use-locale';

const LogoutTimerLayout = ({ responseTimer, logout, renew }) => {

    const { translateToString } = useLocale();
    const message = translateToString('logoutTimer.message1').replace('%responseTimer%', responseTimer);

    return (
    <div id="sessionTimer" className="session-timeout-wrapper med:w-[98%]">
        <div id="sessionTimer1" className="session-timeout p-6 med:p-3 med:flex med:flex-col med:items-center">
            <div id="sessionTimerheader" className="session-timeout-header med:text-2xl med:font-medium med:text-theme-primary">
                <LocalizedText localeKey="logoutTimer.title" />
            </div>
            <div id="sessionTimercontent" className="session-timeout-content my-3 med:flex med:flex-col med:items-center med:text-sm">
                    <div dangerouslySetInnerHTML={{ __html: message }} />
                    <div><LocalizedText localeKey="logoutTimer.message2" /></div>
            </div>
            <div id="sessionTimerfooter" className="session-timeout-footer flex md:justify-end">
                <button id="session_timeout_logout_btn" aria-label="Logout" className="btn btn-secondary med:hidden"  onClick={logout}><LocalizedText localeKey="logoutTimer.buttons.logout" /></button>
                <button id="session_timeout_stay_connected_btn" className="btn btn-secondary-alt med:hidden" onClick={renew}><LocalizedText localeKey="logoutTimer.buttons.renew" /></button>

                <Button buttonType={ButtonType.MobilePrimary} buttonClass="hidden med:block" id="session_timeout_stay_connected_btn" onClick={renew} title="Renew" titleKey="logoutTimer.buttons.renew" />
                <Button buttonType={ButtonType.MobileSecondary} buttonClass="hidden med:block" id="session_timeout_logout_btn" onClick={logout} title="Logout" titleKey="logoutTimer.buttons.logout"/>
            </div>
        </div>
    </div>
    )
};

export default LogoutTimerLayout;
