import React, { useEffect } from "react";
import { IFilter } from "interfaces/filter";
import LocalizedText from "components/Translations/LocalizedText";
import useLocale from "hooks/use-locale";
import AccessibleIcon from "components/Translations/AccessibleIcon";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface OptionsProps {
  props: any;
  filters: Array<IFilter>;
  type?: string;
  width?: number;
  onClear: () => void;
  onClick: (val: any) => void;
}

export const Options: React.FC<OptionsProps> = ({
  props,
  onClick,
  filters,
  onClear,
  type,
  width,
}) => {
  const { translateToString } = useLocale();
  useEffect(() => {
    const node: HTMLDivElement | null = document.querySelector(
      ".react-dropdown-select-dropdown"
    );
    if (node) {
      node.style.width = `${width || 150}px`;
    }
  }, []);

  const onClickClearAll = () => {
    if (filters.length) {
      props.onDropdownClose();
      onClear();
    }
  };

  if (type === "bulkActions") {
    return (
      <div>
        {props.options.map((option: any, i: number) => {
          return (
            <div
              className="p-2 text-right"
              style={{ color: option.id === "activate" ? "green" : "red" }}
              key={i}
            >
              {option.nameKey}
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div>
      <div style={{ overflow: "auto", maxHeight: 225 }}>
        {!props.options.length && (
          <div className="pl-4 py-3">
            <LocalizedText localeKey="common.loading" id="list-option-label" />
            <AccessibleIcon
              icon={faSpinner}
              labeledBy="mid-searching-label"
              size="md"
              spin
              classes="text-sm pl-[4px]"
            />
          </div>
        )}
        {props.options.map((item: any, i: number) => {
          const checkedIndex = filters.findIndex(
            (e: any) => e.nameKey === item.nameKey
          );
          return (
            <div
              className={`${item?.hide ? 'hidden' : ''} flex items-center pl-4 pt-2 pb-2 hover:bg-theme-info hover:bg-opacity-20`} key={i} data-test={`grid-filters-${translateToString(item.nameKey).replace(/\s/g, '-').toLowerCase()}`}
              onClick={() => !item.disabled && onClick(item)}
            >
              <input
                id={translateToString(item.nameKey)}
                checked={checkedIndex > -1}
                className="pl-4 cursor-pointer"
                type="checkbox"
                onChange={() => {}}
                disabled={item.disabled}
              />
              <label
                htmlFor={item.nameKey}
                className={`pl-4 ${item.disabled ? 'text-theme-disabled' : 'text-theme-info'} cursor-pointer`}
              >
                <LocalizedText localeKey={item.nameKey}/>
              </label>
            </div>
          );
        })}
      </div>
      <hr />
      <div
        onClick={onClickClearAll}
        className={`text-red-600 pl-4 py-3 ${
          filters.length === 0 ? "opacity-50 cursor-not-allowed" : "hover:bg-theme-info hover:bg-opacity-20"
        }`} data-test="grid-filters-clearall"
      >
        <LocalizedText localeKey={'filters.clearAllFiters'} />
      </div>
    </div>
  );
};

export default Options;
