import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import useLocale, { localeKeys } from '../../hooks/use-locale';

type IconTitleProps = {
    localeKey: localeKeys
    icon: IconProp;
    classes?: string;
    [key: string]: any;
}

type IconLabeledProps = {
    labeledBy: string;
    icon: IconProp;
    classes?: string;
    [key: string]: any;
}

type AccessibleIconProps = IconTitleProps | IconLabeledProps;


const AccessibleIcon: React.FC<AccessibleIconProps>  = (props) => {
    const { translate } = useLocale();
    let translatedText;
    if ('localeKey' in props) {
        const { localeKey, classes, icon, ...rest } = props;
        const translateResultsObj = translate(props.localeKey);
        translatedText = translateResultsObj.translation || translateResultsObj.defaultTranslation;
        return <FontAwesomeIcon icon={props.icon} className={props?.classes} title={translatedText} {...rest}/>;
    } else if ('labeledBy' in props) {
        const { labeledBy, classes, icon, ...rest } = props;
        return <FontAwesomeIcon icon={props.icon} className={props?.classes} aria-labelledby={props.labeledBy} {...rest} />;
    }

    throw new Error('Incorrect props provided to AccessibleIcon component.')
}


export default AccessibleIcon;