import React, { useState, useRef, useEffect, ReactElement } from 'react';
import {useClickAway} from 'react-use';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import LocalizedText from './Translations/LocalizedText';
import { localeKeys } from 'hooks/use-locale';
import AccessibleIcon from './Translations/AccessibleIcon';


export interface PanelProps {
    titleKey: localeKeys;
    title: string | ReactElement;
    buttonClass?: string;
    containerClass?: string;
    utilityClass?:string;
    expandedContainerClass?: string;
    buttonIcon?: IconProp;
    buttonIconLabel?: string;
    arrowClass?:string;
    panelIsOpen?: boolean;
    onClick?: ()=>void;
    onOpenChange: (expanded: boolean) => void;
    children?: React.ReactNode;
}

const PanelDropdown: React.FC<PanelProps> = ({
    title,
    titleKey,
    buttonClass,
    containerClass,
    children,
    utilityClass,
    expandedContainerClass = 'mt-[2px] shadow-panel-dropdown',
    buttonIcon,
    buttonIconLabel,
    arrowClass,
    panelIsOpen = false,
    onOpenChange = () => {},
    onClick
  }) => {
    const [expanded, setExpanded] = useState(false);
    const arrowIcon = expanded ? faChevronUp : faChevronDown;
    const panelRef = useRef(null);
    
    useClickAway(panelRef, () => {
        if (expanded) setExpanded(!expanded);
    });

    useEffect(() => {
        onOpenChange(expanded);
    }, [expanded]);

    useEffect(() => {
        if (expanded !== panelIsOpen) setExpanded(panelIsOpen);
    }, [panelIsOpen]);

    return (
        <div className={`flex flex-col w-fit-content`} ref={panelRef} aria-label="Action Panel Dropdown" data-test="panel-dropdown">
            <div className={`flex items-center justify-center text-sm border border-solid border-gray-200 rounded-sm ${containerClass}`} onClick={() => setExpanded(!expanded)}>
                <div className={`${utilityClass} flex-grow font-medium`} data-test="user-management-create-user" onClick={() => {if(onClick && !expanded) onClick()}}>
                    {buttonIcon ? <AccessibleIcon labeledBy={buttonIconLabel || ''} icon={buttonIcon as IconProp} classes='mr-3' /> : ''}
                    {titleKey ? <LocalizedText localeKey={titleKey} /> : <span>{title}</span>}
                </div>
                <button 
                    id="dropdown-button"
                    type="button"
                    aria-expanded={expanded} 
                    className={`flex items-center justify-center border-solid border-l-2 border-gray-200 ${buttonClass} ${arrowClass}`}
                    onClick={() => setExpanded(!expanded)}
                >
                    <AccessibleIcon localeKey={'common.icons.dropdownArrow'} icon={arrowIcon} />
                </button>
            </div>
            {expanded && <div id= "panel-dropdown" className="relative">
                <div className={`z-50 bg-theme-on-primary absolute flex flex-col w-full border-1 border-solid border-theme-light-border ${expandedContainerClass} rounded top-0 left-0 border`}>
                  {children}
                </div>
            </div>}
        </div>
    )
}

export default PanelDropdown;