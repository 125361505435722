export async function getBrands(visitedUrl, basePath,baseUrl) {
    let isRefetch = sessionStorage.getItem('brandDetails') === undefined || sessionStorage.getItem('brandDetails') === null || ['/synovus', '/'].includes(basePath);

    if (isRefetch) {
        try {
            const response = await fetch(`${baseUrl}/brandApplicationAssociation/visit?visitedUrl=${visitedUrl}&basePath=${basePath}`);
            const data = await response.json();
            sessionStorage.setItem('brandDetails', JSON.stringify(data));
            return data;
        } catch (error) {
            throw error.response;
        }
    } else {
        return JSON.parse(sessionStorage.getItem('brandDetails'));
    }
}

export default getBrands