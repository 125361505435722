import React, { useState, useEffect, useRef } from "react";
import { FilterProps } from "interfaces/filter";
import RemoveFilterButton from "./buttons/RemoveFilterButton";
import LocalizedText from "components/Translations/LocalizedText";
import { localeKeys } from "hooks/use-locale";

interface TextFilterProps extends Omit<FilterProps, 'options'> {
  placeholder?: string;
  errorMessageKey?: string;
  regex?: string;
  type:string;
  initialText: string;
}

export const TextFilter: React.FC<TextFilterProps> = ({
  onRemove,
  onFilter,
  filter,
  classes,
  errorMessageKey,
  regex,
  type="text",
  initialText = ""
}) => {
  const [selected, setSelected] = useState(initialText);
  const [serchText, setSerchText] = useState(initialText);
  const [notValidText, setNotValidText] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const initialRender = useRef(true);

  useEffect(() => {
    if (!selected) inputRef.current?.focus();
  }, []);

  useEffect(() => {
    if (!initialRender.current) onFilter(selected);
    initialRender.current = false;
  }, [selected]);

  useEffect(() => {
    if (regex) {
      const reg = new RegExp(regex);
      setNotValidText(!reg.test(serchText));
    }
  }, [serchText]);

  let inputClasses = ["font-medium", "text-sm", "text-theme-info"];

  if (classes) {
    inputClasses = [...inputClasses, ...classes.split(" ")];
  }

  const handleSearch = (e: any) => {
    setSerchText(e.target.value.replace(/\s+/g,' '));
  };

  const onSearch = (e: any) => {
    if(e && e.preventDefault) {
      e.preventDefault();
    }
    if (
      !notValidText
      // && (serchText.trim()) //.length > 1 || selected.length
    ) {
      setSerchText(serchText.trim());
      setSelected(serchText.trim());
    }
    return false;
  };

  return (
    <div className="mr-4 mb-4 items-center relative">
      <form onSubmit={e => onSearch(e)}>
      <div className="flex items-center h-[42px] w-auto border border-solid border-gray-300 px-2">
        <span>
          <RemoveFilterButton onRemove={() => onRemove(filter)}/>
        </span>
        <label className="text-xs text-theme-medium font-medium uppercase ml-1" htmlFor={filter.field}><LocalizedText localeKey={filter.nameKey as localeKeys}/></label>
        <input
          id={filter.field}
          ref={inputRef} 
          className={`${inputClasses.join(" ")} ml-2 p-1 pl-2 truncate`}
          value={serchText}
          //onKeyDown={onSearch}
          onBlur={e => onSearch(e)}
          onChange={handleSearch}
          type={type}
          data-test={`grid-filters-text-${filter.field}`}
        />
      </div>
      </form>
      {errorMessageKey && notValidText ? (
        <div className="absolute w-full bg-theme-on-primary z-10 border-gray-400 border border-solid px-1 py-1 text-theme-medium text-[12px] text-left font-bold">
          <LocalizedText localeKey={errorMessageKey as localeKeys}/>
        </div>
      ) : null}
    </div>
  );
};

export default TextFilter;
