import React, {useState} from 'react';

const PortalAppContext = React.createContext({});

export function PortalAppContextProvider({
    config,
    sessionUser,
    getSessionToken,
    getMyProfile,
    setMyProfile,
    enqueueAlert,
    routerHistory,
    multiPortfolioEnabled, // todo: remove when this feature flag is closed
    brandListEnabled, // todo: remove when this feature flag is closed
    featureFlags = {},
    appFilterState,
    appSortState = {},
    appLocaleKeys,  
    localeDictionary,
    translationAdmin,
    appPreferences = {}, 
    children,
  }) {

  const [mobSidebar, setMobSidebar] = useState(false);
  
  const handleMobSidebar = () => {
    setMobSidebar(!mobSidebar);
  }

  
  const providerValue = {
    config,
    sessionUser,
    getSessionToken,
    getMyProfile,
    setMyProfile,
    enqueueAlert,
    routerHistory,
    multiPortfolioEnabled,
    brandListEnabled,  // todo: remove when this feature flag is closed
    appFilterState,  // todo: remove when this feature flag is closed
    featureFlags,
    appSortState,
    mobSidebar,
    handleMobSidebar,
    appLocaleKeys,
    localeDictionary,
    translationAdmin,
    appPreferences
  };

  return (
    <PortalAppContext.Provider value={providerValue}>
      {children}
    </PortalAppContext.Provider>
  );
}

export const usePortalAppContext = () => React.useContext(PortalAppContext);
