const css = `.session-timeout {
  background-color: white;
  z-index: 27;
  box-shadow: 0 6px 60px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}
.session-timeout-wrapper {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 10000;
}
.session-timeout-header {
  top: calc(50% - 21px - 72.5px);
  font-family: Roboto;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 42px;
  letter-spacing: normal;
  color: #3A3D46;
}
.session-timeout-content {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--bluey-grey);
}
.session-timeout-content span.bold {
  font-weight: 700;
  color: #3A3D46;
}
.session-timeout-content-header {
  color: black;
  display: block;
  font-family: Roboto;
  font-size: 11.5px;
  margin-left: 5%;
}
.session-timeout-footer {
  text-align: left;
}
.session-timeout-footer #session_timeout_stay_connected_btn {
  width: 125px;
  height: 40px;
  color: #fff;
  background-color: #0F7DC4;
  font-weight: 500;
}
.session-timeout-footer #session_timeout_logout_btn {
  color: #3A3D46;
  font-weight: 500;
}

.session-timeout-contents {
  line-height: 1.8;
  margin-left: spacing(xl);
}

@media only screen and (max-width: 767px) {
  .session-timeout-wrapper {
    position: absolute;
    bottom: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvamVua2lucy9hZ2VudC93b3Jrc3BhY2UvRnJvbnRlbmRfcG9ydGFsLWNvbW1vbi11aV9tYXN0ZXIvc3JjL2NvbXBvbmVudHMvTG9nb3V0VGltZXIiLCJzb3VyY2VzIjpbIkxvZ291dFRpbWVyTGF5b3V0LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBO0VBR0E7RUFDQTs7QUFFQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFPRjtFQUVFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBR0E7RUFDRTtFQUNBOztBQUlKO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFHRjtFQUNFOztBQUlBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFHRjtFQUNFO0VBQ0E7OztBQUtOO0VBQ0U7RUFDQTs7O0FBR0Y7RUFFSTtJQUNFO0lBQ0E7SUFDQTtJQUNBO0lBQ0EifQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
